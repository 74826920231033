import React, { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiText } from "@elastic/eui";

import { ROUTES } from "../app/app";

interface LoadingOverlayProps {
  message?: ReactNode;
}
export const SomethingWentWrong: FC<LoadingOverlayProps> = ({ message }) => {
  const navigate = useNavigate();

  return (
    <EuiFlexGroup direction={"column"}>
      <EuiFlexItem>
        {message || <EuiText>Something Went Wrong</EuiText>}
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButton onClick={() => navigate(`${ROUTES.INDEX}`)}>
          Go Back
        </EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
