import { DateTime } from "luxon";
import { FC } from "react";

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import { css } from "@emotion/react";

import { ProductServerRoleLink } from "../../@types/serverRole";
import { ArPanel } from "../../arComponents/ArPanel";
import { statusPrettier } from "../../features/constants";

import { TableDataRow } from "./ServerMemberTable";

interface ServerMemberTableDetailsProps {
  item: TableDataRow;
  productServerRoleLinks: ProductServerRoleLink[] | undefined;
}

export const ServerMemberTableDetails: FC<ServerMemberTableDetailsProps> = ({
  item,
  productServerRoleLinks,
}) => {
  if (!item.allSubscriptions) {
    return null;
  }

  return (
    <EuiFlexGroup direction={"column"} gutterSize={"s"}>
      {item.allSubscriptions.map((sub) => {
        return (
          <EuiFlexItem key={`table-details-${item.id}-sub-${sub.id}`}>
            <ArPanel hasShadow={false} hasBorder={true}>
              <EuiFlexGroup
                justifyContent={"spaceBetween"}
                css={css`
                  flex-direction: row;
                  @media (max-width: 767px) {
                    flex-direction: column;
                    margin-right: auto;
                  }
                `}
              >
                <EuiFlexItem
                  grow={false}
                  css={css`
                    align-items: end;
                    justify-content: center;
                  `}
                >
                  <EuiFlexGroup direction={"column"}>
                    <EuiFlexItem>
                      <EuiTitle size={"xxs"}>
                        <h1>{sub.name}</h1>
                      </EuiTitle>
                    </EuiFlexItem>
                    {productServerRoleLinks &&
                    productServerRoleLinks.filter(
                      (p) =>
                        String(p.product.productId) ===
                        String(sub.externalProductId)
                    ).length > 0 ? (
                      <EuiFlexItem>
                        <EuiText size={"s"}>
                          Linked Roles:{" "}
                          {productServerRoleLinks
                            .filter(
                              (p) =>
                                String(p.product.productId) ===
                                  String(sub.externalProductId) &&
                                p.serverRole !== undefined
                            )
                            .map((p) => p.serverRole!.discordRoleName)
                            .join(", ")}
                        </EuiText>
                      </EuiFlexItem>
                    ) : null}
                    <EuiFlexItem>
                      <EuiText size={"s"}>User Email: {sub.email}</EuiText>
                      <EuiText size={"s"}>
                        Subscribed On:{" "}
                        {DateTime.fromISO(sub.createdAt!)
                          .setLocale("en")
                          .toLocaleString(DateTime.DATETIME_MED)}
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiText size={"s"}>
                        Subscription Cost{" "}
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(sub.total)}
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      {sub.source === "woo" && sub.sourceId ? (
                        <EuiText size={"s"}>
                          WooCommerce Subscription Id: {sub.sourceId}
                        </EuiText>
                      ) : null}
                      {sub.source === "woo" && sub.externalProductId ? (
                        <EuiText size={"s"}>
                          WooCommerce Product Id: {sub.externalProductId}
                        </EuiText>
                      ) : null}
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem
                  grow={false}
                  css={css`
                    justify-content: space-between;
                    align-items: end;
                  `}
                >
                  <EuiFlexItem />
                  <EuiFlexItem>
                    <EuiHealth
                      color={sub.status === "active" ? "success" : "danger"}
                    >
                      {statusPrettier(sub.status)}
                    </EuiHealth>
                  </EuiFlexItem>
                  <EuiFlexItem
                    grow={false}
                    css={css`
                      justify-content: end;
                    `}
                  >
                    <EuiText size={"xs"} color={"subdued"}>
                      <i>
                        Last Updated on{" "}
                        {DateTime.fromISO(sub.updatedAt!)
                          .setLocale("en")
                          .toLocaleString(DateTime.DATETIME_MED)}
                      </i>
                    </EuiText>
                  </EuiFlexItem>
                </EuiFlexItem>
              </EuiFlexGroup>
            </ArPanel>
          </EuiFlexItem>
        );
      })}
    </EuiFlexGroup>
  );
};
