import React from "react";

import { EuiEmptyPrompt } from "@elastic/eui";
import { useQueryClient } from "@tanstack/react-query";

import { ArPage } from "../../arComponents/ArPage";
import { Header } from "../../components/header/Header";
import { LoadingOverlay } from "../../components/loading/LoadingOverlay";
import {
  useFetchSubscribeFormByUrlParams,
  useServerFromUrl,
} from "../../features/hooks/hooks";
import { ServerView } from "../server/ServerView";

import { CollectionPanel } from "./panels/CollectionPanel";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StoreBaseRouteProps {}

export const StoreRoot: React.FC<StoreBaseRouteProps> = () => {
  const server = useServerFromUrl();
  const queryClient = useQueryClient();

  useFetchSubscribeFormByUrlParams();

  if (server.isError) {
    return (
      <ServerView
        children={
          <EuiEmptyPrompt
            iconType={"alert"}
            iconColor={"danger"}
            title={<h2>Something went wrong</h2>}
            body={<p>{server.error.message}</p>}
          />
        }
      />
    );
  }

  return (
    <ArPage
      isLoading={!server || server.isLoading}
      header={<Header />}
      body={
        server.isSuccess ? (
          <ServerView>
            <CollectionPanel />
          </ServerView>
        ) : (
          <LoadingOverlay />
        )
      }
      restrictWidth={true}
    />
  );
};
