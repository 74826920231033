import { EuiThemeModifications } from "@elastic/eui/src/services/theme/types";

export const ThemeDanger = "#EA4F58";
export const ThemeWarning = "#F2C57C";

export const Theme: EuiThemeModifications = {
  // font: { family: "Neueu" },
  colors: {
    DARK: {
      primary: "#62DEA7",
      accent: "#55B9DD",
      success: "#62DEA7",
      warning: ThemeWarning,
      danger: ThemeDanger,
      dangerText: "#EA4F58",
    },
  },
};
