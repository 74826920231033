import { sumBy } from "lodash";
import React, { useMemo } from "react";
import { useImmer } from "use-immer";

import {
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiInMemoryTable,
  EuiLoadingContent,
} from "@elastic/eui";
import {
  EuiTableFieldDataColumnType,
  EuiTableSortingType,
} from "@elastic/eui/src/components/basic_table/table_types";

import { DataTimePeriod, TableDataRow } from "../../@types/data";
import { ServerState } from "../../@types/server";
import { useSummaryTableData } from "../../features/api/data";
import { toNumberString } from "../../features/formatters/general";

interface DataSummaryTableProps {
  server: ServerState;
  timePeriod: DataTimePeriod;
}

export const DataSummaryTable: React.FC<DataSummaryTableProps> = ({
  server,
  timePeriod,
}) => {
  const [error, setError] = useImmer(null);

  const summaryTableData = useSummaryTableData(server.id, timePeriod);

  const columns = useMemo<EuiTableFieldDataColumnType<TableDataRow>[]>(() => {
    return [
      {
        field: "name",
        name: "Product",
        sortable: true,
      },
      {
        field: "numberOfSales",
        name: "Number of Sales",
        sortable: true,
        footer: ({ items }) => (
          <span>{sumBy(items, (item) => parseFloat(item.numberOfSales))}</span>
        ),
      },
      {
        field: "totalSales",
        name: "Total Sales",
        sortable: true,
        footer: ({ items }) => (
          <span>
            {toNumberString(
              sumBy(items, (item) => parseFloat(item.totalSales))
            )}
          </span>
        ),
      },
    ];
  }, []);

  const sort: EuiTableSortingType<any> = {
    enableAllColumns: true,
    allowNeutralSort: true,
    sort: {
      field: "isAutoroleSubscriber",
      direction: "desc",
    },
  };

  if (summaryTableData.data) {
    return (
      <EuiFlexGroup direction={"column"}>
        {error !== null ? (
          <EuiFlexItem>
            <EuiCallOut
              iconType="faceSad"
              color="danger"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              title={`Invalid search: ${error.message}`}
            />
          </EuiFlexItem>
        ) : null}
        <EuiFlexItem>
          <EuiInMemoryTable
            items={summaryTableData.data}
            columns={columns}
            pagination={true}
            itemId="name"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sorting={sort}
            tableLayout={"auto"}
            responsive={true}
            hasActions={false}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
  return <EuiLoadingContent lines={5} />;
};
