import { startCase } from "lodash";
import React from "react";

import { EuiFlexGroup, EuiFlexItem, EuiStat } from "@elastic/eui";

import { DataTimePeriod } from "../../@types/data";
import { ServerState } from "../../@types/server";
import { useDataSummary } from "../../features/api/data";
import { toNumberString } from "../../features/formatters/general";

interface DataSummaryProps {
  server: ServerState;
  timePeriod: DataTimePeriod;
}

export const DataSummary: React.FC<DataSummaryProps> = ({
  server,
  timePeriod,
}) => {
  const dataSummary = useDataSummary(server.id, timePeriod);

  if (dataSummary.data && !dataSummary.isLoading) {
    return (
      <EuiFlexGroup direction={"row"} justifyContent={"spaceAround"}>
        {Object.entries(dataSummary.data[0]).map(([key, value]) => {
          return (
            <EuiFlexItem key={`summary-stat-${key}`} grow={false}>
              {key === "numberOfSales" ? (
                <EuiStat
                  textAlign={"center"}
                  description={startCase(key)}
                  title={value}
                />
              ) : (
                <EuiStat
                  textAlign={"center"}
                  description={startCase(key)}
                  title={toNumberString(value)}
                />
              )}
            </EuiFlexItem>
          );
        })}
      </EuiFlexGroup>
    );
  }
  return null;
};
