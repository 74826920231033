import React from "react";

import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { DiscordGuildRole } from "../../../@types/discord";
import { ServerState } from "../../../@types/server";
import { ArHorizontalRule } from "../../../arComponents/ArHorizontalRule";
import { ArPanel } from "../../../arComponents/ArPanel";
import { useDiscordApi } from "../../../features/api/discord-server-api";
import {
  useDefaultServerRole,
  useProductServerRoleLinks,
} from "../../../features/api/server-roles";
import { ArRoleLinksSection } from "../panels/autoroleStore/ArRoleLinksSection";
import { DefaultRoleSection } from "../panels/manageRoles/DefaultRoleSection";
import { WooRoleLinksSection } from "../panels/woocommerceStore/WooRoleLinksSection";

interface ManageServerManageRolesSubPageProps {
  server: ServerState;
}

export const ManageServerManageRolesSubPage: React.FC<
  ManageServerManageRolesSubPageProps
> = ({ server }) => {
  const productServerRoleLinks = useProductServerRoleLinks(server.id, {
    refetchOnWindowFocus: false,
  });

  const guildRoles = useDiscordApi<DiscordGuildRole[]>(
    server.id,
    "guilds",
    "roles"
  );

  const defaultServerRole = useDefaultServerRole(server.id);

  return (
    <ArPanel>
      <EuiFlexGroup gutterSize={"m"} direction={"column"}>
        <EuiFlexItem>
          <DefaultRoleSection
            server={server}
            guildRoles={guildRoles.data}
            defaultServerRole={defaultServerRole.data}
            productServerRoleLinks={productServerRoleLinks.data}
            isLoading={productServerRoleLinks.isLoading}
          />
        </EuiFlexItem>
        <ArHorizontalRule threeQuarter={true} margin={"l"} />
        <EuiFlexItem>
          {server.siteType === "woo" ? (
            <WooRoleLinksSection
              server={server}
              guildRoles={guildRoles.data}
              productServerRoleLinks={productServerRoleLinks.data}
              isLoading={productServerRoleLinks.isLoading}
            />
          ) : (
            <ArRoleLinksSection
              server={server}
              guildRoles={guildRoles.data}
              productServerRoleLinks={productServerRoleLinks.data}
              isLoading={productServerRoleLinks.isLoading}
            />
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </ArPanel>
  );
};
