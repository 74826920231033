import React from "react";
import { useNavigate } from "react-router-dom";

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiText,
  EuiTextColor,
  EuiTitle,
} from "@elastic/eui";
import { css } from "@emotion/react";

import { ArIconDescription } from "../../arComponents/ArIconDescription";
import { ROUTE_BUILDER } from "../../components/app/app";

const ArChecks = [
  <EuiText>
    <EuiTextColor color={"accent"}>Free storefront</EuiTextColor> hosted by
    Autorole
  </EuiText>,
  <EuiText>
    Customers <EuiTextColor color={"accent"}>automatically</EuiTextColor>{" "}
    assigned linked roles(s) after purchase
  </EuiText>,
  <EuiText>
    <EuiTextColor color={"accent"}>Customize Coupons</EuiTextColor> with
    advanced logic-flow
  </EuiText>,
  <EuiText>
    <EuiTextColor color={"accent"}>Compare-at-Price</EuiTextColor> and{" "}
    <EuiTextColor color={"accent"}>Free Trials </EuiTextColor>
    options available
  </EuiText>,
  <EuiText>
    <EuiTextColor color={"accent"}>Secure</EuiTextColor> payment portal hosted
    by Stripe
  </EuiText>,
];

export const ArBenefitsPanel = () => {
  const navigate = useNavigate();
  return (
    <EuiPanel className={"ar-panel-light"}>
      <EuiFlexGroup
        css={css`
          height: 100%;
        `}
        direction={"column"}
        justifyContent={"spaceBetween"}
        responsive={false}
      >
        <EuiFlexItem>
          <EuiFlexGroup
            direction={"column"}
            alignItems={"center"}
            responsive={false}
          >
            <EuiFlexItem>
              <EuiTitle size={"m"}>
                <h1>Autorole Storefront</h1>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText textAlign={"center"}>
                Fast and easy storefront hosted by Autorole
                <br />
                <i>
                  <EuiTextColor color={"accent"}>
                    Recommended for most servers
                  </EuiTextColor>
                </i>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText textAlign={"center"}>
                4% per transaction - automatically deducted
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup
                direction={"column"}
                gutterSize={"m"}
                responsive={false}
              >
                {ArChecks.map((check, index) => {
                  return (
                    <EuiFlexItem key={`woo-check-${index}`}>
                      <ArIconDescription
                        icon={"checkInCircleFilled"}
                        description={check}
                      />
                    </EuiFlexItem>
                  );
                })}
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem
          css={css`
            justify-content: end;
          `}
        >
          <EuiFlexGroup justifyContent={"center"}>
            <EuiFlexItem
              grow={false}
              css={css`
                justify-content: end;
              `}
            >
              <EuiButton
                onClick={() => navigate(ROUTE_BUILDER.SIGN_UP("autorole"))}
              >
                Get Started
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};
