import React from "react";

import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { DataTimePeriod } from "../../@types/data";
import { ServerState } from "../../@types/server";
import { useOrdersOverTime } from "../../features/api/data";

import { OverTimeBarChart } from "./OverTimeBarChart";

interface TotalOrderCountOverTimeProps {
  server: ServerState;
  timePeriod: DataTimePeriod;
}

export const TotalOrderCountOverTime: React.FC<
  TotalOrderCountOverTimeProps
> = ({ server, timePeriod }) => {
  const ordersOverTime = useOrdersOverTime(server.id, timePeriod);

  return (
    <EuiFlexGroup direction={"column"}>
      <EuiFlexItem>
        <OverTimeBarChart
          title={"Orders"}
          data={ordersOverTime.data}
          isLoading={ordersOverTime.isLoading}
          xAccessor={"formattedInterval"}
          yAccessors={["orderCount"]}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
