import { cloneDeep, isEmpty, orderBy } from "lodash";
import React, { useEffect } from "react";
import { useImmer } from "use-immer";

import {
  EuiButtonEmpty,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingContent,
  EuiTitle,
} from "@elastic/eui";

import { DiscordGuildRole } from "../../../../@types/discord";
import { ServerState } from "../../../../@types/server";
import { ProductServerRoleLink } from "../../../../@types/serverRole";
import { useProducts } from "../../../../features/api/products";
import { useDeleteProductServerRoleLink } from "../../../../features/api/server-roles";

interface ManageServerSettingsFormProps {
  server: ServerState;
  guildRoles: DiscordGuildRole[] | undefined;
  productServerRoleLinks: ProductServerRoleLink[] | undefined;
  isLoading: boolean;
}

const newRow: ProductServerRoleLink = {
  id: "",
  serverId: "",
  productId: "",
  serverRole: {
    id: "",
    serverId: "",
    discordRoleId: "",
    discordRoleName: "",
    productId: "",
    isDefault: false,
  },
  product: { id: "", serverId: "", productId: "", name: "" },
};

export const ArRoleLinksSection: React.FC<ManageServerSettingsFormProps> = ({
  server,
  guildRoles,
  productServerRoleLinks,
  isLoading,
}) => {
  const arProducts = useProducts(server.id);

  const deleteProductServerRoleLink = useDeleteProductServerRoleLink();

  const [data, setData] = useImmer<ProductServerRoleLink[]>(
    productServerRoleLinks || [newRow]
  );
  const [disableAdd, setDisableAdd] = useImmer<boolean>(false);

  useEffect(() => {
    const newData = productServerRoleLinks || [newRow];
    setData(newData);
  }, [productServerRoleLinks]);

  useEffect(() => {
    for (const item of data) {
      if (isEmpty(item.productId) || isEmpty(item.serverRole?.id)) {
        setDisableAdd(true);
        return;
      }
    }
    setDisableAdd(false);
  }, [productServerRoleLinks, data]);

  const onDelete = async (
    productServerRoleLink: ProductServerRoleLink,
    index: number
  ) => {
    if (
      isEmpty(productServerRoleLink.productId) ||
      isEmpty(productServerRoleLink.serverRole?.id)
    ) {
      let newData = cloneDeep(data);
      newData.splice(index, 1);
      if (newData.length === 0) {
        newData = [newRow];
      }
      setData(newData);
    } else {
      deleteProductServerRoleLink.mutate(productServerRoleLink);
    }
  };

  const onAddRow = () => {
    const newData = cloneDeep(data);
    newData.push(newRow);
    setData(newData);
  };

  return (
    <EuiFlexGroup direction={"column"}>
      <EuiFlexItem>
        <EuiTitle size={"s"}>
          <h2>Role Links</h2>
        </EuiTitle>
        <EuiTitle size={"xxxs"}>
          <h2>Link one or more Discord roles to each subscription.</h2>
        </EuiTitle>
      </EuiFlexItem>
      <EuiFlexItem>
        {isLoading ||
        !productServerRoleLinks ||
        !server ||
        !guildRoles ||
        !arProducts.isSuccess ? (
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiLoadingContent lines={6} />
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          <EuiFlexGroup direction={"column"}>
            {!data ||
            data.length === 0 ||
            (data.length === 1 &&
              (isEmpty(data[0].productId) ||
                isEmpty(data[0].serverRole?.id))) ? (
              <EuiFlexItem>
                <EuiCallOut
                  title="No Role Links Assigned"
                  color="warning"
                  iconType="alert"
                >
                  {server.siteType === "woo" ? (
                    <p>
                      WooCommerce purchases not linked to a role will{" "}
                      <u>
                        <b>not</b>
                      </u>{" "}
                      be logged!
                    </p>
                  ) : (
                    <p>
                      Subscriptions not linked to a role will{" "}
                      <u>
                        <b>not</b>
                      </u>{" "}
                      be logged!
                    </p>
                  )}
                </EuiCallOut>
              </EuiFlexItem>
            ) : null}
            <EuiFlexItem>
              {orderBy(data, (i) => i.product.name, "asc").map(
                (productServerRoleLink, index) => {
                  return (
                    // <ArLinkRoleRow
                    //   server={server}
                    //   key={`${productServerRoleLink.id}`}
                    //   showLabel={index === 0}
                    //   productServerRoleLink={productServerRoleLink}
                    //   onDelete={() => onDelete(productServerRoleLink, index)}
                    //   guildRoles={guildRoles}
                    //   arProducts={arProducts.data}
                    // />
                    null
                  );
                }
              )}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup justifyContent={"spaceAround"} gutterSize={"none"}>
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty
                    iconType="plus"
                    disabled={disableAdd}
                    onClick={() => onAddRow()}
                  >
                    Add {data.length === 0 ? "Role Link" : "Another Role Link"}
                  </EuiButtonEmpty>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
