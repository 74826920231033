import {
  QueryObserverOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { Params, SetDefaultServeRole } from "../../@types/api";
import { ArProductLinkPayload } from "../../@types/product";
import { ProductServerRoleLink, ServerRole } from "../../@types/serverRole";
import { addToast } from "../../components/toast";

import { api } from "./api";

export const upsertProductServerRoleLink = async (
  role: ProductServerRoleLink
) => {
  const resp = await api.patch("/server-role-links", role);
  return resp.data;
};

export const useUpsertProductServerRoleLink = () => {
  return useMutation<any, Error, any>(
    async (role: ProductServerRoleLink) => upsertProductServerRoleLink(role),
    {
      onError: (error) => {
        addToast({
          id: "upsert-product-server-role-link-failure",
          color: "danger",
          title: error.message,
        });
      },
    }
  );
};

export const setDefaultServerRole = async (
  serverId: string,
  discordRoleId: string,
  discordRoleName: string
) => {
  const resp = await api.post("/server-roles/default", {
    serverId,
    discordRoleId,
    discordRoleName,
  });
  return resp.data;
};

export const useSetDefaultServerRole = () => {
  return useMutation<any, Error, any>(async (params: SetDefaultServeRole) =>
    setDefaultServerRole(
      params.serverId,
      params.discordRoleId,
      params.discordRoleName
    )
  );
};

export const defaultRoleKeys = {
  base: [{ scope: "defaultRole" }] as const,
  one: (serverId: string) =>
    [{ ...defaultRoleKeys.base[0], serverId }] as const,
};

export const fetchDefaultServerRole = async (serverId: string) => {
  const resp = await api.get<ServerRole>("/server-roles/default", {
    params: { serverId },
  });
  return resp.data;
};

export const useDefaultServerRole = (
  serverId: string,
  options?: QueryObserverOptions<ServerRole, Error>
) =>
  useQuery<ServerRole, Error>(
    defaultRoleKeys.one(serverId),
    async () => fetchDefaultServerRole(serverId),
    options
  );

export const productServerRoleLinksKeys = {
  all: [{ scope: "productServerRoleLinks" }] as const,
  server: (serverId: string) =>
    [{ ...productServerRoleLinksKeys.all[0], serverId }] as const,
  one: (serverId: string, productId: string) =>
    [{ ...productServerRoleLinksKeys.server(serverId)[0], productId }] as const,
};

export const FetchProductServerRoleLinks = async (serverId: string) => {
  const resp = await api.get<ProductServerRoleLink[]>("/server-role-links", {
    params: { serverId },
  });
  return resp.data;
};

export const useProductServerRoleLinks = (
  serverId: string,
  options?: QueryObserverOptions<ProductServerRoleLink[], Error>
) =>
  useQuery<ProductServerRoleLink[], Error>(
    productServerRoleLinksKeys.server(serverId),
    async () => FetchProductServerRoleLinks(serverId),
    options
  );

export const deleteProductServerRoleLink = async (params: Params) => {
  const resp = await api.delete("/server-role-links", { params });
  return resp.data;
};

export const useDeleteProductServerRoleLink = (
  options?: UseMutationOptions<any, Error, any, any>
) => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>(
    async (role: ProductServerRoleLink) =>
      deleteProductServerRoleLink({
        serverId: role.serverId,
        productId: role.productId,
        serverRoleId: role.serverRole!.id,
      }),
    {
      onSuccess: (data) => {
        void queryClient.invalidateQueries(productServerRoleLinksKeys.all);
      },
      onError: (error) => {
        addToast({
          id: "delete-product-server-role-link-failure",
          color: "danger",
          title: error.message,
        });
      },
      ...options,
    }
  );
};

export const updateArProductRoleLinks = async (
  payload: ArProductLinkPayload
) => {
  const resp = await api.patch("/ar-product-role-links", payload);
  return resp.data;
};

export const useUpdateArProductRoleLinks = (serverId: string) => {
  const queryClient = useQueryClient();
  return useMutation<ArProductLinkPayload, Error, ArProductLinkPayload>(
    ["mutatingArProductLink"],
    async (role: ArProductLinkPayload) => updateArProductRoleLinks(role),
    {
      onSuccess: (data) => {
        void queryClient.invalidateQueries(productServerRoleLinksKeys.all);
        addToast({
          id: "update-server-roles-success",
          title: "Product Updated",
          color: "success",
        });
      },
      onError: (error) => {
        addToast({
          id: "upsert-ar-role-link-failure",
          color: "danger",
          title: error.message,
        });
      },
    }
  );
};
