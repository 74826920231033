import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import {
  EuiButton,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiText,
} from "@elastic/eui";
import { css } from "@emotion/react";

import { ArPage } from "../../arComponents/ArPage";
import { ArPanel } from "../../arComponents/ArPanel";
import { Header } from "../../components/header/Header";
import { ManagerServerHeader } from "../../components/header/ManageServerHeader";
import { LoadingOverlay } from "../../components/loading/LoadingOverlay";
import { MissingRolesModal } from "../../components/modal/MissingRolesModal";
import { useUser } from "../../features/api/auth";
import {
  IntegrationStatus,
  usePrefetchServerMemberTable,
} from "../../features/api/servers";
import { useStripeRefresh, useStripeReturn } from "../../features/api/stripe";
import { useServerFromUrl } from "../../features/hooks/hooks";

import { ManageArStoreSubPage } from "./subPages/ManageServer.manageArStoreSubPage";
import { ManageServerManageMembersSubPage } from "./subPages/ManageServer.manageMembersSubPage";
import { ManageServerManageRolesSubPage } from "./subPages/ManageServer.manageRolesSubPage";
import { ManageServerPerformanceSubPage } from "./subPages/ManageServer.performanceSubPage";
import { ManageServerSettingsSubPage } from "./subPages/ManageServer.settingsSubPage";

interface ManageServerBaseRouteProps {
  subPath: string;
  children?: ReactNode;
}

export const ManageServerRoot: React.FC<ManageServerBaseRouteProps> = ({
  subPath,
  children,
}) => {
  const server = useServerFromUrl();
  const prefetchMemberTable = usePrefetchServerMemberTable(server.data?.id);
  const user = useUser();

  const stripeRefresh = useStripeRefresh();

  // const discordApi = useDiscordApi(server.data?.id, "guilds", "priority");

  const navigate = useNavigate();
  if (server.isSuccess && user.isSuccess) {
    const child = () => {
      if (subPath === "account") {
        return (
          <ManageServerSettingsSubPage server={server.data} user={user.data} />
        );
      }
      if (subPath === "roles") {
        return <ManageServerManageRolesSubPage server={server.data} />;
      }
      if (subPath === "members") {
        return <ManageServerManageMembersSubPage server={server.data} />;
      }
      if (subPath === "performance") {
        return <ManageServerPerformanceSubPage server={server.data} />;
      }
      if (server.data.siteType === "autorole" && subPath === "store") {
        return <ManageArStoreSubPage server={server.data} />;
      }
    };

    return (
      <ArPage
        isLoading={server.isLoading || server.isRefetching}
        header={<Header />}
        subHeader={<ManagerServerHeader />}
        body={
          <>
            <EuiFlexGroup justifyContent={"flexStart"} direction={"column"}>
              <EuiFlexItem>
                {server.data.integrationStatus >=
                IntegrationStatus.BillingAccepted ? (
                  <EuiFlexGroup direction={"column"}>
                    {server.data.testMode &&
                    server.data.siteType === "autorole" ? (
                      <EuiFlexItem>
                        <EuiFlexItem>
                          <EuiCallOut
                            title="Test Mode On - Test Purchases Will Affect Actual User Roles!"
                            color="warning"
                            iconType="alert"
                          >
                            Any test purchases made while in test mode will
                            change the respective user's role!
                          </EuiCallOut>
                        </EuiFlexItem>
                      </EuiFlexItem>
                    ) : null}
                    <EuiFlexItem>{child()}</EuiFlexItem>
                  </EuiFlexGroup>
                ) : (
                  <ArPanel>
                    <EuiFlexGroup direction={"column"}>
                      <EuiFlexItem>
                        <EuiText>
                          Complete onboarding to access this page
                        </EuiText>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <EuiFormRow>
                          <EuiButton
                            isLoading={stripeRefresh.isLoading}
                            onClick={() => stripeRefresh.mutate(server.data.id)}
                            fullWidth={false}
                          >
                            Finish Onboarding
                          </EuiButton>
                        </EuiFormRow>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </ArPanel>
                )}
              </EuiFlexItem>
            </EuiFlexGroup>
            <MissingRolesModal />
          </>
        }
        restrictWidth={true}
      />
    );
  }

  return <LoadingOverlay />;
};
