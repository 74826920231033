import React, { FC, ReactNode } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiTitle } from "@elastic/eui";

interface SplitPanelTitleProps {
  left: ReactNode;
  right: ReactNode;
}
export const SplitPanelTitle: FC<SplitPanelTitleProps> = ({ left, right }) => {
  return (
    <EuiFlexItem>
      <EuiFlexGroup responsive={false}>
        <EuiFlexItem>
          {typeof left === "string" ? (
            <EuiTitle size={"s"}>
              <h1>{left}</h1>
            </EuiTitle>
          ) : (
            left
          )}
        </EuiFlexItem>
        <EuiFlexItem grow={false}>{right}</EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>
  );
};
