import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";

import {EuiPageBody} from "@elastic/eui";

import {ROUTES} from "../../components/app/app";
import {LoadingOverlay} from "../../components/loading/LoadingOverlay";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LinkRouteProps {}
export const WooLinkRedirect: React.FC<LinkRouteProps> = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const parts = location.pathname.split("/");
    const a = parts[parts.length - 1];
    const e = new URLSearchParams(location.search).get("email");
    const o = new URLSearchParams(location.search).get("sourceId");
    navigate(`/${ROUTES.SERVER}/${a}?email=${e}&sourceId=${o}`);
  }, []);

  return (
    <EuiPageBody>
      <LoadingOverlay />
    </EuiPageBody>
  );
};
