import { useQuery } from "@tanstack/react-query";

import {
  DataSummary,
  DataTimePeriod,
  OverTimeChartData,
  TableDataRow,
} from "../../@types/data";

import { api } from "./api";

const performanceKeys = {
  performance: [{ scope: "performance" }] as const,
  chart: (serverId: string, chart: string, timePeriod: DataTimePeriod) =>
    [
      {
        ...performanceKeys.performance[0],
        chart,
        serverId,
        timePeriod,
      },
    ] as const,
};
export const fetchGrossRevenueOverTime = async (
  serverId: string,
  timePeriod: string
): Promise<OverTimeChartData[]> => {
  const resp = await api.get<OverTimeChartData[]>(
    "/data/grossRevenueOverTime",
    {
      params: { serverId, timePeriod },
    }
  );
  return resp.data;
};

export const useGrossRevenueOverTime = (
  serverId: string,
  timePeriod: DataTimePeriod
) =>
  useQuery<OverTimeChartData[], Error>(
    performanceKeys.chart(serverId, "grossRevenueOverTime", timePeriod),
    async () => fetchGrossRevenueOverTime(serverId, timePeriod)
  );

export const fetchOrdersOverTime = async (
  serverId: string,
  timePeriod: DataTimePeriod
): Promise<OverTimeChartData[]> => {
  const resp = await api.get<OverTimeChartData[]>("/data/ordersOverTime", {
    params: { serverId, timePeriod },
  });
  return resp.data;
};

export const useOrdersOverTime = (
  serverId: string,
  timePeriod: DataTimePeriod
) =>
  useQuery<OverTimeChartData[], Error>(
    performanceKeys.chart(serverId, "ordersOverTime", timePeriod),
    async () => fetchOrdersOverTime(serverId, timePeriod)
  );

export const fetchOrderByProductOverTime = async (
  serverId: string,
  timePeriod: DataTimePeriod
): Promise<OverTimeChartData[]> => {
  const resp = await api.get<OverTimeChartData[]>(
    "/data/ordersByProductOverTime",
    {
      params: { serverId, timePeriod },
    }
  );
  return resp.data;
};

export const useFetchOrderByProductOverTime = (
  serverId: string,
  timePeriod: DataTimePeriod
) =>
  useQuery<OverTimeChartData[], Error>(
    performanceKeys.chart(serverId, "ordersByProductOverTime", timePeriod),
    async () => fetchOrderByProductOverTime(serverId, timePeriod)
  );

export const fetchDataSummary = async (
  serverId: string,
  timePeriod: DataTimePeriod
): Promise<DataSummary[]> => {
  const resp = await api.get<DataSummary[]>("/data/dataSummary", {
    params: { serverId, timePeriod },
  });
  return resp.data;
};

export const useDataSummary = (serverId: string, timePeriod: DataTimePeriod) =>
  useQuery<DataSummary[], Error>(
    performanceKeys.chart(serverId, "dataSummary", timePeriod),
    async () => fetchDataSummary(serverId, timePeriod)
  );

export const fetchSummaryTableData = async (
  serverId: string,
  timePeriod: DataTimePeriod
): Promise<TableDataRow[]> => {
  const resp = await api.get<TableDataRow[]>("/data/dataTableSummary", {
    params: { serverId, timePeriod },
  });
  return resp.data;
};

export const useSummaryTableData = (
  serverId: string,
  timePeriod: DataTimePeriod
) =>
  useQuery<TableDataRow[], Error>(
    performanceKeys.chart(serverId, "dataSummaryTable", timePeriod),
    async () => fetchSummaryTableData(serverId, timePeriod)
  );
