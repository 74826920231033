import AWS from "aws-sdk";
import { PutObjectRequest } from "aws-sdk/clients/s3";

import { ServerState } from "../../@types/server";
import { addToast } from "../../components/toast";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ID,
  secretAccessKey: process.env.REACT_APP_AWS_KEY,
});

export const s3Upload = async (
  server: ServerState,
  file: Blob,
  subFolder: string,
  fileName: string,
  onComplete: (url: string) => void
) => {
  const staticBucket = new AWS.S3({
    params: {
      Bucket: process.env.REACT_APP_STATIC_BUCKET,
    },
    region: process.env.REACT_APP_REGION,
  });

  const filename = `${server.id}/${subFolder}/${fileName}`;

  const params: PutObjectRequest = {
    Body: file,
    Bucket: process.env.REACT_APP_STATIC_BUCKET as string,
    Key: filename,
    ContentType: file.type,
  };

  try {
    const uploadedFile = await staticBucket.upload(params).promise();
    addToast({
      id: "file-upload-success",
      color: "success",
      title: "File Uploaded",
    });
    onComplete(uploadedFile.Location);
  } catch (e) {
    addToast({
      id: "file-upload-failure",
      color: "danger",
      title: "File Uploaded Failed",
    });
  }
};
