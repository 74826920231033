import { FC, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@elastic/eui";
import { css } from "@emotion/react";

import { AppState } from "../@types";
import { isDialogOpen, setModal } from "../features/redux/appState";

interface BaseModalProps {
  modalKey: keyof AppState["appState"]["modals"];
  title: ReactNode;
  body: ReactNode;
  showCancel?: boolean;
  noText?: string;
  yesText?: string;
  onYesClick?: () => void;
  onClose?: () => void;
  minWidth?: string;
}

export const ArModal: FC<BaseModalProps> = ({
  modalKey,
  title,
  body,
  showCancel,
  noText,
  yesText,
  onYesClick,
  onClose,
  minWidth,
}) => {
  const isOpen = useSelector(isDialogOpen(modalKey));
  const dispatch = useDispatch();

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    dispatch(setModal({ modal: modalKey, open: false }));
  };

  const handleYesClick = () => {
    if (onYesClick) {
      onYesClick();
    }
    handleClose();
  };
  let modal;

  if (isOpen) {
    modal = (
      <EuiModal
        onClose={handleClose}
        css={css`
          min-width: ${minWidth};
        `}
      >
        <EuiModalHeader
          css={css`
            justify-content: center;
            width: 100%;
          `}
        >
          <EuiModalHeaderTitle
            css={css`
              width: 100%;
            `}
          >
            {title}
          </EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>{body}</EuiModalBody>

        <EuiModalFooter>
          {showCancel ? (
            <EuiButtonEmpty onClick={handleClose}>
              {noText || "Cancel"}
            </EuiButtonEmpty>
          ) : null}
          <EuiButton color="accent" onClick={handleYesClick}>
            {yesText || "Save"}
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }
  return <div>{modal}</div>;
};
