import React, { FC, ReactNode } from "react";

import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { ArLogo } from "../../arComponents/ArLogo";
import { ArPanel } from "../../arComponents/ArPanel";
import { useServerFromUrl } from "../../features/hooks/hooks";

interface ServerDataProps {
  children?: ReactNode;
}

export const ServerView: FC<ServerDataProps> = ({ children }) => {
  const server = useServerFromUrl();

  if (server.isSuccess) {
    return (
      <ArPanel>
        <EuiFlexGroup justifyContent={"flexStart"} direction={"column"}>
          <EuiFlexItem grow={false}>
            {server.data.logoUrl ? (
              <ArLogo logoUrl={server.data.logoUrl} />
            ) : null}
          </EuiFlexItem>
          <EuiFlexItem>{children}</EuiFlexItem>
        </EuiFlexGroup>
      </ArPanel>
    );
  }
  return null;
};
