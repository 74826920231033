import { cloneDeep } from "lodash";
import React, { ReactNode, useCallback, useEffect, useMemo } from "react";
import { useImmer } from "use-immer";

import {
  EuiBadge,
  EuiButton,
  EuiButtonIcon,
  EuiCallOut,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiIcon,
  EuiInMemoryTable,
  EuiLoadingContent,
  RIGHT_ALIGNMENT,
} from "@elastic/eui";
import { Search } from "@elastic/eui/src/components/basic_table/in_memory_table";
import {
  EuiTableFieldDataColumnType,
  EuiTableSortingType,
} from "@elastic/eui/src/components/basic_table/table_types";
import { SchemaType } from "@elastic/eui/src/components/search_bar/search_box";

import { DiscordGuildRole } from "../../@types/discord";
import {
  ServerMemberTableRow,
  ServerState,
  SeverRoleBadge,
} from "../../@types/server";
import { AddRemoveRolesRequest } from "../../@types/serverRole";
import {
  SubscriptionRerun,
  SubscriptionState,
} from "../../@types/subscription";
import {
  useBulkModifyRoles,
  useDiscordApi,
} from "../../features/api/discord-server-api";
import { useProductServerRoleLinks } from "../../features/api/server-roles";
import { useServerMemberTable } from "../../features/api/servers";
import {
  SubscriptionsRerun,
  useSubscriptionNames,
} from "../../features/api/subscriptions";
import { ACTIVE, INACTIVE, statusPrettier } from "../../features/constants";
import { ThemeDanger, ThemeWarning } from "../app/theme";
import { AddRemoveRolesPopover } from "../popover/AddRemoveRolesPopover";
import { MoreRolesPopover } from "../popover/MoreRolesPopover";
import { addToast } from "../toast";

import { ServerMemberTableDetails } from "./ServerMemberTableDetails";

export interface MemberIssue {
  type: "WARNING" | "ERROR";
  message: string;
}

const ISSUES: { [key: string]: MemberIssue } = {
  MissingDefaultRole: {
    type: "WARNING",
    message: "Missing Default Role",
  },
  PurchasedMissingRole: {
    type: "ERROR",
    message: "Purchased & Active, Missing Role",
  },
  PurchasedIncorrectRole: {
    type: "ERROR",
    message: "Purchased & Active, Incorrect Role",
  },
  // RoleNotPurchased: {
  //   type: "ERROR",
  //   message: "Role Not Purchased",
  // },
  InactiveWithRole: {
    type: "ERROR",
    message: "Inactive, Still has Role",
  },
};

interface ServerMemberTableProps {
  server: ServerState;
}

export interface TableDataRow extends ServerMemberTableRow {
  id: string;
  statusFilter: string | undefined;
  roleFilter: string | undefined;
  subscriptionFilter: string | undefined;
}

export const ServerMemberTable: React.FC<ServerMemberTableProps> = ({
  server,
}) => {
  const [error, setError] = useImmer(null);
  const [query, setQuery] = useImmer("isAutoroleSubscriber:true");
  const [selection, setSelection] = useImmer<TableDataRow[]>([]);
  const [tableData, setTableData] = useImmer<TableDataRow[]>([]);
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useImmer<{
    [id: string]: ReactNode;
  }>({});

  const productServerRoleLinks = useProductServerRoleLinks(server.id);
  const modifyRoles = useBulkModifyRoles();

  const toggleDetails = (item: TableDataRow) => {
    setItemIdToExpandedRowMap((draft) => {
      if (draft[item.discordUser.user.id]) {
        delete draft[item.discordUser.user.id];
      } else {
        draft[item.discordUser.user.id] = (
          <ServerMemberTableDetails
            item={item}
            productServerRoleLinks={productServerRoleLinks.data}
          />
        );
      }
      return draft;
    });
  };

  const serverMemberTable = useServerMemberTable(server.id);

  const discordRoles = useDiscordApi<DiscordGuildRole[]>(
    server.id,
    "guilds",
    "roles"
  );

  const subscriptionNames = useSubscriptionNames(server.id);

  const updateMemberRoles = (updatedRows: ServerMemberTableRow[]) => {
    setTableData((oldTableData) => {
      const newTableData = cloneDeep(oldTableData);
      if (newTableData) {
        for (const updatedRow of updatedRows) {
          const index = newTableData.findIndex(
            (row) => row.discordUser.user.id === updatedRow.discordUser.user.id
          );
          newTableData[index] = addExtraData(updatedRow);
        }
        return newTableData;
      }
      return oldTableData;
    });
  };

  const selectionValue = {
    /**
     * A callback that will be called whenever the item selection changes
     */
    onSelectionChange: (selection: TableDataRow[]) => setSelection(selection),
    /**
     * A callback that is called per item to indicate whether it is selectable
     */
    selectable: (item: TableDataRow) => true,
    /**
     * A callback that is called per item to retrieve a message for its selectable state.We display these messages as a tooltip on an unselectable checkbox
     */
    // selectableMessage?: (selectable: boolean, item: TableDataRow) => string;
    initialSelected: [],
  };

  const addExtraData = useCallback((m: ServerMemberTableRow) => {
    return {
      id: m.discordUser.user.id,
      username: m.discordUser.user.username,
      roleFilter: m.activeRoles
        ? m.activeRoles.map((r) => r.name).join(";")
        : undefined,
      statusFilter: m.allSubscriptions
        ? m.allSubscriptions.map((s) => s.status).join(";")
        : undefined,
      subscriptionFilter: m.allSubscriptions
        ? m.allSubscriptions.map((s) => s.name).join(";")
        : undefined,
      issueFilter: m.issues
        ? m.issues.map((i) => i.message).join(";")
        : undefined,
      ...m,
    };
  }, []);

  useEffect(() => {
    if (serverMemberTable.data) {
      const newTableData = serverMemberTable.data.map(
        (row: ServerMemberTableRow) => {
          return addExtraData(row);
        }
      );
      setTableData(newTableData);
    }
  }, [serverMemberTable.data]);

  const actions = [
    {
      render: (item: ServerMemberTableRow) => {
        return (
          <EuiButtonIcon
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={async () => onReprocessClick(item)}
            isLoading={item?.disabled || false}
            color={"success"}
            aria-label={"refresh"}
            iconType={"refresh"}
          />
        );
      },
    },
  ];

  const columns = useMemo<EuiTableFieldDataColumnType<TableDataRow>[]>(() => {
    return [
      {
        field: "isAutoroleSubscriber",
        name: "Subscribed",
        sortable: true,
        align: "center",
        render: (value: boolean, record: TableDataRow) => {
          return value ? (
            <EuiIcon type={"check"} color={"success"} />
          ) : (
            <EuiIcon type={"cross"} color={"danger"} />
          );
        },
      },
      {
        field: "allSubscriptions",
        name: "Status",
        sortable: true,
        render: (
          value: SubscriptionState[] | undefined,
          record: TableDataRow
        ) => {
          if (!value) {
            return null;
          }

          let color = "success";
          const inactiveCount = value.filter((v) =>
            INACTIVE.includes(v.status)
          ).length;
          const activeCount = value.filter((v) =>
            ACTIVE.includes(v.status)
          ).length;

          if (value.length > 1) {
            if (activeCount > 0 && inactiveCount === 0) {
              color = "success";
            } else if (activeCount > 0 && inactiveCount > 0) {
              color = "warning";
            } else if (activeCount === 0 && inactiveCount > 0) {
              color = "danger";
            }
            return (
              <EuiHealth key={`health-${record.id}`} color={color}>
                Multiple
              </EuiHealth>
            );
          } else {
            return value.map((s: SubscriptionState) => {
              return (
                <EuiHealth
                  key={`health-${record.id}`}
                  color={ACTIVE.includes(s.status) ? "success" : "danger"}
                >
                  {statusPrettier(s.status)}
                </EuiHealth>
              );
            });
          }
        },
      },
      {
        field: "discordUser.user.username",
        name: "Username",
        sortable: true,
      },
      {
        field: "activeRoles",
        name: "Roles",
        width: "20%",
        sortable: true,
        render: (value: SeverRoleBadge[] | undefined, record: TableDataRow) => {
          if (!value) {
            return null;
          }

          const first = value.slice(0, 3);
          const ret = first.map((o: any) => {
            return (
              <EuiFlexGroup
                key={`active-roles-${record.id}-${o.id}`}
                direction={"column"}
                gutterSize={"xs"}
              >
                <EuiFlexItem key={`${record.id}-${o.id}`} grow={false}>
                  <EuiBadge
                    color={
                      o.color === 0 ? "default" : `#${o.color.toString(16)}`
                    }
                  >
                    {o.name}
                  </EuiBadge>
                </EuiFlexItem>
              </EuiFlexGroup>
            );
          });
          if (value.length > 3) {
            ret.push(<MoreRolesPopover items={value.slice(3)} />);
            return <div>{ret}</div>;
          } else {
            return <div>{ret}</div>;
          }
        },
        mobileOptions: {
          width: "100%",
        },
      },
      {
        field: "activeSubscriptions",
        name: "Active Subscriptions",
        sortable: (record) => {
          return record.allSubscriptions ? record.allSubscriptions.length : 0;
        },
        width: "20%",

        mobileOptions: {
          width: "100%",
        },

        render: (value: SubscriptionState[] | undefined, record) => {
          if (!value) {
            return null;
          }
          const names = value
            .filter((o: any) => o.status !== "cancelled")
            .map((o: any) => o.name);
          return names.join("; ");
        },
      },
      {
        field: "issues",
        name: "Notes",
        render: (value: MemberIssue[], record) => {
          if (value && value.length > 0) {
            return (
              <EuiFlexGroup direction={"column"} gutterSize={"xs"}>
                {value.map((v) => (
                  <EuiFlexItem key={`record-${record.id}-${v.message}`}>
                    <EuiBadge
                      iconType={"tokenException"}
                      color={v.type === "WARNING" ? ThemeWarning : ThemeDanger}
                    >
                      {v.message}
                    </EuiBadge>
                  </EuiFlexItem>
                ))}
              </EuiFlexGroup>
            );
          }
        },
      },
      {
        field: "",
        name: "",
        actions,
      },
      {
        name: "",
        field: "",
        align: RIGHT_ALIGNMENT,
        width: "40px",
        isExpander: true,
        render: (item, record) => {
          return (
            <EuiButtonIcon
              onClick={() => toggleDetails(item)}
              aria-label={
                itemIdToExpandedRowMap && [item] ? "Collapse" : "Expand"
              }
              iconType={itemIdToExpandedRowMap[item] ? "arrowUp" : "arrowDown"}
            />
          );
        },
      },
    ];
  }, [tableData]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const onChange = ({ query, error }) => {
    if (error) {
      setError(error);
    } else {
      setError(null);
      setQuery(query);
    }
  };

  const enableDisableRow = (
    item: ServerMemberTableRow,
    action: "enable" | "disable"
  ) => {
    setTableData((oldTableData) => {
      const newTableData = cloneDeep(oldTableData);
      const row = newTableData?.findIndex(
        (r) => r.discordUser.user.id === item.discordUser.user.id
      );
      if (row && newTableData) {
        newTableData[row].disabled = action === "disable";
        return newTableData;
      }
      return oldTableData;
    });
  };

  const onReprocessClick = async (item: ServerMemberTableRow) => {
    if (item.allSubscriptions) {
      enableDisableRow(item, "disable");
      const rerun: SubscriptionRerun = {
        serverId: server.id,
        email: item.allSubscriptions[0].email,
        discordAccountId: item.allSubscriptions[0].discordAccountId,
      };

      const resp = await SubscriptionsRerun(rerun);
      if (resp.status === 200) {
        updateMemberRoles([resp.data]);
        addToast({
          id: "update-subscriptions-success",
          title: `Subscriptions updated!`,
          color: "success",
        });
      }
      enableDisableRow(item, "enable");
    }
  };

  if (
    tableData !== undefined &&
    discordRoles.data !== undefined &&
    subscriptionNames.data !== undefined
  ) {
    const schema: SchemaType = {
      strict: false,
      fields: {
        username: {
          type: "string",
        },
        roles: {
          type: "string",
          validate: (value: string) => {
            if (
              value !== "" &&
              !discordRoles.data.some((role: any) => {
                return role.name === value;
              })
            ) {
              throw new Error(
                `unknown role (possible values: ${discordRoles.data
                  .map((role: any) => role.name)
                  .join(", ")})`
              );
            }
          },
        },
        subscriptions: {
          type: "string",
        },
        error: {
          type: "string",
        },
      },
    };

    const onBulkAddSave = (isAdd: boolean, roles: string[]) => {
      const discordAccountIds = selection.map(
        (item) => item.discordUser.user.id
      );

      const req: AddRemoveRolesRequest = {
        serverId: server.id,
        action: isAdd ? "ADD" : "REMOVE",
        changes: discordAccountIds.map((discordAccountId) => {
          return {
            discordAccountId,
            roles,
          };
        }),
      };

      modifyRoles.mutate(req, {
        onSuccess: (data) => {
          updateMemberRoles(data);
          addToast({
            id: "update-role-success",
            title: `Roles updated!`,
            color: "success",
          });
        },
      });
    };

    const sort: EuiTableSortingType<any> = {
      enableAllColumns: true,
      allowNeutralSort: true,
      sort: {
        field: "isAutoroleSubscriber",
        direction: "desc",
      },
    };

    const renderToolsLeft = () => {
      if (selection.length === 0) {
        return;
      }

      return (
        <EuiFlexGroup gutterSize={"s"}>
          <EuiFlexItem>
            <AddRemoveRolesPopover
              items={discordRoles.data}
              onSave={onBulkAddSave}
              isAdd={true}
            >
              <EuiButton size={"s"} color="primary" iconType="plus">
                Add Roles
              </EuiButton>
            </AddRemoveRolesPopover>
          </EuiFlexItem>
          <EuiFlexItem>
            <AddRemoveRolesPopover
              items={discordRoles.data}
              onSave={onBulkAddSave}
              isAdd={false}
            >
              <EuiButton size={"s"} color="primary" iconType="cross">
                Remove Roles
              </EuiButton>
            </AddRemoveRolesPopover>
          </EuiFlexItem>
        </EuiFlexGroup>
      );
    };

    const search: Search = {
      query: query,
      onChange: onChange,
      box: {
        incremental: true,
        schema: schema,
      },
      toolsLeft: renderToolsLeft(),
      filters: [
        {
          type: "field_value_toggle",
          field: "isAutoroleSubscriber",
          name: "User",
          value: true,
        },
        {
          type: "field_value_selection",
          field: "statusFilter",
          name: "Status",
          multiSelect: "or",
          filterWith: "includes",
          options: ACTIVE.concat(INACTIVE)
            .filter((s: string) => s !== "cancelled-subscription")
            .filter((s: string) => s !== "pending-cancellation")
            .map((s: string) => {
              return {
                value: s,
                name: statusPrettier(s),
              };
            })
            .sort((a: any, b: any) => (a > b ? 1 : b > a ? -1 : 0)),
        },
        {
          type: "field_value_selection",
          field: "roleFilter",
          name: "Roles",
          multiSelect: "or",
          filterWith: "includes",
          options: discordRoles.data
            .filter((role: any) => !role.name.includes("@"))
            .map((role: any) => {
              return {
                value: role.name,
                name: role.name,
                view: (
                  <EuiBadge
                    key={`badge-${role.id}`}
                    color={
                      role.color === 0
                        ? "default"
                        : `#${role.color.toString(16)}`
                    }
                  >
                    {role.name}
                  </EuiBadge>
                ),
              };
            })
            .sort((a: any, b: any) =>
              a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : b.name.toLowerCase() > a.name.toLowerCase()
                ? -1
                : 0
            ),
        },
        {
          type: "field_value_selection",
          field: "subscriptionFilter",
          name: "Subscriptions",
          multiSelect: "or",
          filterWith: "includes",
          options: subscriptionNames.data
            .map((sub: any) => {
              return {
                value: sub.name,
                name: sub.name,
              };
            })
            .sort((a: any, b: any) =>
              a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : b.name.toLowerCase() > a.name.toLowerCase()
                ? -1
                : 0
            ),
        },
        {
          type: "field_value_selection",
          field: "issueFilter",
          name: "Issues",
          multiSelect: "or",
          filterWith: "includes",
          options: Object.values(ISSUES)
            .map((issue: MemberIssue) => {
              return {
                value: issue.message,
                name: issue.message,
              };
            })
            .sort((a: any, b: any) =>
              a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : b.name.toLowerCase() > a.name.toLowerCase()
                ? -1
                : 0
            ),
        },
      ],
    };

    const rowProps = (item: any) => {
      if (item && item.issues && item.issues.length > 0) {
        if (item.issues.some((issue: MemberIssue) => issue.type === "ERROR")) {
          return {
            // className: "euiCallOut--danger",
          };
        }
        if (
          item.issues.some((issue: MemberIssue) => issue.type === "WARNING")
        ) {
          return {
            // className: "euiCallOut--warning",
          };
        }
      }
      return {};
    };

    return (
      <EuiFlexGroup direction={"column"}>
        {error !== null ? (
          <EuiFlexItem>
            <EuiCallOut
              iconType="faceSad"
              color="danger"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              title={`Invalid search: ${error.message}`}
            />
          </EuiFlexItem>
        ) : null}
        <EuiFlexItem>
          <EuiInMemoryTable
            items={tableData}
            columns={columns}
            rowProps={rowProps}
            pagination={true}
            itemId="id"
            itemIdToExpandedRowMap={itemIdToExpandedRowMap}
            isExpandable={true}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sorting={sort}
            search={search}
            hasActions={false}
            selection={selectionValue}
            isSelectable={true}
            tableLayout={"auto"}
            responsive={true}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }

  if (serverMemberTable.isError) {
    return <EuiEmptyPrompt body={<p>No data found</p>} />;
  }
  return <EuiLoadingContent lines={5} />;
};
