import React from "react";

import { EuiFlexGroup, EuiFlexItem, EuiImage } from "@elastic/eui";

interface LogoProps {
  logoUrl?: string;
}

export const ArLogo: React.FC<LogoProps> = ({ logoUrl }) => {
  return logoUrl ? (
    <EuiFlexGroup justifyContent={"spaceAround"} alignItems={"center"}>
      <EuiFlexItem grow={false}>
        <EuiImage
          style={{
            maxWidth: "400px",
            maxHeight: "200px",
            width: "-webkit-fill-available",
          }}
          alt={"logo"}
          src={logoUrl}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  ) : null;
};
