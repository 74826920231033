import getSymbolFromCurrency from "currency-symbol-map";
import { FormikProps } from "formik";
import React, { ChangeEvent, FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useImmer } from "use-immer";

import {
  EuiButton,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiMarkdownEditor,
  EuiSelect,
  EuiSwitch,
} from "@elastic/eui";
import { EuiSelectOption } from "@elastic/eui/src/components/form/select/select";
import { css } from "@emotion/react";

import { ArImage } from "../../../arComponents/ArImage";
import { setModal } from "../../../features/redux/appState";
import { ImageUploadModal } from "../../upload/ImageUploadModal";

import { FormValues, OuterProps } from "./types";

const subscriptionPeriodOptions: EuiSelectOption[] = [
  { value: "day", text: "days" },
  { value: "week", text: "weeks" },
  { value: "month", text: "months" },
  { value: "year", text: "years" },
  { value: "lifetime", text: "lifetime" },
];

export const ProductStep: FC<
  OuterProps & FormikProps<FormValues> & { handleClose: any }
> = ({
  server,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  isSubmitting,
  handleClose,
  setFieldValue,
}) => {
  const [messages, setMessages] = useImmer([]);

  const onSubscriptionIntervalUnitChange = (
    e: ChangeEvent<HTMLSelectElement>
  ) => {
    setFieldValue("subscriptionIntervalUnit", e.target.value);
    if (e.target.value === "lifetime") {
      setFieldValue("subscriptionInterval", 1);
    }
  };

  const onDescriptionChange = (e: string) => {
    setFieldValue("description", e);
  };

  // @ts-ignore
  const onDescriptionParse = useCallback((err: any, { messages, ast }) => {
    setMessages(err ? [err] : messages);
  }, []);

  const dispatch = useDispatch();

  return (
    <EuiFlexGroup direction={"column"}>
      <EuiFlexItem>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow
              fullWidth={true}
              label={"Name"}
              isInvalid={(isSubmitting || touched.name) && !!errors.name}
              error={errors.name}
            >
              <EuiFieldText
                fullWidth={true}
                name={"name"}
                value={values.name}
                required={touched.name}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup>
          <EuiFlexItem grow={3}>
            <EuiFlexItem>
              <EuiFormRow
                fullWidth={true}
                label={"Price"}
                isInvalid={(isSubmitting || touched.price) && !!errors.price}
                error={errors.price}
              >
                <EuiFieldText
                  prepend={getSymbolFromCurrency(server.currency || "USD")}
                  fullWidth={true}
                  name={"price"}
                  type={"number"}
                  value={values.price}
                  required={touched.price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem grow={3}>
            <EuiFormRow
              fullWidth={true}
              label={"Compare at Price"}
              isInvalid={
                (isSubmitting || touched.compareAtPrice) &&
                !!errors.compareAtPrice
              }
              error={errors.compareAtPrice}
            >
              <EuiFieldText
                fullWidth={true}
                name={"compareAtPrice"}
                prepend={getSymbolFromCurrency(server.currency || "USD")}
                type={"number"}
                value={values.compareAtPrice}
                required={false}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={6}>
            <EuiFormRow
              fullWidth={true}
              label={"Subscription Interval"}
              isInvalid={
                (isSubmitting || touched.subscriptionInterval) &&
                !!errors.subscriptionInterval
              }
              error={errors.subscriptionInterval}
            >
              <EuiFieldText
                prepend={
                  values.subscriptionIntervalUnit == "lifetime"
                    ? "Once"
                    : "Every"
                }
                append={
                  <EuiFlexGroup
                    gutterSize={"none"}
                    css={css`
                      width: 150px;

                      & svg {
                        background-color: transparent !important;
                      }
                    `}
                  >
                    <EuiFlexItem>
                      <EuiSelect
                        value={values.subscriptionIntervalUnit}
                        options={subscriptionPeriodOptions}
                        disabled={isSubmitting}
                        onChange={onSubscriptionIntervalUnitChange}
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                }
                disabled={
                  values.subscriptionIntervalUnit === "lifetime" || isSubmitting
                }
                type={"number"}
                step={1}
                fullWidth={true}
                name={"subscriptionInterval"}
                value={values.subscriptionInterval}
                required={true}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup justifyContent={"center"}>
          <EuiFlexItem grow={false}>
            <EuiFormRow hasEmptyLabelSpace={true}>
              <EuiSwitch
                label={"Enabled"}
                disabled={isSubmitting}
                checked={values.freeTrial !== undefined}
                // eslint-disable-next-line node/handle-callback-err
                onChange={(e) => {
                  if (values.freeTrial) {
                    setFieldValue("freeTrial", undefined);
                  } else {
                    setFieldValue("freeTrial", 7);
                  }
                }}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFormRow
              label={"Free Trial"}
              isInvalid={
                (isSubmitting || touched.freeTrial) && !!errors.freeTrial
              }
              error={errors.freeTrial}
            >
              <EuiFieldText
                fullWidth={true}
                name={"freeTrial"}
                append={"days"}
                type={"number"}
                value={values.freeTrial || ""}
                required={values.freeTrial !== undefined}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={values.freeTrial === undefined || isSubmitting}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup justifyContent={"center"}>
          <EuiFlexItem grow={false}>
            <EuiFormRow
              label={"Image"}
              isInvalid={
                (isSubmitting || touched.imageUrl) && !!errors.imageUrl
              }
              error={errors.imageUrl}
            >
              <EuiFlexGroup direction={"column"}>
                {values.imageUrl ? (
                  <EuiFlexItem>
                    <ArImage
                      alt={"product-image"}
                      src={values.imageUrl}
                      size={"m"}
                    />
                  </EuiFlexItem>
                ) : null}
                <EuiFlexItem grow={false}>
                  <EuiButton
                    color={"accent"}
                    disabled={isSubmitting}
                    onClick={() => {
                      dispatch(setModal({ modal: "imageUpload", open: true }));
                    }}
                  >
                    Choose Image
                  </EuiButton>
                </EuiFlexItem>
                <ImageUploadModal
                  id={values.id}
                  server={server}
                  aspectRatio={1}
                  initialImgSrc={values.imageUrl}
                  onComplete={(location) => {
                    setFieldValue("imageUrl", location);
                    dispatch(setModal({ modal: "imageUpload", open: false }));
                  }}
                />
              </EuiFlexGroup>
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup>
          <EuiFlexItem grow={2}>
            <EuiFormRow
              fullWidth={true}
              label={"Description"}
              isInvalid={
                (isSubmitting || touched.description) && !!errors.description
              }
              error={errors.description}
            >
              <EuiMarkdownEditor
                aria-label="Subscription Description"
                placeholder="Your description here..."
                value={values.description}
                onChange={(e) => onDescriptionChange(e)}
                height={400}
                onParse={onDescriptionParse}
                errors={messages}
                readOnly={isSubmitting}
                initialViewMode="editing"
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
