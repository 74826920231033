import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppState } from "../../@types";
import { SubscribeFormState } from "../../@types/subscribeForm";

const initialState: SubscribeFormState = {
  init: false,
  email: "",
  sourceId: "",
};

export const slice = createSlice({
  name: "subscribeForm",
  initialState,
  reducers: {
    setSubscribeFormEmail: (
      subscribeFormState,
      { payload: value }: PayloadAction<string>
    ) => {
      subscribeFormState.init = true;
      subscribeFormState.email = value;
    },
    setSubscribeFormSourceId: (
      subscribeFormState,
      { payload: value }: PayloadAction<string>
    ) => {
      subscribeFormState.init = true;
      subscribeFormState.sourceId = value;
    },
    clearSubscribeForm: (subscribeFormState) => {
      Object.assign(subscribeFormState, initialState);
    },
  },
});

export const {
  setSubscribeFormEmail,
  setSubscribeFormSourceId,
  clearSubscribeForm,
} = slice.actions;

export const subscribeFormReducer = slice.reducer;

export const getSubscribeForm = (state: AppState) => state.subscribeForm;
