import getSymbolFromCurrency from "currency-symbol-map";
import { FormikProps, withFormik } from "formik";
import moment, { Moment } from "moment";
import React, { ChangeEvent, Dispatch, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useImmer } from "use-immer";
import { v4 } from "uuid";
import voucher_codes from "voucher-code-generator";
import * as Yup from "yup";

import {
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiDatePicker,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiLink,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiRadioGroup,
  EuiSelect,
  EuiSwitch,
  EuiTitle,
} from "@elastic/eui";
import { EuiSelectOption } from "@elastic/eui/src/components/form/select/select";
import { EuiSwitchEvent } from "@elastic/eui/src/components/form/switch/switch";
import { css } from "@emotion/react";
import {
  QueryClient,
  useIsMutating,
  useQueryClient,
} from "@tanstack/react-query";

import { AppSettingsState } from "../../@types/appSettings";
import { ArPromotionCode } from "../../@types/coupon";
import { ServerState } from "../../@types/server";
import {
  productKeys,
  useDeleteCoupon,
  useProducts,
  useUpdateCoupon,
} from "../../features/api/products";
import { getModal, setModal } from "../../features/redux/appState";
import { SelectArProduct } from "../select/SelectArProduct";

interface ArEditCouponModalProps {
  server: ServerState;
}

type FormValues = ArPromotionCode;

interface OuterProps {
  server: ServerState;
  updateCoupon: any;
  queryClient: QueryClient;
  initialValues: Partial<FormValues>;
  dispatch: Dispatch<any>;
}

const BaseForm = (props: OuterProps & FormikProps<FormValues>) => {
  const dialog = useSelector(
    getModal("editCoupon")
  ) as AppSettingsState["modals"]["editCoupon"];

  const arProducts = useProducts(props.server.id);

  const deleteCoupon = useDeleteCoupon(props.server.id);
  const isCouponMutating = useIsMutating(["mutateCoupon"]);

  const [selectedCouponType, setSelectedCouponType] =
    useImmer<CouponTypeId>("percentage");

  const [appliesTo, setAppliesTo] = useImmer<boolean>(false);
  const [isExpires, setIsExpires] = useImmer<boolean>(false);
  const [expiresOn, setExpiresOn] = useImmer<Moment | null>(
    props.values.expiresAt
  );
  const [isMaxRedemptions, setIsMaxRedemptions] = useImmer<boolean>(false);
  const [isFirstOrder, setIsFirstOrder] = useImmer<boolean>(false);

  type CouponTypeId = "percentage" | "fixed";

  const couponTypeOptions = [
    {
      id: "percentage",
      label: "Percentage Discount",
    },
    {
      id: "fixed",
      label: "Fixed Amount Discount",
    },
  ];

  const durationOptions: EuiSelectOption[] = [
    { value: "once", text: "Once" },
    { value: "forever", text: "Forever" },
    { value: "repeating", text: "Multiple Months" },
  ];

  const {
    server,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    submitForm,
    isSubmitting,
    dispatch,
  } = props;

  const onNameChange = (value: string) => {
    setFieldValue("code", value);
    setFieldValue("coupon.name", value);
  };

  const onPercentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue("coupon.percentOff", e.target.value);
    setFieldValue("coupon.amountOff", undefined);
  };

  const onAmountOffChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue("coupon.amountOff", e.target.value);
    setFieldValue("coupon.percentOff", undefined);
  };

  const onCouponTypeChange = (id: CouponTypeId) => {
    setSelectedCouponType(id);
    setFieldValue("coupon.percentOff", "");
    setFieldValue("coupon.amountOff", "");
  };

  const onAppliesToChange = (e: EuiSwitchEvent) => {
    setAppliesTo(e.target.checked);

    if (e.target.checked) {
      setFieldValue("coupon.appliesTo", { products: [] });
    } else {
      setFieldValue("coupon.appliesTo", undefined);
    }
  };

  const onIsExpiresChange = (e: EuiSwitchEvent) => {
    setIsExpires(e.target.checked);
    if (e.target.checked) {
      const newTime = moment().utc().add(1, "M");
      setExpiresOn(newTime);
      setFieldValue("expiresAt", newTime.unix());
      setFieldValue("coupon.redeemBy", newTime.unix());
    } else {
      setFieldValue("expiresAt", undefined);
      setFieldValue("coupon.redeemBy", undefined);
    }
  };

  const onExpiresChange = (e: Moment | null) => {
    setExpiresOn(e);
    if (e) {
      setFieldValue("expiresAt", e.utc().unix());
      setFieldValue("coupon.redeemBy", e.utc().unix());
    }
  };

  const onIsLimitedChange = (e: EuiSwitchEvent) => {
    setIsMaxRedemptions(e.target.checked);
    setFieldValue("maxRedemptions", undefined);
    setFieldValue("coupon.maxRedemptions", undefined);
  };

  const onSelectProduct = (name: string, value: string, index: number) => {
    const products = values.coupon.appliesTo?.products || [];

    products[index] = value;

    setFieldValue("coupon.appliesTo", { products });
  };

  const onAppliesToAdd = () => {
    const products = values.coupon.appliesTo?.products || [];
    products.push("");
    setFieldValue("coupon.appliesTo", { products });
  };

  const onAppliesToDelete = (index: number) => {
    const products = values.coupon.appliesTo?.products || [];
    products.splice(index, 1);

    setFieldValue("coupon.appliesTo", { products });
  };

  const onMaxRedemptionsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue("maxRedemptions", e.target.value);
    setFieldValue("coupon.maxRedemptions", e.target.value);
  };

  const onIsFirstOrder = (e: EuiSwitchEvent) => {
    setIsFirstOrder(e.target.checked);
    setFieldValue("restrictions.firstTimeTransaction", e.target.checked);
  };

  const onDurationChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setFieldValue("coupon.duration", e.target.value);
    if (e.target.value !== "repeating") {
      setFieldValue("coupon.durationInMonths", undefined);
    }
  };

  const handleClose = () => {
    dispatch(setModal({ modal: "editCoupon", open: false, props: undefined }));
  };

  let modal;
  if (dialog.isOpen && arProducts.isSuccess) {
    modal = (
      <EuiModal
        onClose={handleClose}
        css={css`
          min-width: 750px;
        `}
      >
        <EuiModalHeader
          css={css`
            justify-content: center;
            width: 100%;
          `}
        >
          <EuiModalHeaderTitle
            css={css`
              width: 100%;
            `}
          >
            <EuiTitle>
              <h1>{dialog.props?.viewOnly ? "View" : "Create"} Coupon</h1>
            </EuiTitle>
            <EuiTitle size={"xxs"}>
              <h1>Coupons can not be modified once created.</h1>
            </EuiTitle>
          </EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiFlexGroup
            justifyContent={"center"}
            direction={"column"}
            css={css`
              max-width: 600px;
              margin-left: auto;
              margin-right: auto;
              margin-top: 10px;
            `}
          >
            <EuiFlexItem>
              <form onSubmit={handleSubmit} id={`edit-coupon-form`}>
                <EuiForm>
                  <EuiFlexGroup direction={"column"}>
                    <EuiFlexItem>
                      <EuiFlexGroup>
                        <EuiFlexItem grow={6}>
                          <EuiFormRow
                            fullWidth={false}
                            label={"Code"}
                            isInvalid={
                              (dialog.props?.viewOnly ||
                                isSubmitting ||
                                touched.code) &&
                              !!errors.code
                            }
                            error={errors.code}
                          >
                            <EuiFieldText
                              fullWidth={true}
                              name={"Code"}
                              value={values.code}
                              required={touched.code}
                              onChange={(e) => onNameChange(e.target.value)}
                              onBlur={handleBlur}
                              disabled={dialog.props?.viewOnly || isSubmitting}
                            />
                          </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem grow={6}>
                          <EuiFormRow
                            fullWidth={false}
                            hasEmptyLabelSpace={true}
                          >
                            <EuiLink
                              disabled={dialog.props?.viewOnly || isSubmitting}
                              onClick={() =>
                                onNameChange(
                                  voucher_codes.generate({
                                    length: 8,
                                    count: 1,
                                  })[0]
                                )
                              }
                            >
                              Generate
                            </EuiLink>
                          </EuiFormRow>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFlexGroup>
                        <EuiFlexItem>
                          <EuiFormRow fullWidth={true} label={"Type"}>
                            <EuiRadioGroup
                              disabled={dialog.props?.viewOnly || isSubmitting}
                              options={couponTypeOptions}
                              idSelected={selectedCouponType}
                              onChange={(id) =>
                                onCouponTypeChange(id as CouponTypeId)
                              }
                              name="Type"
                            />
                          </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem>
                          {selectedCouponType === "percentage" ? (
                            <EuiFormRow
                              fullWidth={true}
                              label={"Percentage Off"}
                              isInvalid={
                                (isSubmitting || touched.coupon?.percentOff) &&
                                !!errors.coupon?.percentOff
                              }
                              error={errors.coupon?.percentOff}
                            >
                              <EuiFieldText
                                fullWidth={false}
                                name={"percentOff"}
                                value={values.coupon?.percentOff}
                                required={touched.coupon?.percentOff}
                                type={"number"}
                                min={0}
                                max={100}
                                onChange={(e) => onPercentChange(e)}
                                onBlur={handleBlur}
                                disabled={
                                  dialog.props?.viewOnly || isSubmitting
                                }
                                append={"%"}
                              />
                            </EuiFormRow>
                          ) : (
                            <EuiFormRow
                              fullWidth={true}
                              label={"Fixed Amount Off"}
                              isInvalid={
                                (isSubmitting || touched.coupon?.amountOff) &&
                                !!errors.coupon?.amountOff
                              }
                              error={errors.coupon?.amountOff}
                            >
                              <EuiFieldText
                                fullWidth={false}
                                name={"amountOff"}
                                value={values.coupon?.amountOff}
                                required={touched.coupon?.amountOff}
                                onChange={(e) => onAmountOffChange(e)}
                                onBlur={handleBlur}
                                min={0}
                                type={"number"}
                                prepend={
                                  getSymbolFromCurrency(
                                    props.server.currency
                                  ) || "$"
                                }
                                append={props.server.currency.toUpperCase()}
                                disabled={
                                  dialog.props?.viewOnly || isSubmitting
                                }
                              />
                            </EuiFormRow>
                          )}
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFlexGroup>
                        <EuiFlexItem>
                          <EuiFormRow fullWidth={true}>
                            <EuiSwitch
                              disabled={dialog.props?.viewOnly || isSubmitting}
                              label={"Apply to Specific Products"}
                              checked={appliesTo}
                              onChange={(e) => onAppliesToChange(e)}
                            />
                          </EuiFormRow>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexItem>
                    {appliesTo ? (
                      <EuiFlexItem>
                        <EuiFlexGroup direction={"column"} gutterSize={"s"}>
                          {values.coupon.appliesTo &&
                          values.coupon.appliesTo.products ? (
                            values.coupon.appliesTo.products.length === 0 ? (
                              <EuiFlexItem>
                                <EuiFlexGroup>
                                  <EuiFlexItem>
                                    <SelectArProduct
                                      disabled={
                                        dialog.props?.viewOnly || isSubmitting
                                      }
                                      value={
                                        values.coupon.appliesTo!.products[0]
                                      }
                                      setFieldValue={(
                                        name: string,
                                        value: string
                                      ) => onSelectProduct(name, value, 0)}
                                      arProducts={arProducts.data}
                                    />
                                  </EuiFlexItem>
                                  <EuiFlexItem
                                    css={css`
                                      width: 24px;
                                    `}
                                    grow={false}
                                  />
                                </EuiFlexGroup>
                              </EuiFlexItem>
                            ) : (
                              values.coupon.appliesTo.products.map(
                                (product, index) => {
                                  return (
                                    <EuiFlexItem
                                      key={`select-applies-to-${index}`}
                                    >
                                      <EuiFlexGroup>
                                        <EuiFlexItem>
                                          <SelectArProduct
                                            value={
                                              values.coupon.appliesTo!.products[
                                                index
                                              ]
                                            }
                                            setFieldValue={(
                                              field: string,
                                              value: string
                                            ) =>
                                              onSelectProduct(
                                                field,
                                                value,
                                                index
                                              )
                                            }
                                            disabled={
                                              dialog.props?.viewOnly ||
                                              isSubmitting
                                            }
                                            arProducts={arProducts.data.filter(
                                              (item) =>
                                                !values.coupon.appliesTo?.products!.some(
                                                  (p) =>
                                                    p === item.productId &&
                                                    p !==
                                                      values.coupon.appliesTo!
                                                        .products[index]
                                                )
                                            )}
                                          />
                                        </EuiFlexItem>
                                        <EuiFlexItem
                                          grow={false}
                                          css={css`
                                            width: 24px;
                                            justify-content: center;
                                          `}
                                        >
                                          {values.coupon.appliesTo!.products
                                            .length > 1 ? (
                                            <EuiButtonIcon
                                              disabled={
                                                dialog.props?.viewOnly ||
                                                isSubmitting
                                              }
                                              color={"danger"}
                                              size={"xs"}
                                              onClick={() =>
                                                onAppliesToDelete(index)
                                              }
                                              iconType={"trash"}
                                            />
                                          ) : null}
                                        </EuiFlexItem>
                                      </EuiFlexGroup>
                                    </EuiFlexItem>
                                  );
                                }
                              )
                            )
                          ) : null}
                          <EuiFlexItem
                            css={css`
                              align-items: center;
                            `}
                          >
                            <EuiButtonEmpty
                              css={css`
                                margin-right: 24px;
                              `}
                              disabled={
                                dialog.props?.viewOnly ||
                                isSubmitting ||
                                (values.coupon.appliesTo &&
                                  !values.coupon.appliesTo.products[
                                    values.coupon.appliesTo.products.length - 1
                                  ])
                              }
                              onClick={() => onAppliesToAdd()}
                            >
                              Add Another Product
                            </EuiButtonEmpty>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFlexItem>
                    ) : null}
                    <EuiFlexItem>
                      <EuiFlexGroup>
                        <EuiFlexItem grow={6}>
                          <EuiFormRow
                            fullWidth={true}
                            label={"Number of Months"}
                            isInvalid={
                              (dialog.props?.viewOnly ||
                                isSubmitting ||
                                touched.coupon?.duration) &&
                              !!errors.coupon?.duration
                            }
                            error={errors.coupon?.duration}
                          >
                            <EuiSelect
                              value={values.coupon.duration}
                              options={durationOptions}
                              disabled={dialog.props?.viewOnly || isSubmitting}
                              onChange={onDurationChange}
                            />
                          </EuiFormRow>
                        </EuiFlexItem>
                        {values.coupon.duration === "repeating" ? (
                          <EuiFlexItem grow={6}>
                            <EuiFormRow
                              fullWidth={true}
                              label={"Duration"}
                              isInvalid={
                                (isSubmitting ||
                                  touched.coupon?.durationInMonths) &&
                                !!errors.coupon?.durationInMonths
                              }
                              error={errors.coupon?.durationInMonths}
                            >
                              <EuiFieldText
                                fullWidth={false}
                                name={"coupon.durationInMonths"}
                                value={values.coupon?.durationInMonths}
                                required={touched.coupon?.durationInMonths}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={
                                  dialog.props?.viewOnly || isSubmitting
                                }
                                min={1}
                                type={"number"}
                                append={
                                  values.coupon?.durationInMonths === 1
                                    ? "month"
                                    : "months"
                                }
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                        ) : (
                          <EuiFlexItem grow={6} />
                        )}
                      </EuiFlexGroup>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFlexGroup>
                        <EuiFlexItem>
                          <EuiFormRow fullWidth={true}>
                            <EuiSwitch
                              label={
                                "Limit the date range when customers can redeem this coupon"
                              }
                              checked={isExpires}
                              disabled={dialog.props?.viewOnly || isSubmitting}
                              onChange={(e) => onIsExpiresChange(e)}
                            />
                          </EuiFormRow>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexItem>
                    {isExpires ? (
                      <EuiFlexItem>
                        <EuiFlexGroup>
                          <EuiFlexItem grow={6}>
                            <EuiFormRow
                              fullWidth={true}
                              label={"Expires On (UTC)"}
                            >
                              <EuiDatePicker
                                showTimeSelect={true}
                                selected={expiresOn}
                                disabled={
                                  dialog.props?.viewOnly || isSubmitting
                                }
                                onChange={(e) => onExpiresChange(e)}
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                          <EuiFlexItem grow={6} />
                        </EuiFlexGroup>
                      </EuiFlexItem>
                    ) : null}
                    <EuiFlexItem>
                      <EuiFlexGroup>
                        <EuiFlexItem>
                          <EuiFormRow fullWidth={true}>
                            <EuiSwitch
                              label={
                                "Limit the total number of times this coupon can be redeemed"
                              }
                              checked={isMaxRedemptions}
                              disabled={dialog.props?.viewOnly || isSubmitting}
                              onChange={(e) => onIsLimitedChange(e)}
                            />
                          </EuiFormRow>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexItem>
                    {isMaxRedemptions ? (
                      <EuiFlexItem>
                        <EuiFlexGroup>
                          <EuiFlexItem grow={6}>
                            <EuiFormRow
                              fullWidth={false}
                              label={"Max Redemptions"}
                              isInvalid={
                                (isSubmitting ||
                                  touched.coupon?.maxRedemptions) &&
                                !!errors.coupon?.maxRedemptions
                              }
                              error={errors.coupon?.maxRedemptions}
                            >
                              <EuiFieldText
                                fullWidth={false}
                                name={"maxRedemptions"}
                                value={values.coupon?.maxRedemptions}
                                required={touched.coupon?.maxRedemptions}
                                onChange={(e) => onMaxRedemptionsChange(e)}
                                onBlur={handleBlur}
                                disabled={
                                  dialog.props?.viewOnly || isSubmitting
                                }
                                min={1}
                                type={"number"}
                                append={
                                  values.maxRedemptions === 1 ? "time" : "times"
                                }
                              />
                            </EuiFormRow>
                          </EuiFlexItem>
                          <EuiFlexItem grow={6} />
                        </EuiFlexGroup>
                      </EuiFlexItem>
                    ) : null}
                    <EuiFlexItem>
                      <EuiFlexGroup>
                        <EuiFlexItem>
                          <EuiFormRow
                            fullWidth={true}
                            label={""}
                            isInvalid={
                              (isSubmitting ||
                                touched.restrictions?.firstTimeTransaction) &&
                              !!errors.restrictions?.firstTimeTransaction
                            }
                            error={errors.restrictions?.firstTimeTransaction}
                          >
                            <EuiSwitch
                              label={"Eligible for first time order only"}
                              checked={isFirstOrder}
                              disabled={dialog.props?.viewOnly || isSubmitting}
                              onChange={(e) => onIsFirstOrder(e)}
                            />
                          </EuiFormRow>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  {/*<pre>*/}
                  {/*  <EuiCode language={"json"}>*/}
                  {/*    {JSON.stringify(values, undefined, 2)}*/}
                  {/*  </EuiCode>*/}
                  {/*</pre>*/}
                </EuiForm>
              </form>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiFlexGroup justifyContent={"spaceBetween"}>
            <EuiFlexItem grow={false}>
              {dialog.props?.viewOnly ? (
                <EuiButtonEmpty
                  color={"danger"}
                  disabled={Boolean(isCouponMutating)}
                  onClick={() => {
                    deleteCoupon.mutate(
                      {
                        serverId: server.id,
                        coupon: values,
                      },
                      {
                        onSettled: (data) => {
                          handleClose();
                        },
                      }
                    );
                  }}
                >
                  Delete
                </EuiButtonEmpty>
              ) : null}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup>
                <EuiFlexItem>
                  {dialog.props?.viewOnly ? null : (
                    <EuiButtonEmpty
                      color={"ghost"}
                      disabled={Boolean(isCouponMutating)}
                      onClick={handleClose}
                    >
                      Cancel
                    </EuiButtonEmpty>
                  )}
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiButton
                    color={"accent"}
                    isLoading={Boolean(isCouponMutating)}
                    // disabled={
                    //   values.code === "" &&
                    //   ((!("percentOff" in values.coupon) &&
                    //     !("amountOff" in values.coupon)) || // @ts-ignore
                    //     (values.coupon?.percentOff === "" &&
                    //       // @ts-ignore
                    //       !values.coupon?.amountOff === ""))
                    // }
                    onClick={() => {
                      dialog.props?.viewOnly ? handleClose() : handleSubmit();
                    }}
                  >
                    {dialog.props?.viewOnly ? "Close" : "Save"}
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  return <div>{modal}</div>;
};

const FormikForm = withFormik<OuterProps, FormValues>({
  mapPropsToValues: (props) => ({
    id: props.initialValues.id || "",
    active: true,
    code: props.initialValues.code || "",
    coupon: props.initialValues.coupon || {
      id: v4(),
      name: props.initialValues.code || "",
      currency: props.server.currency.toLowerCase(),
      amountOff: undefined,
      percentOff: undefined,
      duration: "forever",
      durationInMonths: undefined,
      appliesTo: undefined,
      maxRedemptions: undefined,
      redeemBy:
        moment.unix(props.initialValues.expiresAt).utc().unix() ||
        moment().utc().unix(),
    },
    customer: undefined,
    expiresAt:
      moment.unix(props.initialValues.expiresAt).utc().unix() ||
      moment().utc().unix(),
    maxRedemptions: props.initialValues.maxRedemptions || undefined,
    restrictions: props.initialValues.restrictions || {
      firstTimeTransaction: false,
      minimumAmount: undefined,
      minimumAmountCurrency: undefined,
    },
  }),

  enableReinitialize: true,
  validationSchema: Yup.object().shape({
    code: Yup.string().required("Code is required"),
    coupon: Yup.object().shape({
      percentOff: Yup.number()
        .min(0, "Percentage off must be greater than 0")
        .max(100, "Percentage off must be less than or equal to 100"),
      amountOff: Yup.number().min(0, "Amount off must be greater than 0"),
    }),
  }),

  handleSubmit(
    {
      id,
      active,
      code,
      coupon,
      customer,
      expiresAt,
      maxRedemptions,
      restrictions,
    }: FormValues,
    { props, setSubmitting, setErrors }
  ) {
    setSubmitting(true);
    const updatedCoupon: FormValues = {
      id,
      active,
      code,
      coupon,
      customer,
      expiresAt,
      maxRedemptions,
      restrictions,
    };

    props.updateCoupon.mutate(
      { serverId: props.server.id, coupon: updatedCoupon },
      {
        onSettled: (data: any) => {
          if (!updatedCoupon) {
            void props.queryClient.invalidateQueries(
              productKeys.coupons(props.server.id)
            );
          }
          setSubmitting(false);
          props.dispatch(
            setModal({ modal: "editCoupon", open: false, props: undefined })
          );
        },
      }
    );
  },
})(BaseForm);

export const ArEditCouponModal: FC<ArEditCouponModalProps> = ({ server }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const modal = useSelector(
    getModal("editCoupon")
  ) as AppSettingsState["modals"]["editCoupon"];

  const updateCoupon = useUpdateCoupon(server.id);

  if (modal.props) {
    return (
      <FormikForm
        queryClient={queryClient}
        server={server}
        initialValues={modal.props}
        updateCoupon={updateCoupon}
        dispatch={dispatch}
      />
    );
  }
  return null;
};
