import React from "react";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";

import {
  EuiButton,
  EuiCheckboxGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiText,
  EuiTextColor,
  EuiTitle,
} from "@elastic/eui";
import { css } from "@emotion/react";

import { ArIconDescription } from "../../arComponents/ArIconDescription";
import { ROUTE_BUILDER } from "../../components/app/app";

const WooChecks = [
  <EuiText>
    <EuiTextColor color={"accent"}>Customize</EuiTextColor> storefront through
    WordPress and WooCommerce
  </EuiText>,
  <EuiText>Reap the benefits of using both Wordpress and Autorole</EuiText>,
  <EuiText>
    Connect to WooCommerce using Autorole's Wordpress{" "}
    <EuiTextColor color={"accent"}>plugin</EuiTextColor>
  </EuiText>,
];

export const WooBenefitsPanel = () => {
  const [checkEligibility, setCheckEligibility] = useImmer<boolean>(false);

  if (checkEligibility) {
    return <CheckEligibility />;
  }

  return (
    <EuiPanel className={"ar-panel-light"}>
      <EuiFlexGroup
        css={css`
          height: 100%;
        `}
        direction={"column"}
        justifyContent={"spaceBetween"}
        responsive={false}
      >
        <EuiFlexItem>
          <EuiFlexGroup
            direction={"column"}
            alignItems={"center"}
            responsive={false}
          >
            <EuiFlexItem>
              <EuiTitle size={"m"}>
                <h1>Wordpress Storefront</h1>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText textAlign={"center"}>
                Use Autorole's WordPress plugin to connect your store
                <br />
                <i>Existing WooCommerce store required</i>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText textAlign={"center"}>
                4% per transaction - invoice emailed monthly
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup
                direction={"column"}
                gutterSize={"m"}
                responsive={false}
              >
                {WooChecks.map((check, index) => {
                  return (
                    <EuiFlexItem key={`woo-check-${index}`}>
                      <ArIconDescription
                        icon={"checkInCircleFilled"}
                        description={check}
                      />
                    </EuiFlexItem>
                  );
                })}
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem
          css={css`
            justify-content: end;
          `}
        >
          <EuiFlexGroup justifyContent={"center"}>
            <EuiFlexItem
              grow={false}
              css={css`
                justify-content: end;
                margin-bottom: -12px !important;
              `}
            >
              <EuiButton onClick={() => setCheckEligibility(true)}>
                Check Eligibility
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};

const options = [
  {
    id: "has-wordpress-site",
    label: "I already host a WordPress website",
  },
  {
    id: "has-woocommerce",
    label: "I already have an active WooCommerce subscription",
  },
  {
    id: "autorole-help",
    label:
      "I understand Autorole may need to help integrate my site (free of charge)",
  },
];

const CheckEligibility = () => {
  const [checkboxIdToSelectedMap, setCheckboxIdToSelectedMap] = useImmer(() => {
    const init = {};
    options.forEach((o) => {
      // @ts-ignore
      init[o.id] = false;
    });
    return init;
  });

  const navigate = useNavigate();
  const onChange = (optionId: string) => {
    const newCheckboxIdToSelectedMap = {
      ...checkboxIdToSelectedMap,
      ...{
        // @ts-ignore
        [optionId]: !checkboxIdToSelectedMap[optionId],
      },
    };
    setCheckboxIdToSelectedMap(newCheckboxIdToSelectedMap);
  };

  return (
    <EuiPanel className={"ar-panel-light"}>
      <EuiFlexGroup
        css={css`
          height: 100%;
        `}
        direction={"column"}
        justifyContent={"spaceBetween"}
        responsive={false}
      >
        <EuiFlexItem>
          <EuiFlexGroup
            direction={"column"}
            alignItems={"center"}
            responsive={false}
          >
            <EuiFlexItem>
              <EuiTitle size={"m"}>
                <h1>Wordpress Eligibility</h1>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText textAlign={"center"}>
                Let's make sure your site is compatible
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup
                direction={"column"}
                gutterSize={"m"}
                responsive={false}
              >
                <EuiFlexItem>
                  <EuiCheckboxGroup
                    options={options}
                    idToSelectedMap={checkboxIdToSelectedMap}
                    onChange={(id) => onChange(id)}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem
          css={css`
            justify-content: end;
          `}
        >
          <EuiFlexGroup justifyContent={"center"}>
            <EuiFlexItem
              grow={false}
              css={css`
                justify-content: end;
                margin-bottom: -12px !important;
              `}
            >
              <EuiButton
                disabled={Object.values(checkboxIdToSelectedMap).some(
                  (v) => !v
                )}
                onClick={() => navigate(ROUTE_BUILDER.SIGN_UP("woo"))}
              >
                Get Started
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};
