import React from "react";

import { EuiFlexGroup, EuiFlexItem, EuiTitle } from "@elastic/eui";

import { ServerState } from "../../../../@types/server";
import { ArPanel } from "../../../../arComponents/ArPanel";
import { WebhookEventsTable } from "../../../../components/table/WebhookEventsTable";

interface WebhookEventsPanelProps {
  server: ServerState;
}

export const WooWebhookEventsPanel: React.FC<WebhookEventsPanelProps> = ({
  server,
}) => {
  return (
    <ArPanel>
      <EuiFlexGroup direction={"column"}>
        <EuiFlexItem>
          <EuiTitle size={"s"}>
            <h2>Webhook Events</h2>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>
          <WebhookEventsTable server={server} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </ArPanel>
  );
};
