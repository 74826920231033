import { AxiosError } from "axios";

import {
  QueryObserverOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { ServerState } from "../../@types/server";
import {
  ServerOwnerState,
  UpdateServerOwnerRequest,
} from "../../@types/serverOwner";
import { addToast } from "../../components/toast";

import { api } from "./api";
import { IntegrationStatus } from "./servers";

export const serverOwnerKeys = {
  all: [{ scope: "server-owners" }] as const,
  one: (id: string) => ["server-owner", id] as const,
};

export const updateServerOwner = async (
  serverId: string,
  item: ServerOwnerState
) => {
  const resp = await api.patch<ServerOwnerState>(
    `/server-owners?serverId=${serverId}`,
    item
  );
  return resp.data;
};

export const useUpdateServerOwner = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error | AxiosError, UpdateServerOwnerRequest>(
    async (data: UpdateServerOwnerRequest) =>
      updateServerOwner(data.serverId, data.update),
    {
      onSuccess: (data: ServerOwnerState) => {
        queryClient.setQueryData(serverOwnerKeys.one(data.id), data);
        addToast({
          id: "update-server-owner-success",
          title: "Details Saved!",
          color: "success",
        });
      },
    }
  );
};

const FetchServerOwner = async (serverId: string) => {
  const resp = await api.get<ServerOwnerState>("/server-owners", {
    params: { serverId },
  });
  return resp.data;
};

export const useServerOwner = (
  server?: ServerState,
  options?: QueryObserverOptions<ServerOwnerState, Error>
) => {
  return useQuery<any, Error, ServerOwnerState>(
    serverOwnerKeys.one(server?.id || "tbd"),
    async () => FetchServerOwner(server!.id),
    {
      enabled:
        server !== undefined &&
        server.integrationStatus >= IntegrationStatus.OwnerInformationSaved,
      ...options,
    }
  );
};
