import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useImmer } from "use-immer";

import { LoadingOverlay } from "../../components/loading/LoadingOverlay";
import { SomethingWentWrong } from "../../components/loading/SomethingWentWrong";
import { useUser } from "../../features/api/auth";
import { useUpdateDiscordAccountServerLink } from "../../features/api/servers";
import { useCreatePurchase } from "../../features/api/stripe";

export const PurchaseRedirect: React.FC = () => {
  const [errors, setErrors] = useImmer<number>(0);
  const { serverId, productId } = useParams<{
    serverId: string;
    productId: string;
  }>();

  const user = useUser();
  const createPurchase = useCreatePurchase();
  const discordAccountLink = useUpdateDiscordAccountServerLink();

  useEffect(() => {
    if (user.isSuccess && errors <= 6) {
      discordAccountLink.mutate(
        { serverId },
        {
          onSuccess: (data) => {
            createPurchase.mutate(
              { serverId, productId, discordAccountId: data.discordAccountId },
              {
                onError: () => {
                  setErrors((draft) => {
                    return draft++;
                  });
                },
              }
            );
          },
        }
      );
    }
  }, []);

  if (errors > 6) {
    return <LoadingOverlay message={<SomethingWentWrong />} />;
  }

  return <LoadingOverlay />;
};
