import React, { useState } from "react";

import { EuiBadge, EuiBadgeGroup, EuiPopover } from "@elastic/eui";

import { MoreRolesPopoverButton } from "../button/MoreRolesPopoverButton";

interface MoreRolesPopoverProps {
  items: any[];
}

export const MoreRolesPopover: React.FC<MoreRolesPopoverProps> = ({
  items,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const onButtonClick = () =>
    setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);

  const closePopover = () => setIsPopoverOpen(false);

  return (
    <EuiPopover
      button={<MoreRolesPopoverButton onClick={onButtonClick} />}
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      style={{ paddingLeft: "2px", paddingRight: "2px" }}
    >
      <div style={{ maxWidth: 150 }}>
        <EuiBadgeGroup gutterSize="s">
          {items.map((o: any) => {
            return (
              <EuiBadge
                key={o.id}
                color={o.color === 0 ? "default" : `#${o.color.toString(16)}`}
              >
                {o.name}
              </EuiBadge>
            );
          })}
        </EuiBadgeGroup>
      </div>
    </EuiPopover>
  );
};
