import React from "react";

import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { ServerState } from "../../../@types/server";
import { ArMangeSubscriptionsPanel } from "../panels/autoroleStore/ArManageSubscriptionsPanel";

interface ManageArStoreSubPageProps {
  server: ServerState;
}

export const ManageArStoreSubPage: React.FC<ManageArStoreSubPageProps> = ({
  server,
}) => {
  return (
    <EuiFlexGroup gutterSize={"m"} direction={"column"}>
      <EuiFlexItem>
        <ArMangeSubscriptionsPanel server={server} />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
