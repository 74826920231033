import { sortBy } from "lodash";
import React, { cloneElement, ReactElement, useEffect, useState } from "react";
import { useImmer } from "use-immer";

import {
  EuiBadge,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  EuiSelectable,
  EuiSelectableOption,
} from "@elastic/eui";
import { css } from "@emotion/react";

import { DiscordGuildRole } from "../../@types/discord";

interface AddRemoveRolesPopoverProps {
  items: DiscordGuildRole[];
  children: ReactElement;
  onSave: (isAdd: boolean, roles: string[]) => void;
  isAdd: boolean;
}

export const AddRemoveRolesPopover: React.FC<AddRemoveRolesPopoverProps> = ({
  items,
  children,
  onSave,
  isAdd,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useImmer<boolean>(false);
  const [options, setOptions] = useState<EuiSelectableOption[]>([]);
  const onButtonClick = () =>
    setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);

  const closePopover = () => setIsPopoverOpen(false);

  const onSaveClick = () => {
    const roles: string[] = options
      .filter((option) => option.checked === "on")
      .map((option) => option.key as string);

    onSave(isAdd, roles);
    closePopover();
  };
  useEffect(() => {
    const _options: EuiSelectableOption[] = items.map((item) => {
      return {
        label: item.name,
        value: item.name,
        key: item.id,
        color: `#${item.color.toString(16)}`,
      };
    });
    setOptions(
      sortBy(
        _options.filter(
          (role: EuiSelectableOption) => !role.label.includes("@")
        ),
        "value"
      )
    );
  }, []);
  console.log(options);
  return (
    <EuiPopover
      button={cloneElement(children, { onClick: onButtonClick })}
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      css={css`
        padding-left: 2px;
        padding-right: 2px;
      `}
    >
      <EuiFlexGroup direction={"column"}>
        <EuiFlexItem>
          <EuiSelectable
            aria-label="Modify Roles"
            searchable
            css={css`
              min-width: 288px;
            `}
            renderOption={(option, searchValue) => {
              return (
                <EuiBadge
                  color={option.color === "#0" ? "default" : option.color}
                >
                  {option.label}
                </EuiBadge>
              );
            }}
            options={options}
            onChange={(newOptions) => setOptions(newOptions)}
          >
            {(list, search) => (
              <>
                {search}
                {list}
              </>
            )}
          </EuiSelectable>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton
            color={"accent"}
            onClick={() => onSaveClick()}
            disabled={
              !options
                ? true
                : !options.some((option) => option.checked === "on")
            }
            size={"s"}
            fullWidth={false}
          >
            Save
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPopover>
  );
};
