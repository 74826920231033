import { QueryObserverOptions, useQuery } from "@tanstack/react-query";

import { SubscriptionState } from "../../@types/subscription";
import { WooWebhook } from "../../@types/woo";

import { api } from "./api";

const webhookLogsForAdminsKeys = {
  all: (serverId: string) => [{ scope: "webhookLogs", serverId }] as const,
};

export const fetchWebhookLogsForAdmins = async (serverId: string) => {
  const resp = await api.get<SubscriptionState[]>("/admin/webhookLog", {
    params: { serverId },
  });
  return resp.data;
};

export const useWebhookLogsForAdmins = (
  serverId: string,
  options?: QueryObserverOptions<SubscriptionState[], Error>
) => {
  return useQuery<SubscriptionState[], Error>(
    webhookLogsForAdminsKeys.all(serverId),
    async () => fetchWebhookLogsForAdmins(serverId),
    options
  );
};

const enabledWebhooksForAdminsKeys = {
  all: (serverId: string) => [{ scope: "enabledWebhooks", serverId }] as const,
};

export const fetchEnabledWebhooksForAdmins = async (serverId: string) => {
  const resp = await api.get<any>("/admin/enabledWebhooks", {
    params: { serverId },
  });
  return resp.data;
};

export const useEnabledWebhooksForAdmins = (
  serverId: string,
  options?: QueryObserverOptions<WooWebhook[], Error>
) =>
  useQuery<WooWebhook[], Error>(
    enabledWebhooksForAdminsKeys.all(serverId),
    async () => fetchEnabledWebhooksForAdmins(serverId),
    options
  );
