import { FormikProps, withFormik } from "formik";
import { sortBy } from "lodash";
import React from "react";
import { Updater, useImmer } from "use-immer";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import {
  EuiButton,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiHorizontalRule,
  EuiSelect,
  EuiText,
} from "@elastic/eui";
import { css } from "@emotion/react";
import {
  QueryClient,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";

import { Country, State } from "../../../@types/csc";
import { ServerState } from "../../../@types/server";
import {
  ServerOwnerState,
  UpdateServerOwnerRequest,
} from "../../../@types/serverOwner";
import { UserState } from "../../../@types/user";
import { useCountries, useStates } from "../../../features/api/csc";
import {
  serverOwnerKeys,
  useUpdateServerOwner,
} from "../../../features/api/server-owners";
import { serverKeys } from "../../../features/api/servers";

interface FormValues extends ServerOwnerState {
  user: UserState;
  disabled: boolean;
}

interface OuterProps {
  queryClient: QueryClient;
  user: UserState;
  serverId: string;
  initialValues?: Partial<FormValues>;
  updateServerOwner: UseMutationResult<any, unknown, UpdateServerOwnerRequest>;
  disabled: boolean;
  country: string;
  setCountry: Updater<string>;
  countries: Country[];
  states: State[];
}

const BaseForm = (props: OuterProps & FormikProps<FormValues>) => {
  const {
    serverId,
    disabled,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    countries,
    country,
    states,
    setCountry,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <EuiForm>
        <EuiFlexGroup direction={"row"}>
          <EuiFlexItem>
            <EuiFlexGroup
              direction={"column"}
              css={css`
                max-width: 600px;
              `}
            >
              <EuiFlexItem>
                <EuiFlexGroup direction={"row"}>
                  <EuiFlexItem>
                    <EuiFormRow
                      label={"First Name"}
                      isInvalid={
                        (isSubmitting || touched.firstName) &&
                        !!errors.firstName
                      }
                      error={errors.firstName}
                    >
                      <EuiFieldText
                        disabled={disabled}
                        name={"firstName"}
                        value={values.firstName}
                        required={touched.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isLoading={isSubmitting}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow
                      label={"Last Name"}
                      isInvalid={
                        (isSubmitting || touched.lastName) && !!errors.lastName
                      }
                      error={errors.lastName}
                    >
                      <EuiFieldText
                        disabled={disabled}
                        name={"lastName"}
                        value={values.lastName}
                        required={touched.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isLoading={isSubmitting}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem grow={true}>
                <EuiFormRow
                  fullWidth={true}
                  label={"Email"}
                  isInvalid={(isSubmitting || touched.email) && !!errors.email}
                  error={errors.email}
                >
                  <EuiFieldText
                    disabled={disabled}
                    fullWidth={true}
                    name={"email"}
                    value={values.email}
                    required={touched.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isLoading={isSubmitting}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiHorizontalRule size={"half"} />
              <EuiFlexItem grow={true}>
                <EuiFormRow
                  fullWidth={true}
                  label={"Address 1"}
                  isInvalid={
                    (isSubmitting || touched.address1) && !!errors.address1
                  }
                  error={errors.address1}
                >
                  <EuiFieldText
                    disabled={disabled}
                    fullWidth={true}
                    name={"address1"}
                    value={values.address1}
                    required={touched.address1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isLoading={isSubmitting}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem grow={true}>
                <EuiFormRow
                  fullWidth={true}
                  label={"Address 2"}
                  isInvalid={
                    (isSubmitting || touched.address2) && !!errors.address2
                  }
                  error={errors.address2}
                >
                  <EuiFieldText
                    disabled={disabled}
                    fullWidth={true}
                    name={"address2"}
                    value={values.address2}
                    required={touched.address2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isLoading={isSubmitting}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem grow={true}>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFormRow
                      fullWidth={true}
                      label={"Country"}
                      isInvalid={
                        (isSubmitting || touched.country) && !!errors.country
                      }
                      error={errors.country}
                    >
                      <EuiSelect
                        options={sortBy(
                          countries.map((country) => {
                            return { value: country.iso2, text: country.name };
                          }),
                          (country) => country.value
                        )}
                        disabled={disabled}
                        fullWidth={true}
                        name={"country"}
                        value={values.country}
                        required={touched.country}
                        onChange={(e) => {
                          setCountry(e.target.value);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        isLoading={isSubmitting}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow
                      fullWidth={true}
                      label={"State"}
                      isInvalid={
                        (isSubmitting || touched.state) && !!errors.state
                      }
                      error={errors.state}
                    >
                      <EuiSelect
                        options={sortBy(
                          states.map((state) => {
                            return {
                              value: state.iso2,
                              text: state.name,
                            };
                          }),
                          (state) => state.text
                        )}
                        hasNoInitialSelection={true}
                        disabled={disabled}
                        fullWidth={true}
                        name={"state"}
                        value={values.state}
                        required={touched.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isLoading={isSubmitting}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem grow={true}>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFormRow
                      fullWidth={true}
                      label={"City"}
                      isInvalid={
                        (isSubmitting || touched.city) && !!errors.city
                      }
                      error={errors.city}
                    >
                      <EuiFieldText
                        disabled={disabled}
                        fullWidth={true}
                        name={"city"}
                        value={values.city}
                        required={touched.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isLoading={isSubmitting}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow
                      fullWidth={true}
                      label={"Zip Code"}
                      isInvalid={(isSubmitting || touched.zip) && !!errors.zip}
                      error={errors.zip}
                    >
                      <EuiFieldText
                        disabled={disabled}
                        fullWidth={true}
                        name={"zip"}
                        value={values.zip}
                        required={touched.zip}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isLoading={isSubmitting}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiHorizontalRule size={"half"} />
              <EuiFlexItem>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFormRow
                      fullWidth={true}
                      label={"Discord Username"}
                      isInvalid={
                        (isSubmitting || touched.user?.username) &&
                        !!errors.user?.username
                      }
                      error={errors.user?.username}
                    >
                      <EuiFieldText
                        disabled={true}
                        fullWidth={true}
                        name={"user.username"}
                        value={values.user.username}
                        required={touched.user?.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isLoading={isSubmitting}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiFormRow hasEmptyLabelSpace={true}>
                  <EuiButton
                    disabled={disabled}
                    color={"accent"}
                    type={"submit"}
                    isLoading={!errors || isSubmitting}
                    onClick={() => handleSubmit()}
                  >
                    Save
                  </EuiButton>
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
    </form>
  );
};

const FormikForm = withFormik<OuterProps, FormValues>({
  mapPropsToValues: (props) => ({
    id: props.initialValues?.id || "",
    firstName: props.initialValues?.firstName || "",
    lastName: props.initialValues?.lastName || "",
    email: props.initialValues?.email || "",
    user: props.user,
    address1: props.initialValues?.address1 || "",
    address2: props.initialValues?.address2 || "",
    city: props.initialValues?.city || "",
    state: props.initialValues?.state || "",
    zip: props.initialValues?.zip || "",
    country: props.initialValues?.country || props.country || "",
    discordAccountId: props.initialValues?.discordAccountId || "",
    disabled: false,
  }),

  validationSchema: Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email().required("Email is required"),
    address1: Yup.string().required("Address 1 is required"),
    address2: Yup.string(),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zip: Yup.string().required("Zip Code is required"),
    country: Yup.string().required("Country is required"),
  }),

  handleSubmit(formValues: FormValues, { props, setSubmitting, setErrors }) {
    setSubmitting(true);
    props.updateServerOwner.mutate(
      {
        serverId: props.serverId,
        update: {
          id: formValues.id || uuidv4(),
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          email: formValues.email,
          address1: formValues.address1,
          address2: formValues.address2,
          city: formValues.city,
          state: formValues.state,
          zip: formValues.zip,
          country: formValues.country,
          discordAccountId: formValues.user.id,
        },
      },
      {
        onSuccess: (data) => {
          void props.queryClient.invalidateQueries(serverOwnerKeys.all);
          void props.queryClient.invalidateQueries(serverKeys.all);
          void props.queryClient.invalidateQueries(["server"]);
          void props.queryClient.invalidateQueries(["server-owner"]);
        },
        onSettled: (data) => {
          setSubmitting(false);
        },
      }
    );
  },
})(BaseForm);

interface ServerOwnerFormProps {
  server?: ServerState;
  user: UserState;
  serverOwner?: ServerOwnerState;
  disabled: boolean;
}

export const ServerOwnerForm: React.FC<ServerOwnerFormProps> = ({
  user,
  disabled,
  server,
  serverOwner,
}) => {
  const [country, setCountry] = useImmer("US");
  const queryClient = useQueryClient;
  const updateServerOwner = useUpdateServerOwner();

  const countries = useCountries({
    placeholderData: [{ id: 1, iso2: "US", name: "United States" }],
  });
  const states = useStates(country, {
    placeholderData: [{ id: 1, iso2: "", name: "" }],
  });

  if (user && server) {
    return (
      <FormikForm
        queryClient={queryClient()}
        serverId={server.id}
        updateServerOwner={updateServerOwner}
        disabled={disabled}
        user={user}
        initialValues={serverOwner}
        country={country}
        setCountry={setCountry}
        countries={countries.data!}
        states={states.data!}
      />
    );
  }
  return (
    <EuiText color={"subdued"}>
      Choose a server server to see this step.
    </EuiText>
  );
};
