import React from "react";

import {useLogout} from "../../features/api/auth";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LoginBaseRouteProps {}
export const LogoutRedirect: React.FC<LoginBaseRouteProps> = () => {
  const logout = useLogout();
  logout.mutate();
  return null;
};
