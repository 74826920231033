import React from "react";

import { EuiFlexGroup, EuiFlexItem, EuiTitle } from "@elastic/eui";

import { ServerState } from "../../../../@types/server";
import { ArPanel } from "../../../../arComponents/ArPanel";
import { EnabledWebhooksTable } from "../../../../components/table/EnabledWebhooksTable";

interface EnabledWebhooksPanelProps {
  server: ServerState;
}

export const WooEnabledWebhooksPanel: React.FC<EnabledWebhooksPanelProps> = ({
  server,
}) => {
  return (
    <ArPanel>
      <EuiFlexGroup direction={"column"}>
        <EuiFlexItem>
          <EuiTitle size={"s"}>
            <h2>Enabled Webhooks</h2>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>
          <EnabledWebhooksTable server={server} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </ArPanel>
  );
};
