import React from "react";

import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { DataTimePeriod } from "../../@types/data";
import { ServerState } from "../../@types/server";
import { useFetchOrderByProductOverTime } from "../../features/api/data";

import { OverTimeLineChart } from "./OverTimeLineChart";

interface TotalOrderCountOverTimeProps {
  server: ServerState;
  timePeriod: DataTimePeriod;
}

export const TotalOrderCountByProductOverTime: React.FC<
  TotalOrderCountOverTimeProps
> = ({ server, timePeriod }) => {
  const orderByProductOverTime = useFetchOrderByProductOverTime(
    server.id,
    timePeriod
  );

  return (
    <EuiFlexGroup direction={"column"}>
      <EuiFlexItem>
        <OverTimeLineChart
          title={"Orders By Product"}
          data={orderByProductOverTime.data}
          isLoading={orderByProductOverTime.isLoading}
          xAccessor={"formattedInterval"}
          yAccessors={["orderCount"]}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
