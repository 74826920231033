import React, { Dispatch, SetStateAction } from "react";

import { EuiSelect } from "@elastic/eui";

import { DataTimePeriod, TimeSelectorOption } from "../../@types/data";

export const TIME_SELECTOR_OPTIONS: TimeSelectorOption[] = [
  { value: "TODAY", text: "Today" },
  { value: "YESTERDAY", text: "Yesterday" },
  { value: "WTD", text: "Week to date" },
  { value: "LAST_WEEK", text: "Last week" },
  { value: "MTD", text: "Month to date" },
  { value: "LAST_MONTH", text: "Last month" },
  {
    value: "YTD",
    text: "Year to date",
  },
  { value: "LAST_YEAR", text: "Last year" },
];

interface TimeSelectorProps {
  timePeriod: string;
  setTimePeriod: Dispatch<SetStateAction<DataTimePeriod>>;
}

export const TimeSelector: React.FC<TimeSelectorProps> = ({
  timePeriod,
  setTimePeriod,
}) => {
  return (
    <EuiSelect
      compressed={true}
      options={TIME_SELECTOR_OPTIONS}
      value={timePeriod}
      onChange={(e) => setTimePeriod(e.target.value as DataTimePeriod)}
    />
  );
};
