import { useNavigate } from "react-router-dom";

import {
  EuiHeader,
  EuiHeaderLink,
  EuiHeaderLinks,
  EuiHeaderSection,
  EuiHeaderSectionItem,
} from "@elastic/eui";

import { useServerByAlias } from "../../features/api/servers";
import { ROUTES } from "../app/app";

export const ManagerServerHeader = () => {
  const alias = window.location.href.split("/").pop();
  const navigate = useNavigate();

  const server = useServerByAlias(alias!);

  return (
    <EuiHeader className={"ar-panel ar-header"}>
      <EuiHeaderSection grow={false}>
        <EuiHeaderSectionItem>
          <EuiHeaderLinks>
            <EuiHeaderLink
              isActive={window.location.href.includes("account")}
              onClick={() =>
                navigate(`${ROUTES.SERVER_MANAGE}/account/${alias}`)
              }
            >
              Server Settings
            </EuiHeaderLink>
            {server.isSuccess && server.data.siteType === "autorole" ? (
              <EuiHeaderLink
                isActive={window.location.href.includes("store")}
                onClick={() =>
                  navigate(`${ROUTES.SERVER_MANAGE}/store/${alias}`)
                }
              >
                Manage Store
              </EuiHeaderLink>
            ) : null}
            {server.isSuccess && server.data.siteType === "woo" ? (
              <EuiHeaderLink
                isActive={window.location.href.includes("roles")}
                onClick={() =>
                  navigate(`${ROUTES.SERVER_MANAGE}/roles/${alias}`)
                }
              >
                Manage Roles
              </EuiHeaderLink>
            ) : null}
            <EuiHeaderLink
              isActive={window.location.href.includes("members")}
              onClick={() =>
                navigate(`${ROUTES.SERVER_MANAGE}/members/${alias}`)
              }
            >
              Manage Members
            </EuiHeaderLink>
            <EuiHeaderLink
              isActive={window.location.href.includes("performance")}
              onClick={() =>
                navigate(`${ROUTES.SERVER_MANAGE}/performance/${alias}`)
              }
            >
              Performance
            </EuiHeaderLink>
          </EuiHeaderLinks>
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </EuiHeader>
  );
};
