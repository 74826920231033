import { orderBy } from "lodash";
import React, { FC } from "react";

import {
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingContent,
} from "@elastic/eui";

import { ProductCard } from "../../../components/card/ProductCard";
import { ProductDescriptionModal } from "../../../components/modal/ProductDescriptionModal";
import { useProducts } from "../../../features/api/products";
import {
  useCurrencyFormatter,
  useServerFromUrl,
} from "../../../features/hooks/hooks";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StoreCollectionPanelProps {}

export const CollectionPanel: FC<StoreCollectionPanelProps> = () => {
  const server = useServerFromUrl();
  const products = useProducts(server.data?.id);

  const currencyFormat = useCurrencyFormatter(server.data?.id);

  if (products.isSuccess && server.isSuccess && currencyFormat) {
    return (
      <EuiFlexGrid columns={4}>
        {orderBy(products.data, (_) => _.name).map((product, index) => {
          return (
            <EuiFlexItem key={product.id}>
              <ProductCard
                product={product}
                serverId={server.data.id}
                currencyFormat={currencyFormat}
              />
            </EuiFlexItem>
          );
        })}
        <ProductDescriptionModal server={server.data} />
        {/*<PurchaseModal server={server.data} />*/}
      </EuiFlexGrid>
    );
  }

  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        <EuiLoadingContent lines={6} />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
