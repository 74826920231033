import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { EuiCallOut, EuiProvider } from "@elastic/eui";
import { Global } from "@emotion/react";

import { useUser } from "../../features/api/auth";
import { ErrorPage } from "../../pages/errorPage";
import { LoggedInDashboard } from "../../pages/loggedInDashboard";
import { LoginBaseRoute } from "../../pages/login/login.baseRoute";
import { NotFound } from "../../pages/login/NotFound";
import { ManageServerRoot } from "../../pages/manage/ManageServerRoot";
import { BillingPortalRedirect } from "../../pages/redirects/BillingPortalRedirect";
import { LogoutRedirect } from "../../pages/redirects/LogoutRedirect";
import { PurchaseRedirect } from "../../pages/redirects/PurchaseRedirect";
import {
  StripeRefresh,
  StripeReturn,
} from "../../pages/redirects/StripeRedirect";
import { WooLinkRedirect } from "../../pages/redirects/WooLinkRedirect";
import { ServerRoot } from "../../pages/server/ServerRoot";
import { ChooseStoreType } from "../../pages/signup/ChooseStoreType";
import { SignupRoot } from "../../pages/signup/SignupRoot";
import { StoreRoot } from "../../pages/store/StoreRoot";
import { LoadingOverlay } from "../loading/LoadingOverlay";
import { GlobalToast } from "../toast";

import { Theme } from "./theme";

export const BACKEND_ROUTES = {
  STRIPE_RETURN: `https://${process.env.REACT_APP_API_URL}/stripe-auth/return`,
  STRIPE_REFRESH: `https://${process.env.REACT_APP_API_URL}/stripe-auth/refresh`,
};

export const ROUTES = {
  INDEX: "/",
  SERVER: "server",
  SERVER_MANAGE: "/manage",
  LINK: "link",
  LOGOUT: "/logout",
  LOGIN: "/login",
  AUTO_LOGIN: "/log-in",
  SIGN_UP: "/signup",
  SIGN_UP_ROOT: () => `${ROUTES.SIGN_UP}/:type`,
  STORE: "/store",
  STRIPE_RETURN: "/stripe-auth/return/:serverId",
  STRIPE_REFRESH: "/stripe-auth/refresh/:serverId",
  STRIPE_PURCHASE: () => `${ROUTES.STORE}/purchase/:serverId/:productId`,
  STRIPE_BILLING_PORTAL: () => `/billing/:serverId/:subscriptionId`,
} as const;

export const ROUTE_BUILDER = {
  STRIPE_RETURN: (serverId: string) =>
    ROUTES.STRIPE_RETURN.replace(":serverId", serverId),
  STRIPE_PURCHASE: (serverId: string, productId: string) =>
    ROUTES.STRIPE_PURCHASE()
      .replace(":serverId", serverId)
      .replace(":productId", productId),
  SIGN_UP: (type: "woo" | "autorole") =>
    ROUTES.SIGN_UP_ROOT().replace(":type", type),
  STRIPE_BILLING_PORTAL: (serverId: string, productId: string) =>
    ROUTES.STRIPE_PURCHASE()
      .replace(":serverId", serverId)
      .replace(":subscriptionId", productId),
};

export function Error({ message }: { message: string }) {
  return (
    <EuiCallOut
      title="Sorry, there was an error"
      color="danger"
      iconType="alert"
    >
      <p>{message}</p>
    </EuiCallOut>
  );
}

function FallbackComponent() {
  return <ErrorPage />;
}

const myFallback = () => <FallbackComponent />;

export const App = () => {
  const user = useUser();

  if (user.isLoading && user.dataUpdatedAt > 0) {
    console.log(JSON.stringify(user));
    return <LoadingOverlay />;
  }

  return (
    // <Sentry.ErrorBoundary
    //   fallback={myFallback}
    //   showDialog={false}
    //   dialogOptions={{
    //     user: {
    //       email: user?.email || undefined,
    //       name: user?.username || undefined,
    //     },
    //   }}
    // >
    <div>
      <EuiProvider colorMode={"dark"} modify={Theme}>
        <Global
          styles={{
            "*:focus:focus-visible": {
              outlineStyle: "none",
            },
          }}
        />
        <BrowserRouter>
          <Routes>
            <Route
              index
              element={
                user.isSuccess && user.data.token ? (
                  <LoggedInDashboard />
                ) : (
                  <LoginBaseRoute />
                )
              }
            />
            <Route
              path={ROUTES.STRIPE_PURCHASE()}
              element={
                user.isSuccess && user.data.token ? (
                  <PurchaseRedirect />
                ) : (
                  <LoginBaseRoute
                    message={"Login to Complete Purchase"}
                    path={true}
                  />
                )
              }
            />
            <Route
              path={ROUTES.STRIPE_BILLING_PORTAL()}
              element={
                user.isSuccess && user.data.token ? (
                  <BillingPortalRedirect />
                ) : (
                  <LoginBaseRoute
                    message={"Login to Edit Subscription"}
                    path={true}
                  />
                )
              }
            />
            <Route
              path={`${ROUTES.STORE}/:alias/collections`}
              element={<StoreRoot />}
            />
            <Route
              path={`${ROUTES.SERVER}/:alias/*`}
              element={<ServerRoot />}
            />
            <Route
              path={`${ROUTES.LINK}/:alias/*`}
              element={<WooLinkRedirect />}
            />
            <Route
              path={`${ROUTES.SERVER_MANAGE}/account/:alias/*`}
              element={<ManageServerRoot subPath={"account"} />}
            />
            <Route
              path={`${ROUTES.SERVER_MANAGE}/roles/:alias/*`}
              element={<ManageServerRoot subPath={"roles"} />}
            />
            <Route
              path={`${ROUTES.SERVER_MANAGE}/performance/:alias/*`}
              element={<ManageServerRoot subPath={"performance"} />}
            />
            <Route
              path={`${ROUTES.SERVER_MANAGE}/store/:alias/*`}
              element={<ManageServerRoot subPath={"store"} />}
            />
            <Route
              path={`${ROUTES.SERVER_MANAGE}/members/:alias/*`}
              element={<ManageServerRoot subPath={"members"} />}
            />
            <Route path={`${ROUTES.LOGOUT}/*`} element={<LogoutRedirect />} />
            <Route path={`${ROUTES.LOGIN}/*`} element={<LoginBaseRoute />} />
            <Route path={`${ROUTES.SIGN_UP}`} element={<ChooseStoreType />} />
            <Route path={`${ROUTES.SIGN_UP_ROOT()}`} element={<SignupRoot />} />
            <Route
              path={`${ROUTES.STRIPE_RETURN}`}
              element={<StripeReturn />}
            ></Route>
            <Route
              path={`/${ROUTES.STRIPE_REFRESH}`}
              element={<StripeRefresh />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <GlobalToast />
        </BrowserRouter>
      </EuiProvider>
    </div>

    // </Sentry.ErrorBoundary>
  );
};
