import React from "react";
import { useImmer } from "use-immer";

import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
} from "@elastic/eui";
import { css } from "@emotion/react";
import { useQueryClient } from "@tanstack/react-query";

import { DiscordGuildRole } from "../../../../@types/discord";
import { ServerState } from "../../../../@types/server";
import {
  ProductServerRoleLink,
  UpdateProductServerRolesRequest,
} from "../../../../@types/serverRole";
import { WooProduct } from "../../../../@types/woo";
import { SelectGuildRole } from "../../../../components/select/SelectGuildRole";
import { SelectWooProduct } from "../../../../components/select/SelectWooProduct";
import { addToast } from "../../../../components/toast";
import { useUpsertProductServerRoleLink } from "../../../../features/api/server-roles";

interface LinkRoleRowProps {
  server: ServerState;
  showLabel?: boolean;
  isNew?: boolean;
  productServerRoleLink: ProductServerRoleLink;
  onDelete: () => void;
  guildRoles: DiscordGuildRole[];
  wooProducts: WooProduct[];
}

export const WooLinkRoleRow: React.FC<LinkRoleRowProps> = ({
  server,
  showLabel = false,
  productServerRoleLink,
  onDelete,
  isNew,
  guildRoles,
  wooProducts,
}) => {
  const queryClient = useQueryClient();
  const mutateServerRole = useUpsertProductServerRoleLink();

  const oldProductId = productServerRoleLink.product.id;
  const oldServerRoleId = productServerRoleLink.serverRole!.id;

  const [touched, setTouched] = useImmer<boolean>(false);
  const [newProductId, setNewProductId] = useImmer<string>(
    productServerRoleLink.product.productId
  );
  const [newProductName, setNewProductName] = useImmer<string>(
    productServerRoleLink.product.name
  );

  const [newDiscordRoleId, setNewDiscordRoleId] = useImmer<string>(
    productServerRoleLink.serverRole!.discordRoleId
  );
  const [newDiscordRoleName, setNewDiscordRoleName] = useImmer<string>(
    productServerRoleLink.serverRole!.discordRoleName
  );

  const onRoleChange = (name: string, value: string) => {
    setNewDiscordRoleName(name);
    setNewDiscordRoleId(value);
    setTouched(true);
  };

  const onProductChange = (name: string, value: string) => {
    setNewProductName(name);
    setNewProductId(value);
    setTouched(true);
  };

  const onSave = () => {
    if (newProductId === "") {
      addToast({
        id: "product-id-empty",
        color: "warning",
        title: "Product ID cannot be empty",
      });
      return;
    }

    if (newDiscordRoleId === "") {
      addToast({
        id: "role-id-empty",
        color: "warning",
        title: "Role cannot be empty",
      });
      return;
    }

    const args: UpdateProductServerRolesRequest = {
      serverId: server.id,
      oldProductId,
      oldServerRoleId,
      newProductId,
      newProductName,
      newDiscordRoleId,
      newDiscordRoleName,
    };

    mutateServerRole.mutate(args, {
      onSuccess: (data) => {
        void queryClient.invalidateQueries(["serverRoleLinks"]);
        setTouched(false);
        addToast({
          id: "update-role-success",
          color: "success",
          title: `Role Updated!`,
        });
      },
    });
  };

  return (
    <EuiFlexGroup alignItems={"center"} responsive={false} gutterSize={"s"}>
      <EuiFlexItem grow={1} />
      <EuiFlexItem
        grow={4}
        css={css`
          max-width: 400px;
        `}
      >
        <EuiFormRow
          label={showLabel ? "Product" : undefined}
          hasEmptyLabelSpace={!showLabel}
        >
          <SelectWooProduct
            disabled={mutateServerRole.isLoading}
            value={newProductId}
            setFieldValue={onProductChange}
            wooProducts={wooProducts}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFormRow hasEmptyLabelSpace={true}>
          <EuiIcon type={"arrowRight"} />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem
        grow={4}
        css={css`
          max-width: 400px;
        `}
      >
        <SelectGuildRole
          disabled={mutateServerRole.isLoading}
          guildRoles={guildRoles}
          value={newDiscordRoleId}
          setFieldValue={onRoleChange}
          showLabel={showLabel}
          showHelpText={showLabel}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFormRow hasEmptyLabelSpace={true}>
          <EuiButtonIcon
            isLoading={mutateServerRole.isLoading}
            color={"danger"}
            size={"xs"}
            onClick={onDelete}
            iconType={"trash"}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        {touched && newProductId && newDiscordRoleId ? (
          <EuiFormRow hasEmptyLabelSpace={true}>
            <EuiButtonIcon
              color={"accent"}
              size={"xs"}
              onClick={() => onSave()}
              isLoading={mutateServerRole.isLoading}
              iconType={"save"}
            />
          </EuiFormRow>
        ) : (
          <div style={{ width: 24 }} />
        )}
      </EuiFlexItem>
      <EuiFlexItem grow={1} />
    </EuiFlexGroup>
  );
};
