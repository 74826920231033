import React from "react";

import { EuiFlexGroup, EuiFlexItem, EuiTitle } from "@elastic/eui";

import { ServerState } from "../../../../@types/server";
import { ArPanel } from "../../../../arComponents/ArPanel";
import { ServerMemberTable } from "../../../../components/table/ServerMemberTable";

interface ManagerServerMembersPanelProps {
  server: ServerState;
}
export const ManageMembersPanel: React.FC<ManagerServerMembersPanelProps> = ({
  server,
}) => {
  return (
    <ArPanel>
      <EuiFlexGroup direction={"column"}>
        <EuiFlexItem>
          <EuiTitle size={"s"}>
            <h2>Member Information</h2>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>
          <ServerMemberTable server={server} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </ArPanel>
  );
};
