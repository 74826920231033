import React, { FC, useEffect } from "react";
import { useImmer } from "use-immer";

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiSteps,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import { EuiContainedStepProps } from "@elastic/eui/src/components/steps/steps";
import { useQueryClient } from "@tanstack/react-query";

import { ArPage } from "../../arComponents/ArPage";
import { ArPanel } from "../../arComponents/ArPanel";
import { Header } from "../../components/header/Header";
import { useDiscordBotLogin } from "../../features/api/auth";
import { IntegrationStatus } from "../../features/api/servers";
import { useCreateAccount } from "../../features/api/stripe";
import { useServerIdFromUrl } from "../../features/hooks/hooks";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SignupBaseRouteProps {}

export const ArSignupRoot: FC<SignupBaseRouteProps> = () => {
  const server = useServerIdFromUrl();
  const botAuth = useDiscordBotLogin();
  const createAccount = useCreateAccount();
  const [isLoading, setIsLoading] = useImmer<boolean>(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    setTimeout(() => {
      void queryClient.invalidateQueries(["server"]);
    }, 2000);
  }, []);

  const steps: EuiContainedStepProps[] = [
    {
      title: "Add Autorole to Your Server",
      children: (
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            {!server.data ||
            (server.data &&
              server.data.integrationStatus <
                IntegrationStatus.ServerSelected) ? (
              <EuiButton
                isLoading={botAuth.isLoading}
                onClick={() => botAuth.mutate({ path: window.location.href })}
              >
                Choose Server
              </EuiButton>
            ) : server.data.companyName ? (
              <EuiText color={"success"}>
                Connected to <b>{server.data.companyName}!</b>
              </EuiText>
            ) : null}
          </EuiFlexItem>
        </EuiFlexGroup>
      ),
    },
    {
      title: "Connect Your Store",
      children: (
        <EuiFlexGroup direction={"column"}>
          <EuiFlexItem grow={false}>
            <EuiText
              color={
                !server.data ||
                (server.data &&
                  server.data.integrationStatus <
                    IntegrationStatus.OwnerInformationSaved)
                  ? "subdued"
                  : undefined
              }
            >
              Autorole charges a <strong>4%</strong> fee on all purchases. By
              continuing you agree to the{" "}
              <EuiLink
                href={"https://autorole.io/terms-and-conditions"}
                target={"_blank"}
              >
                terms and conditions
              </EuiLink>{" "}
              and{" "}
              <EuiLink href={"https://autorole.io/privacy"} target={"_blank"}>
                privacy
              </EuiLink>{" "}
              policies.
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                {server.data && server.data.id ? (
                  <EuiButton
                    isLoading={isLoading}
                    onClick={() => {
                      setIsLoading(true);
                      createAccount.mutate(server.data.id);
                    }}
                  >
                    Connect Store
                  </EuiButton>
                ) : (
                  <EuiButton disabled={true}>Connect Store</EuiButton>
                )}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      ),
    },
  ];

  return (
    <ArPage
      isLoading={false}
      header={<Header />}
      body={
        <EuiFlexGroup justifyContent={"flexStart"} direction={"column"}>
          <EuiFlexItem>
            <ArPanel>
              <EuiFlexGroup direction={"column"}>
                <EuiFlexItem>
                  <EuiTitle size={"m"}>
                    <h2>Add Your Server</h2>
                  </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiSteps headingElement="h2" steps={steps} />
                </EuiFlexItem>
              </EuiFlexGroup>
            </ArPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      }
      restrictWidth={true}
    />
  );
};
