import React from "react";

import { EuiPanel } from "@elastic/eui";
import { EuiPanelProps } from "@elastic/eui/src/components/panel/panel";

// eslint-disable-next-line @typescript-eslint/ban-types
type ArPanelProps = EuiPanelProps & {};

export const ArPanel: React.FC<ArPanelProps> = (props) => {
  return <EuiPanel className={"ar-panel"} {...props}></EuiPanel>;
};
