import React, { FC } from "react";
import { useSelector } from "react-redux";

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiMarkdownFormat,
  EuiText,
} from "@elastic/eui";
import { css } from "@emotion/react";

import { AppSettingsState } from "../../@types/appSettings";
import { ServerState } from "../../@types/server";
import { ArModal } from "../../arComponents/ArModal";
import { ProductPrice } from "../../features/formatters/storeProduct";
import { useCurrencyFormatter } from "../../features/hooks/hooks";
import { getModal } from "../../features/redux/appState";

interface ProductDescriptionModalProps {
  server: ServerState;
}
export const ProductDescriptionModal: FC<ProductDescriptionModalProps> = ({
  server,
}) => {
  const dialog = useSelector(
    getModal("productDescription")
  ) as AppSettingsState["modals"]["productDescription"];

  const currencyFormat = useCurrencyFormatter(server.id);

  if (dialog.props && currencyFormat) {
    return (
      <ArModal
        yesText={"Close"}
        modalKey={"productDescription"}
        minWidth={"750px"}
        title={
          <EuiFlexGroup
            direction={"column"}
            gutterSize={"xs"}
            alignItems={"center"}
          >
            <EuiFlexItem>{dialog.props.name}</EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexItem
                css={css`
                  text-align: center;
                `}
              >
                <ProductPrice
                  formatter={currencyFormat}
                  product={dialog.props}
                />
              </EuiFlexItem>
            </EuiFlexItem>
            <EuiHorizontalRule size={"full"} margin={"s"} />
          </EuiFlexGroup>
        }
        body={
          <EuiFlexGroup
            direction={"column"}
            css={css`
              height: 100%;
            `}
          >
            <EuiFlexItem>
              <EuiText>
                <EuiMarkdownFormat>
                  {dialog.props.description}
                </EuiMarkdownFormat>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        }
      />
    );
  }

  return null;
};
