import axios, { AxiosError } from "axios";

import {
  QueryObserverOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { ArCouponState, ArPromotionCodeResponse } from "../../@types/coupon";
import { ArProductState } from "../../@types/product";
import { addToast } from "../../components/toast";

import { api } from "./api";

export const productKeys = {
  all: (serverId: string, archivedOnly: boolean) => [
    { scope: "products", serverId, archivedOnly },
  ],
  server: (serverId: string, archivedOnly: boolean, productId: string) => [
    { ...productKeys.all(serverId, archivedOnly)[0], productId },
  ],
  coupons: (serverId: string) => [{ scope: "coupons", serverId }],
} as const;

export const fetchProducts = async (serverId: string, archivedOnly = false) => {
  const resp = await api.get<ArProductState[]>("/products", {
    params: { serverId, archivedOnly },
  });
  return resp.data;
};

export const useProducts = (
  serverId?: string,
  archivedOnly = false,
  options?: QueryObserverOptions<ArProductState[], Error>
) => {
  return useQuery<ArProductState[], Error, ArProductState[]>(
    productKeys.all(serverId!, archivedOnly),
    async () => fetchProducts(serverId!, archivedOnly),
    {
      enabled: Boolean(serverId),
      onError: (error) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 404) {
            addToast({
              id: "fetch-products-404",
              color: "danger",
              title: "No Products Found",
            });
            return;
          }
          return;
        } else {
          throw error;
        }
      },
      ...options,
    }
  );
};

export const updateProduct = async (
  serverId: string,
  product: ArProductState
) => {
  const resp = await api.post<ArProductState>("/products", {
    serverId,
    product,
  });
  return resp.data;
};

export const useUpdateProduct = (serverId: string) => {
  const queryClient = useQueryClient();
  return useMutation<ArProductState, Error | AxiosError, any>(
    ["mutateProduct"],
    async (payload: { serverId: string; item: ArProductState }) =>
      updateProduct(payload.serverId, payload.item),
    {
      onSuccess: (updatedProduct: ArProductState) => {
        void queryClient.invalidateQueries(productKeys.all(serverId, false));
        // queryClient.setQueryData(productKeys.all(serverId, false), (draft) => {
        //   return (draft as ArProductState[]).map((oldProduct) =>
        //     oldProduct.productId === updatedProduct.productId
        //       ? updatedProduct
        //       : oldProduct
        //   );
        // });
        addToast({
          id: "update-product-success",
          title: "Product Updated",
          color: "success",
        });
      },
    }
  );
};

export const archiveProduct = async (
  serverId: string,
  item: ArProductState
) => {
  const resp = await api.delete<ArProductState>("/products", {
    data: {
      serverId,
      product: item,
    },
  });
  return resp.data;
};

export const useArchiveProduct = (serverId: string) => {
  const queryClient = useQueryClient();
  return useMutation<any, Error | AxiosError, any>(
    ["mutateProduct"],
    async (payload: { serverId: string; item: ArProductState }) =>
      archiveProduct(payload.serverId, payload.item),
    {
      onSuccess: (data: ArProductState[]) => {
        void queryClient.invalidateQueries(productKeys.all(serverId, false));
        void queryClient.invalidateQueries(productKeys.all(serverId, true));
        addToast({
          id: "archive-product-success",
          title: "Product Archived",
          color: "success",
        });
      },
    }
  );
};

export const restoreProduct = async (
  serverId: string,
  item: ArProductState
) => {
  const resp = await api.post<ArProductState>("/products/restore", {
    serverId,
    product: item,
  });
  return resp.data;
};

export const useRestoreProduct = (serverId: string) => {
  const queryClient = useQueryClient();
  return useMutation<any, Error | AxiosError, any>(
    async (payload: { serverId: string; item: ArProductState }) =>
      restoreProduct(payload.serverId, payload.item),
    {
      onSuccess: (data: ArProductState[]) => {
        void queryClient.invalidateQueries(productKeys.all(serverId, false));
        void queryClient.invalidateQueries(productKeys.all(serverId, true));
        addToast({
          id: "restore-product-success",
          title: "Product Restored",
          color: "success",
        });
      },
    }
  );
};

export const fetchCoupons = async (serverId: string) => {
  const resp = await api.get<ArPromotionCodeResponse[]>("/coupons", {
    params: { serverId },
  });
  return resp.data;
};

export const useCoupons = (
  serverId?: string,
  options?: QueryObserverOptions<ArPromotionCodeResponse[], Error>
) => {
  return useQuery<ArPromotionCodeResponse[], Error, ArPromotionCodeResponse[]>(
    productKeys.coupons(serverId!),
    async () => fetchCoupons(serverId!),
    {
      enabled: Boolean(serverId),
      ...options,
    }
  );
};

export const updateCoupon = async (serverId: string, coupon: ArCouponState) => {
  const resp = await api.post<ArCouponState>("/coupons", {
    serverId,
    coupon,
  });
  return resp.data;
};

export const useUpdateCoupon = (serverId: string) => {
  const queryClient = useQueryClient();
  return useMutation<ArCouponState, Error | AxiosError, any>(
    ["mutateCoupon"],
    async (payload: { serverId: string; coupon: ArCouponState }) =>
      updateCoupon(payload.serverId, payload.coupon),
    {
      onSuccess: (updatedCoupon: ArCouponState) => {
        void queryClient.invalidateQueries(productKeys.coupons(serverId));
        // queryClient.setQueryData(productKeys.coupons(serverId), (draft) => {
        //   return (draft as ArCouponState[]).map((oldCoupon) =>
        //     oldCoupon.id === updatedCoupon.id ? updatedCoupon : oldCoupon
        //   );
        // });
        addToast({
          id: "update-coupon-success",
          title: "Coupon Created",
          color: "success",
        });
      },
    }
  );
};

export const deleteCoupon = async (serverId: string, coupon: ArCouponState) => {
  const resp = await api.delete<ArCouponState>("/coupons", {
    data: {
      serverId,
      coupon,
    },
  });
  return resp.data;
};

export const useDeleteCoupon = (serverId: string) => {
  const queryClient = useQueryClient();
  return useMutation<any, Error | AxiosError, any>(
    ["mutateCoupon"],
    async (payload: { serverId: string; coupon: ArCouponState }) =>
      deleteCoupon(payload.serverId, payload.coupon),
    {
      onSuccess: (data: ArCouponState[]) => {
        void queryClient.invalidateQueries(productKeys.coupons(serverId));
        addToast({
          id: "archive-coupon-success",
          title: "Coupon Archived",
          color: "success",
        });
      },
    }
  );
};
