import React from "react";
import { useImmer } from "use-immer";
import { v4 } from "uuid";

import {
  EuiAccordion,
  EuiHeaderLink,
  EuiPopover,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";

import { DISCORD } from "../../features/constants";

const Popover = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useImmer<boolean>(false);

  const onButtonClick = () =>
    setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);

  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  const Button = (
    <EuiHeaderLink iconType="help" onClick={onButtonClick}>
      Help
    </EuiHeaderLink>
  );

  return (
    <EuiPopover
      button={Button}
      isOpen={isPopoverOpen}
      closePopover={closePopover}
    >
      <div style={{ maxWidth: 400 }}>
        <EuiAccordion
          id={v4()}
          buttonContent={
            <EuiText size={"s"}>
              I purchased a subscription, but nothing's happening. What gives?
            </EuiText>
          }
          paddingSize={"l"}
        >
          <EuiText size={"s"}>
            Did you link your Discord Account to your purchase? Check the
            group's website for the link to their unique link page. If this
            doesn't work, post a message <a href={DISCORD}>here</a> and we'll
            get it sorted out.
          </EuiText>
        </EuiAccordion>
        <EuiSpacer />
        <EuiAccordion
          id={v4()}
          buttonContent="I'm interested in using this service for my server."
          paddingSize={"l"}
        >
          <EuiText size={"s"}>
            Great! This product is under active development - message us{" "}
            <a href={DISCORD}>here</a> to join our beta. We are offering steep
            discounts for those who sign up early!
          </EuiText>
        </EuiAccordion>
      </div>
    </EuiPopover>
  );
};

export const HelpIcon = () => {
  return <Popover />;
};
