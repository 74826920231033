import { FormikProps } from "formik";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Updater } from "use-immer";

import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
} from "@elastic/eui";
import { css } from "@emotion/react";
import { useIsMutating } from "@tanstack/react-query";

import { AppSettingsState } from "../../../@types/appSettings";
import { DiscordGuildRole } from "../../../@types/discord";
import { ArProductLinkPayload } from "../../../@types/product";
import { ServerState } from "../../../@types/server";
import { useProducts } from "../../../features/api/products";
import { getModal } from "../../../features/redux/appState";
import { SelectGuildRole } from "../../select/SelectGuildRole";

import { FormValues, OuterProps } from "./types";

type RolesStepProps = OuterProps &
  FormikProps<FormValues> & {
    server: ServerState;
    guildRoles: DiscordGuildRole[];
    roleLinkPayload: ArProductLinkPayload;
    setRoleLinkPayload: Updater<ArProductLinkPayload>;
    handleClose: any;
  };

export const RolesStep: FC<RolesStepProps> = ({
  server,
  isSubmitting,
  guildRoles,
  roleLinkPayload,
  setRoleLinkPayload,
}) => {
  const dialog = useSelector(
    getModal("editProduct")
  ) as AppSettingsState["modals"]["editProduct"];

  const arProducts = useProducts(server.id);
  const isMutatingArProductLink = useIsMutating(["mutatingArProductLink"]);

  const onAddRole = () => {
    setRoleLinkPayload((draft) => {
      draft.links.push("");
      return draft;
    });
  };

  const onSelectRole = (name: string, value: string, index: number) => {
    setRoleLinkPayload((draft) => {
      draft.links[index] = value;
    });
  };

  const onDeleteRole = (index: number) => {
    setRoleLinkPayload((draft) => {
      draft.links.splice(index, 1);
      return draft;
    });
  };

  if (arProducts.isSuccess) {
    return (
      <EuiFlexGroup direction={"column"} gutterSize={"s"}>
        {dialog.props?.isEdit ? (
          <EuiFlexItem>
            <EuiCallOut
              css={css`
                text-align: center;
                margin-bottom: 15px;
              `}
              color={"primary"}
              size={"s"}
            >
              Role changes will be applied to all members with an active
              subscription to this product.
            </EuiCallOut>
          </EuiFlexItem>
        ) : null}

        {roleLinkPayload.links.length === 0 ? (
          <EuiFlexItem>
            <EuiFlexGroup>
              <EuiFlexItem>
                <SelectGuildRole
                  selectProps={{ fullWidth: true }}
                  value={roleLinkPayload.links[0]}
                  setFieldValue={(field: string, value: string) =>
                    onSelectRole(field, value, 0)
                  }
                  guildRoles={guildRoles}
                  showLabel={true}
                  showHelpText={true}
                />
              </EuiFlexItem>
              <EuiFlexItem
                css={css`
                  width: 24px;
                `}
                grow={false}
              />
            </EuiFlexGroup>
          </EuiFlexItem>
        ) : (
          roleLinkPayload.links.map((link, index) => {
            return (
              <EuiFlexItem key={`select-role-links-${index}`}>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <SelectGuildRole
                      disabled={
                        Boolean(isMutatingArProductLink) || isSubmitting
                      }
                      selectProps={{ fullWidth: true }}
                      value={roleLinkPayload.links[index]}
                      setFieldValue={(field: string, value: string) =>
                        onSelectRole(field, value, index)
                      }
                      guildRoles={guildRoles.filter((role) => {
                        return (
                          role.id === link ||
                          !roleLinkPayload.links.some((p) => p === role.id)
                        );
                      })}
                      showLabel={index === 0}
                      showHelpText={index === 0}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem
                    grow={false}
                    css={css`
                      width: 24px;
                      justify-content: center;
                    `}
                  >
                    {roleLinkPayload.links.length > 1 ? (
                      <EuiButtonIcon
                        disabled={
                          Boolean(isMutatingArProductLink) || isSubmitting
                        }
                        color={"danger"}
                        size={"xs"}
                        onClick={() => onDeleteRole(index)}
                        iconType={"trash"}
                      />
                    ) : null}
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            );
          })
        )}
        <EuiFlexItem
          css={css`
            align-items: center;
          `}
        >
          <EuiButtonEmpty
            disabled={
              Boolean(isMutatingArProductLink) ||
              isSubmitting ||
              !roleLinkPayload.links[roleLinkPayload.links.length - 1]
            }
            onClick={() => onAddRole()}
          >
            Add Another Role
          </EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }

  return null;
};
