import React from "react";

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiTitle,
} from "@elastic/eui";
import { css } from "@emotion/react";

import { ArPage } from "../../arComponents/ArPage";
import { ArPanel } from "../../arComponents/ArPanel";
import { useLogin } from "../../features/api/auth";
import { AutoroleFull } from "../../images/autorole-full";
import logo from "../../images/autorole-full.svg";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LoginBaseRouteProps {
  message?: string;
  path?: boolean;
}

export const LoginBaseRoute: React.FC<LoginBaseRouteProps> = ({
  message,
  path,
}) => {
  const login = useLogin();

  return (
    <ArPage
      isLoading={login.isLoading}
      bodyCss={css`
        height: 100%;
      `}
      body={
        <EuiFlexGroup
          gutterSize={"l"}
          justifyContent={"spaceAround"}
          alignItems={"center"}
          direction={"column"}
          responsive={false}
          css={css`
            height: 100%;
          `}
        >
          <EuiFlexItem
            grow={false}
            css={css`
              @media only screen and (max-width: 480px) {
                width: 80%;
              }
            `}
          >
            <ArPanel paddingSize={"xl"} className={"ar-login-panel ar-panel"}>
              <EuiFlexGroup
                direction={"column"}
                justifyContent={"spaceAround"}
                alignItems={"center"}
                css={css`
                  height: 100%;
                `}
              >
                {message ? (
                  <EuiFlexItem
                    css={css`
                      text-align: center;
                    `}
                  >
                    <EuiTitle>
                      <h2>{message}</h2>
                    </EuiTitle>
                  </EuiFlexItem>
                ) : null}
                <EuiFlexItem
                  css={css`
                    height: 100%;
                    width: 65%;
                  `}
                >
                  <AutoroleFull />
                  {/*<EuiImage*/}
                  {/*  alt={"logo"}*/}
                  {/*  src={logo}*/}
                  {/*  css={css`*/}
                  {/*    height: 100px;*/}
                  {/*  `}*/}
                  {/*></EuiImage>*/}
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    onClick={() =>
                      login.mutate({
                        path: path ? window.location.href : undefined,
                      })
                    }
                  >
                    Log in
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </ArPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      }
      restrictWidth={true}
    />
  );
};
