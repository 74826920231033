import React, { useEffect } from "react";
import { useImmer } from "use-immer";

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiLoadingContent,
} from "@elastic/eui";
import { useQueryClient } from "@tanstack/react-query";

import { DiscordGuildRole } from "../../../../@types/discord";
import { ServerState } from "../../../../@types/server";
import {
  ProductServerRoleLink,
  ServerRole,
} from "../../../../@types/serverRole";
import { SelectGuildRole } from "../../../../components/select/SelectGuildRole";
import { addToast } from "../../../../components/toast";
import {
  defaultRoleKeys,
  useSetDefaultServerRole,
} from "../../../../features/api/server-roles";

interface DefaultRoleSelectionProps {
  server: ServerState;
  guildRoles: DiscordGuildRole[] | undefined;
  productServerRoleLinks: ProductServerRoleLink[] | undefined;
  defaultServerRole: ServerRole | undefined;
  isLoading: boolean;
}

export const DefaultRoleSelection: React.FC<DefaultRoleSelectionProps> = ({
  server,
  guildRoles,
  productServerRoleLinks,
  defaultServerRole,
  isLoading,
}) => {
  const setDefaultServerRole = useSetDefaultServerRole();
  const queryClient = useQueryClient();
  const [defaultDiscordRoleId, setDefaultDiscordRoleId] = useImmer<string>("");
  const [defaultDiscordRoleName, setDefaultDiscordRoleName] =
    useImmer<string>("");

  useEffect(() => {
    if (defaultServerRole) {
      setDefaultDiscordRoleId(defaultServerRole.discordRoleId);
      setDefaultDiscordRoleName(defaultServerRole.discordRoleName);
    }
  }, [defaultServerRole]);

  const onRoleChange = (name: string, value: string) => {
    setDefaultDiscordRoleId(value);
    setDefaultDiscordRoleName(name);
  };

  const onSave = () => {
    setDefaultServerRole.mutate(
      {
        serverId: server.id,
        discordRoleName: defaultDiscordRoleName,
        discordRoleId: defaultDiscordRoleId,
      },
      {
        onSuccess: (data) => {
          void queryClient.invalidateQueries([defaultRoleKeys.base]);
          addToast({
            id: "set-default-role-success",
            title: `${defaultDiscordRoleName} set as default!`,
            color: "success",
          });
        },
      }
    );
  };

  if (isLoading || !productServerRoleLinks || !guildRoles) {
    return (
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiLoadingContent lines={3} />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  } else {
    return (
      <EuiFlexGroup style={{ maxWidth: 600 }} responsive={false}>
        <EuiFlexItem grow={false} style={{ width: 400 }}>
          <SelectGuildRole
            disabled={setDefaultServerRole.isLoading}
            value={defaultDiscordRoleId}
            setFieldValue={onRoleChange}
            guildRoles={guildRoles}
            showLabel={true}
            showHelpText={true}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormRow hasEmptyLabelSpace={true}>
            <EuiButton
              color={"accent"}
              disabled={setDefaultServerRole.isLoading}
              onClick={() => onSave()}
            >
              Save
            </EuiButton>
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};
