import { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useImmer } from "use-immer";

import { ROUTES } from "../../components/app/app";
import { addToast } from "../../components/toast";
import {
  useServer,
  useServerByAlias,
  useServerCurrency,
  useUpdateDiscordAccountServerLink,
} from "../api/servers";
import {
  getSubscribeForm,
  setSubscribeFormEmail,
  setSubscribeFormSourceId,
} from "../redux/subscribeForm";

export const useServerIdFromUrl = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const serverId = searchParams.get("serverId") || undefined;

  return useServer(serverId!, {
    enabled: Boolean(serverId),
  });
};

export const useServerFromUrl = (redirect = false) => {
  const { alias } = useParams<{ alias: string }>();
  const navigate = useNavigate();

  const server = useServerByAlias(alias!, {
    enabled: alias !== undefined,
  });

  const token = localStorage.getItem("token");
  const updateDiscordAccountServerLink = useUpdateDiscordAccountServerLink();

  if (server.error) {
    addToast({
      id: "invalid-page",
      color: "danger",
      title: "Invalid page",
    });
    navigate(ROUTES.INDEX);
  }

  useEffect(() => {
    if (alias && token) {
      updateDiscordAccountServerLink.mutate({ alias });
    }
  }, [alias, token]);

  return server;
};

export const useFetchSubscribeFormByUrlParams = (redirect = false) => {
  const [alias, setAlias] = useImmer<string | undefined>(undefined);

  const subscribeForm = useSelector(getSubscribeForm);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const parts = location.pathname.split("/");
    const _alias = parts[parts.length - 1];
    const search = new URLSearchParams(location.search);
    const _email = search.get("email");
    const _sourceId = search.get("sourceId");

    setAlias(_alias);

    if (_email) {
      dispatch(setSubscribeFormEmail(_email));
    }
    if (_sourceId) {
      dispatch(setSubscribeFormSourceId(_sourceId));
    }
  }, []);

  useEffect(() => {
    if (
      redirect &&
      alias &&
      subscribeForm.init &&
      subscribeForm.email &&
      subscribeForm.sourceId
    ) {
      navigate(
        `${ROUTES.SERVER}/${alias}?email=${subscribeForm.email}&sourceId=${subscribeForm.sourceId}`
      );
    }
  }, [
    redirect,
    alias,
    subscribeForm.init,
    subscribeForm.email,
    subscribeForm.sourceId,
  ]);
};

export const useCurrencyFormatter = (serverId?: string) => {
  let currency;
  if (serverId) {
    currency = useServerCurrency(serverId);
  }
  if (currency?.isSuccess && currency.data) {
    return new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: currency.data,
      minimumFractionDigits: 2,
    });
  }
  return null;
};

export const useIsOverflow = (ref: any, callback?: any) => {
  const [isOverflow, setIsOverflow] = useImmer<boolean>(false);

  useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = current.scrollHeight > current.clientHeight;
      setIsOverflow(hasOverflow);
      if (callback) callback(hasOverflow);
    };

    if (current) {
      trigger();
    }
  }, [callback, ref]);

  return isOverflow;
};
