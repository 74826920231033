import { DateTime } from "luxon";
import React, { FC } from "react";
import { useImmer } from "use-immer";

import { EuiBasicTable, EuiButtonEmpty } from "@elastic/eui";
import { EuiBasicTableColumn } from "@elastic/eui/src/components/basic_table/basic_table";
import { useIsMutating } from "@tanstack/react-query";

import { ServerState } from "../../@types/server";
import { SubscriptionState } from "../../@types/subscription";
import { useUser } from "../../features/api/auth";
import { useBillingPortal } from "../../features/api/stripe";
import { ACTIVE, statusPrettier } from "../../features/constants";

interface ArSubscriptionDetailsTableProps {
  server: ServerState;
  subscriptions: SubscriptionState[];
}

export const ArSubscriptionDetailsTable: FC<
  ArSubscriptionDetailsTableProps
> = ({ server, subscriptions }) => {
  const [isCustomerPortalMutating, setIsCustomerPortalMutating] =
    useImmer<boolean>(false);
  const user = useUser();
  const billingPortal = useBillingPortal();
  const customerPortalMutating = useIsMutating(["billingPortal"]);

  const columns: EuiBasicTableColumn<SubscriptionState>[] = [
    {
      field: "name",
      name: "Name",
    },
    {
      field: "status",
      name: "Status",
      render: (value: string) => {
        return ACTIVE.includes(value)
          ? "Active"
          : value === "trash"
          ? statusPrettier("cancelled")
          : statusPrettier(value);
      },
    },
    {
      field: "dateCreatedGmt",
      name: "Started On",
      render: (value: string) => {
        return DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED);
      },
    },
    {
      field: "nextPaymentDateGmt",
      name: "Next Payment Date",
      render: (value: string) => {
        return DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED);
      },
    },
    {
      field: "",
      name: "",
      actions: [
        {
          // @ts-ignore
          render: (row: SubscriptionState) => {
            if (["active", "trialing"].includes(row.status)) {
              return (
                <EuiButtonEmpty
                  isLoading={isCustomerPortalMutating}
                  onClick={() => {
                    setIsCustomerPortalMutating(true);
                    billingPortal.mutate({
                      serverId: server.id,
                      subscriptionId: row.id,
                      discordAccountId: user.data?.id,
                    });
                  }}
                >
                  Modify
                </EuiButtonEmpty>
              );
            }
            return <></>;
          },
        },
      ],
    },
  ];

  return (
    <EuiBasicTable
      columns={columns}
      items={subscriptions}
      tableLayout={"auto"}
      hasActions={false}
    />
  );
};
