import React from "react";
import { useImmer } from "use-immer";

import { EuiFlexGroup, EuiFlexItem, EuiTitle } from "@elastic/eui";

import { DataTimePeriod } from "../../../../@types/data";
import { ServerState } from "../../../../@types/server";
import { ArPanel } from "../../../../arComponents/ArPanel";
import { DataSummary } from "../../../../components/chart/DataSummary";
import { SubscriptionSalesOverTime } from "../../../../components/chart/GrossRevenueOverTime";
import { TotalOrderCountByProductOverTime } from "../../../../components/chart/OrderCountByProductOverTime";
import { TimeSelector } from "../../../../components/chart/TimeSelector";
import { TotalOrderCountOverTime } from "../../../../components/chart/totalOrderCountOverTime";
import { DataSummaryTable } from "../../../../components/table/DataSummaryTable";

interface ManagerServerChartsPanelProps {
  server: ServerState;
}

export const ChartsPanel: React.FC<ManagerServerChartsPanelProps> = ({
  server,
}) => {
  const [timePeriod, setTimePeriod] = useImmer<DataTimePeriod>("MTD");

  return (
    <ArPanel>
      <EuiFlexGroup direction={"column"}>
        <EuiFlexItem>
          <EuiFlexGroup
            direction={"row"}
            justifyContent={"spaceBetween"}
            alignItems={"flexEnd"}
          >
            <EuiFlexItem>
              <EuiTitle size={"s"}>
                <h2>Performance</h2>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem grow={false} className={"center-mobile"}>
              <TimeSelector
                timePeriod={timePeriod}
                setTimePeriod={setTimePeriod}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <DataSummary server={server} timePeriod={timePeriod} />
        </EuiFlexItem>
        <EuiFlexItem>
          <SubscriptionSalesOverTime server={server} timePeriod={timePeriod} />
        </EuiFlexItem>
        <EuiFlexItem>
          <TotalOrderCountOverTime server={server} timePeriod={timePeriod} />
        </EuiFlexItem>
        <EuiFlexItem>
          <TotalOrderCountByProductOverTime
            server={server}
            timePeriod={timePeriod}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <DataSummaryTable server={server} timePeriod={timePeriod} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </ArPanel>
  );
};
