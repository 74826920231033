import React from "react";

import { EuiBadge } from "@elastic/eui";

interface MoreRolesPopoverButtonProps {
  onClick: () => void;
}

export const MoreRolesPopoverButton: React.FC<MoreRolesPopoverButtonProps> = ({
  onClick,
}) => {
  return (
    <EuiBadge
      key={"more"}
      color={"gray"}
      onClick={() => onClick()}
      onClickAriaLabel="More Roles Button Click"
    >
      ···
    </EuiBadge>
  );
};
