import { QueryObserverOptions, useMutation } from "@tanstack/react-query";

import { api } from "./api";

export const createAccount = async (serverId: string) => {
  const resp = await api.post<any>(`/stripe/account`, { serverId });
  return resp.data;
};

export const useCreateAccount = <Response>(
  options?: QueryObserverOptions<Response, Error>
) => {
  return useMutation<any, Error, any>(
    async (serverId: string) => createAccount(serverId),
    {
      onSuccess: (data) => {
        if (data.redirect) {
          window.location.href = data.redirect;
        }
      },
    }
  );
};

export const stripeReturn = async (serverId: string) => {
  const resp = await api.post<any>(`/stripe-auth/return`, { serverId });
  return resp.data;
};

export const useStripeReturn = <Response>(
  options?: QueryObserverOptions<Response, Error>
) => {
  return useMutation<any, Error, any>(
    async (serverId: string) => stripeReturn(serverId),
    {
      onSuccess: (data) => {
        if (data.redirect) {
          window.location.href = data.redirect;
        }
      },
    }
  );
};

export const stripeRefresh = async (serverId: string) => {
  const resp = await api.post<any>(`/stripe-auth/refresh`, { serverId });
  return resp.data;
};

export const useStripeRefresh = <Response>(
  options?: QueryObserverOptions<Response, Error>
) => {
  return useMutation<any, Error, any>(
    async (serverId: string) => stripeRefresh(serverId),
    {
      onSuccess: (data) => {
        if (data.redirect) {
          window.location.href = data.redirect;
        }
      },
    }
  );
};

export const createPurchase = async (
  serverId: string,
  productId: string,
  discordAccountId: string
) => {
  const resp = await api.post<any>(`/stripe/purchase`, {
    serverId,
    productId,
    discordAccountId,
  });
  return resp.data;
};

export const useCreatePurchase = <Response>(
  options?: QueryObserverOptions<Response, Error>
) => {
  return useMutation<any, Error, any>(
    async (data: { serverId: string; productId: string; discordAccountId: string }) =>
      createPurchase(data.serverId, data.productId, data.discordAccountId),
    {
      retry: 3,
      onSuccess: (data) => {
        if (data.redirect) {
          window.location.href = data.redirect;
        }
      },
    }
  );
};

export const billingPortal = async (
  serverId: string,
  subscriptionId: string
) => {
  const resp = await api.post<any>(`/stripe/customerPortal`, {
    serverId,
    subscriptionId,
  });
  return resp.data;
};

export const useBillingPortal = <Response>(
  options?: QueryObserverOptions<Response, Error>
) => {
  return useMutation<any, Error, any>(
    ["customerPortal"],
    async (data: { serverId: string; subscriptionId: string }) =>
      billingPortal(data.serverId, data.subscriptionId),
    {
      onSuccess: (data) => {
        if (data.redirect) {
          window.location.href = data.redirect;
        }
      },
    }
  );
};
