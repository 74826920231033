import { AxiosError } from "axios";
import { FormikProps, withFormik } from "formik";
import { isEmpty } from "lodash";
import React, { Dispatch, useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import * as Yup from "yup";

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiImage,
  EuiLink,
  EuiSwitch,
  EuiTitle,
} from "@elastic/eui";
import { css } from "@emotion/react";
import { UseMutationResult } from "@tanstack/react-query";

import { ServerState } from "../../../../@types/server";
import { SelectGuildChannel } from "../../../../components/select/SelectGuildChannel";
import { ImageUploadModal } from "../../../../components/upload/ImageUploadModal";
import { useUpdateServer } from "../../../../features/api/servers";
import { setModal } from "../../../../features/redux/appState";

interface FormValues {
  guildId: string;
  companyName: string;
  logoUrl: string;
  privateLogChannelId: string | null;
  publicLogChannelId: string | null;
  onboardComplete: boolean;
}

interface OuterProps {
  publicEnabled: boolean;
  setPublicEnabled: Dispatch<React.SetStateAction<boolean>>;
  privateEnabled: boolean;
  setPrivateEnabled: Dispatch<React.SetStateAction<boolean>>;
  server: ServerState;
  initialValues: Partial<FormValues>;
  updateServer: UseMutationResult<
    any,
    Error | AxiosError<any, any>,
    any,
    unknown
  >;
  dispatch: Dispatch<any>;
  navigate: NavigateFunction;
}

const BaseForm = (props: OuterProps & FormikProps<FormValues>) => {
  const {
    server,
    updateServer,
    setPublicEnabled,
    setPrivateEnabled,
    privateEnabled,
    publicEnabled,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    dispatch,
    navigate,
  } = props;

  return (
    <>
      <EuiFlexGroup direction={"column"}>
        <EuiFlexItem>
          <form onSubmit={handleSubmit}>
            <EuiForm>
              <EuiFlexGroup direction={"column"}>
                <EuiFlexItem>
                  <EuiFlexGroup responsive={false}>
                    <EuiFlexItem>
                      <EuiTitle size={"s"}>
                        <h2>General Settings</h2>
                      </EuiTitle>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    fullWidth={false}
                    label={"Company Name"}
                    isInvalid={
                      (isSubmitting || touched.companyName) &&
                      !!errors.companyName
                    }
                    error={errors.companyName}
                  >
                    <EuiFieldText
                      fullWidth={true}
                      name={"companyName"}
                      value={values.companyName}
                      required={touched.companyName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isLoading={isSubmitting}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    fullWidth={true}
                    label={"Logo"}
                    isInvalid={
                      (isSubmitting || touched.logoUrl) && !!errors.logoUrl
                    }
                    error={errors.logoUrl}
                  >
                    <EuiFlexGroup
                      direction={"column"}
                      css={css`
                        margin-top: ${values.logoUrl ? "10px" : "0"};
                      `}
                    >
                      {values.logoUrl ? (
                        <EuiFlexItem
                          css={css`
                            align-items: flex-start;
                          `}
                        >
                          <EuiImage
                            size={"m"}
                            alt={"logo"}
                            src={values.logoUrl}
                          />
                        </EuiFlexItem>
                      ) : null}

                      <EuiFlexItem
                        grow={false}
                        css={css`
                          margin-top: ${values.logoUrl
                            ? undefined
                            : "0 !important"};
                        `}
                      >
                        <EuiFlexGroup gutterSize={"none"}>
                          <EuiFlexItem grow={false}>
                            <EuiLink
                              color={"success"}
                              onClick={() =>
                                dispatch(
                                  setModal({
                                    modal: "imageUpload",
                                    open: true,
                                    props: { id: server.id },
                                  })
                                )
                              }
                            >
                              Choose Image
                            </EuiLink>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFlexGroup>
                    <EuiFlexItem grow={2}>
                      <EuiFormRow
                        fullWidth={true}
                        label={"Discord Guild (Server) ID"}
                        isInvalid={
                          (isSubmitting || touched.guildId) && !!errors.guildId
                        }
                        error={errors.guildId}
                      >
                        <EuiFieldText
                          readOnly={true}
                          fullWidth={true}
                          name={"guildId"}
                          value={values.guildId}
                          required={touched.guildId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isLoading={isSubmitting}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                    {/*<EuiFlexItem grow={4}>*/}
                    {/*  <EuiFlexGroup responsive={false}>*/}
                    {/*    <EuiFlexItem grow={false}>*/}
                    {/*      <EuiFormRow hasEmptyLabelSpace={true}>*/}
                    {/*        <EuiSwitch*/}
                    {/*          label={"Enabled"}*/}
                    {/*          disabled={isSubmitting}*/}
                    {/*          checked={privateEnabled}*/}
                    {/*          onChange={(e) =>*/}
                    {/*            setPrivateEnabled(e.target.checked)*/}
                    {/*          }*/}
                    {/*        />*/}
                    {/*      </EuiFormRow>*/}
                    {/*    </EuiFlexItem>*/}
                    {/*    <EuiFlexItem>*/}
                    {/*      <EuiFormRow*/}
                    {/*        fullWidth={true}*/}
                    {/*        label={"Private Log Channel"}*/}
                    {/*        isInvalid={*/}
                    {/*          (isSubmitting || touched.privateLogChannelId) &&*/}
                    {/*          !!errors.privateLogChannelId*/}
                    {/*        }*/}
                    {/*        error={errors.privateLogChannelId}*/}
                    {/*      >*/}
                    {/*        <SelectGuildChannel*/}
                    {/*          value={values.privateLogChannelId || ""}*/}
                    {/*          name={"privateLogChannelId"}*/}
                    {/*          setFieldValue={setFieldValue}*/}
                    {/*          isLoading={isSubmitting ? true : !privateEnabled}*/}
                    {/*        />*/}
                    {/*      </EuiFormRow>*/}
                    {/*    </EuiFlexItem>*/}
                    {/*  </EuiFlexGroup>*/}
                    {/*</EuiFlexItem>*/}
                    <EuiFlexItem grow={4}>
                      <EuiFlexGroup responsive={false}>
                        <EuiFlexItem grow={false}>
                          <EuiFormRow hasEmptyLabelSpace={true}>
                            <EuiSwitch
                              disabled={isSubmitting}
                              label={"Enabled"}
                              checked={publicEnabled}
                              onChange={(e) => {
                                setPublicEnabled(e.target.checked);
                              }}
                            />
                          </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem>
                          <EuiFormRow
                            fullWidth={true}
                            label={"Public Log Channel"}
                            isInvalid={
                              (isSubmitting || touched.publicLogChannelId) &&
                              !!errors.publicLogChannelId
                            }
                            error={errors.publicLogChannelId}
                          >
                            <SelectGuildChannel
                              value={values.publicLogChannelId || ""}
                              name={"publicLogChannelId"}
                              setFieldValue={setFieldValue}
                              isLoading={isSubmitting ? true : !publicEnabled}
                            />
                          </EuiFormRow>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFlexGroup>
                    <EuiFlexItem grow={false}>
                      <EuiButton
                        color={"accent"}
                        type={"submit"}
                        isLoading={!errors || isSubmitting}
                        onClick={() => handleSubmit()}
                      >
                        Save
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiForm>
          </form>
        </EuiFlexItem>
      </EuiFlexGroup>
      <ImageUploadModal
        id={server.id}
        server={server}
        initialAspectRatio={1.61}
        initialImgSrc={values.logoUrl}
        onComplete={(location) => {
          setFieldValue("logoUrl", location);
          dispatch(setModal({ modal: "imageUpload", open: false }));
        }}
      />
    </>
  );
};

interface ManageServerPageSettingsFormProps {
  server: ServerState;
}

const FormikForm = withFormik<OuterProps, FormValues>({
  mapPropsToValues: (props) => ({
    guildId: props.initialValues.guildId || "",
    logoUrl: props.initialValues.logoUrl || "",
    companyName: props.initialValues.companyName || "",
    privateLogChannelId: props.initialValues.privateLogChannelId || "",
    publicLogChannelId: props.initialValues.publicLogChannelId || "",
    onboardComplete: props.initialValues.onboardComplete || false,
  }),

  enableReinitialize: true,
  validationSchema: Yup.object().shape({
    companyName: Yup.string().required("Company Name is required"),
    guildId: Yup.string().required("Guild ID is required"),
    logoUrl: Yup.string()
      .url("ArLogo URL must be a valid URL")
      .required("ArLogo URL is required"),
    privateChannelLogId: Yup.string(),
    publicChannelLogId: Yup.string(),
  }),

  handleSubmit(
    {
      guildId,
      logoUrl,
      companyName,
      privateLogChannelId,
      publicLogChannelId,
    }: FormValues,
    { props, setSubmitting, setErrors }
  ) {
    setSubmitting(true);
    const updatedServer: Partial<ServerState> = {
      init: true,
      id: props.server.id,
      alias: props.server.alias,
      guildId,
      logoUrl,
      companyName,
      privateLogChannelId: props.privateEnabled ? privateLogChannelId : null,
      publicLogChannelId: props.publicEnabled ? publicLogChannelId : null,
    };
    props.updateServer.mutate(updatedServer, {
      onSettled: (data) => {
        setSubmitting(false);
      },
    });
  },
})(BaseForm);

export const ArPortalSettingsPanel: React.FC<
  ManageServerPageSettingsFormProps
> = ({ server }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [privateEnabled, setPrivateEnabled] = useImmer<boolean>(
    !isEmpty(server.privateLogChannelId)
  );
  const [publicEnabled, setPublicEnabled] = useImmer<boolean>(
    !isEmpty(server.publicLogChannelId)
  );

  const updateServer = useUpdateServer();

  useEffect(() => {
    setPrivateEnabled(server.privateLogChannelId !== null);
    setPublicEnabled(server.publicLogChannelId !== null);
  }, [server]);

  return (
    <FormikForm
      server={server}
      initialValues={server}
      updateServer={updateServer}
      publicEnabled={publicEnabled}
      setPublicEnabled={setPublicEnabled}
      privateEnabled={privateEnabled}
      setPrivateEnabled={setPrivateEnabled}
      dispatch={dispatch}
      navigate={navigate}
    />
  );
};
