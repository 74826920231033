import { useNavigate } from "react-router-dom";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  DiscordLoginResponse,
  LoginResponse,
  UserState,
  WooLoginResponse,
} from "../../@types/user";
import { ROUTES } from "../../components/app/app";

import { api } from "./api";

const userKeys = {
  all: [{ scope: "user" }] as const,
  user: (jwt: string | null) => [{ ...userKeys.all[0], jwt }] as const,
};

export const discordLogin = async (
  email: string,
  sourceId: string,
  path: string
) => {
  const resp = await api.post<DiscordLoginResponse>(`/discord-login`, {
    email,
    sourceId,
    path,
  });
  return resp.data;
};

export const login = async (path?: string) => {
  const resp = await api.post<LoginResponse>(`/login`, { path });
  return resp.data;
};

export const wooLogin = async (serverId: string, url: string) => {
  const resp = await api.post<WooLoginResponse>("woo-login", {
    serverId,
    url,
    returnUrl: window.location.href,
  });
  return resp.data;
};

export const useLogin = () => {
  return useMutation(async (data: { path?: string }) => login(data.path), {
    onSuccess: (data) => {
      if (data.redirect) {
        window.location.href = data.redirect;
      }
    },
  });
};

export const useLogout = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return {
    mutate: () => {
      localStorage.removeItem("token");
      queryClient.clear();
      navigate(ROUTES.LOGIN);
    },
  };
};

export const useDiscordLogin = () => {
  return useMutation(
    ["discordLogin", "email", "sourceId"],
    async (data: { email: string; sourceId: string; path: string }) =>
      discordLogin(data.email, data.sourceId, data.path),
    {
      onSuccess: (data) => {
        window.location.href = data.redirect;
      },
    }
  );
};

export const useWooLogin = () => {
  return useMutation(
    ["wooLogin", "serverId", "url"],
    async (data: { serverId: string; url: string }) =>
      wooLogin(data.serverId, data.url),
    {
      onSuccess: (data) => {
        window.location.href = data.redirect;
      },
    }
  );
};

export const JwtLogin = async (token: string) => {
  const resp = await api.post<UserState>("/login", { token: token });
  return resp.data;
};

export const useUser = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = localStorage.getItem("token");

  const urlJWT = urlParams.get("auth") || token || null;

  const queryClient = useQueryClient();

  return useQuery<UserState, Error, UserState, any>(
    userKeys.all,
    async () => JwtLogin(urlJWT!),
    {
      enabled: urlJWT !== null,
      onSuccess: (data) => {
        localStorage.setItem("token", data.token);
      },
      onError: (data) => {
        queryClient.removeQueries(userKeys.all);
      },
    }
  );
};

export const DiscordBotLogin = async (path: string) => {
  const resp = await api.post<DiscordLoginResponse>(`/discord-bot-login`, {
    path,
  });
  return resp.data;
};

export const useDiscordBotLogin = () => {
  return useMutation(
    ["discordBotAuth"],
    async (data: { path: string }) => DiscordBotLogin(data.path),
    {
      onSuccess: (data) => {
        window.location.href = data.redirect;
      },
    }
  );
};
