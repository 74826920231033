import axios, { AxiosRequestConfig, Method } from "axios";

import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

const apiClient = axios.create({
  baseURL: `https://${process.env.REACT_APP_API_URL}`,
  headers: {
    "Content-type": "application/json",
  },
});

apiClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (config.headers === undefined) {
    config.headers = {};
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

const _request = async (method: Method, url: string, config?: AxiosRequestConfig) =>
  apiClient.request({
    method,
    url,
    ...config,
  });

export const api = {
  get: async <T>(url: string, config?: AxiosRequestConfig) =>
    _request("GET", url, { ...config }),
  post: async <T>(url: string, data: any, config?: AxiosRequestConfig) =>
    _request("POST", url, { data, ...config }),
  patch: async <T>(url: string, data: any, config?: AxiosRequestConfig) =>
    _request("PATCH", url, { data, ...config }),
  delete: async <T>(url: string, config?: AxiosRequestConfig) =>
    _request("DELETE", url, { ...config }),
};

export const useLazyQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  key: TQueryKey,
  fn: QueryFunction<TQueryFnData, TQueryKey>,
  options: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "queryKey" | "queryFn"
  > = {}
): UseQueryResult<TData, TError> => {
  return useQuery(key, fn, {
    ...options,
    enabled: false,
  });
};
