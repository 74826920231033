import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

import {
  QueryObserverOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { ServerMemberTableRow, ServerState } from "../../@types/server";
import { ROUTES } from "../../components/app/app";
import { addToast } from "../../components/toast";

import { api } from "./api";

export enum IntegrationStatus {
  NotStarted = 0,
  ServerSelected = 5,
  StoreUrlAdded = 10,
  PluginInstalled = 15,
  OwnerInformationSaved = 20,
  BillingAccepted = 25,
  MissingVerification = 30,
  Complete = 35,
}

export const serverKeys = {
  all: [{ scope: "server" }] as const,
  one: (alias: string) => [{ ...serverKeys.all[0], server: alias }] as const,
};

export const FetchServerByAlias = async (
  alias: string
): Promise<ServerState> => {
  const resp = await api.get<ServerState[]>("/servers/alias/" + alias);
  return resp.data;
};

export const useServerByAlias = (
  alias: string,
  options?: QueryObserverOptions<ServerState, Error>
) =>
  useQuery<any, Error, ServerState>(
    serverKeys.one(alias),
    async () => FetchServerByAlias(alias),
    {
      refetchOnWindowFocus: true,
      ...options,
    }
  );

export const fetchServers = async (): Promise<ServerState[]> => {
  const resp = await api.get<ServerState[]>("/servers");
  return resp.data;
};

export const fetchServer = async (serverId: string) => {
  const resp = await api.get<ServerState>(`servers?serverId=${serverId}`);
  return resp.data;
};

export const useServer = (
  serverId: string,
  options?: QueryObserverOptions<ServerState, Error>
) =>
  useQuery<ServerState, Error>(
    serverKeys.one(serverId),
    async () => fetchServer(serverId),
    options
  );

export const useServers = () =>
  useQuery<ServerState[], Error>(serverKeys.all, async () => fetchServers());

export const updateServer = async (item: ServerState) => {
  const resp = await api.patch<ServerState>("/servers/" + item.id, item);
  return resp.data;
};

export const useUpdateServer = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error | AxiosError, any>(
    async (update: ServerState) => updateServer(update),
    {
      onSuccess: (data: ServerState[]) => {
        void queryClient.invalidateQueries(serverKeys.all);
        addToast({
          id: "update-server-success",
          title: "Server Settings Updated",
          color: "success",
        });
      },
    }
  );
};

const serverMemberTableKeys = {
  all: (serverId: string) => [{ scope: "severMemberTable", serverId }] as const,
};

export const fetchServerMemberTable = async (
  serverId: string
): Promise<ServerMemberTableRow[]> => {
  const resp = await api.get<ServerMemberTableRow[]>("/servers/memberTable", {
    params: { serverId },
  });
  return resp.data;
};

export const usePrefetchServerMemberTable = async (serverId?: string) => {
  const queryClient = useQueryClient();
  if (serverId) {
    await queryClient.prefetchQuery(
      serverMemberTableKeys.all(serverId),
      async () => fetchServerMemberTable(serverId)
    );
  }
};

export const useServerMemberTable = (
  serverId: string,
  options?: QueryObserverOptions<ServerMemberTableRow[], Error>
) =>
  useQuery<ServerMemberTableRow[], Error>(
    serverMemberTableKeys.all(serverId),
    async () => fetchServerMemberTable(serverId),
    options
  );

export const updateDiscordAccountServerLink = async (
  alias?: string,
  serverId?: string
) => {
  const resp = await api.post("/servers/discordLink", {
    alias,
    serverId,
  });
  return resp.data;
};

export const useUpdateDiscordAccountServerLink = () => {
  const navigate = useNavigate();
  return useMutation<any, Error | AxiosError, any>(
    async (data: { alias?: string; serverId: string }) =>
      updateDiscordAccountServerLink(data.alias, data.serverId),
    {
      onError: (error) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 404) {
            addToast({
              id: "update-discord-account-server-link-failure",
              color: "danger",
              title: "This server does not exist.",
            });
            navigate(ROUTES.INDEX);
            return;
          }
          return;
        } else {
          throw error;
        }
      },
    }
  );
};

export const acceptBilling = async (serverId: string) => {
  const resp = await api.post(
    `/servers/accept-billing?serverId=${serverId}`,
    {}
  );
  return resp.data;
};

export const useAcceptBilling = () => {
  const navigate = useNavigate();
  return useMutation<any, Error | AxiosError, any>(
    async (serverId: string) => acceptBilling(serverId),
    {}
  );
};

export const fetchServerCurrency = async (serverId: string) => {
  const resp = await api.get<string>(`servers/currency?serverId=${serverId}`);
  return resp.data;
};

export const useServerCurrency = (
  serverId: string,
  options?: QueryObserverOptions<string, Error>
) =>
  useQuery<string, Error>(
    serverKeys.one(serverId),
    async () => fetchServerCurrency(serverId),
    options
  );
