import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppState } from "../../@types";
import { AppSettingsState } from "../../@types/appSettings";

const initialState: AppSettingsState = {
  init: false,
  wooConnectionStatus: false,
  modals: {
    imageUpload: { isOpen: false },
    productDescription: { isOpen: false },
    editProduct: { isOpen: false },
    editCoupon: { isOpen: false },
    purchase: { isOpen: false },
    missingRoles: { isOpen: false },
    createTestAccount: { isOpen: false },
  },
};

export const slice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setWooConnectionStatus: (
      appState,
      { payload: value }: PayloadAction<boolean>
    ) => {
      appState.wooConnectionStatus = value;
    },
    setModal: (
      appState,
      value: PayloadAction<{
        modal: keyof AppState["appState"]["modals"];
        open: boolean;
        props?: any;
      }>
    ) => {
      appState.modals[value.payload.modal].isOpen = value.payload.open;
      appState.modals[value.payload.modal].props = value.payload.props;
    },
  },
});

export const { setWooConnectionStatus, setModal } = slice.actions;

export const isDialogOpen =
  (dialog: keyof AppState["appState"]["modals"]) => (state: AppState) => {
    return state.appState.modals[dialog].isOpen;
  };

export const getModal =
  (dialog: keyof AppState["appState"]["modals"]) => (state: AppState) => {
    return state.appState.modals[dialog];
  };

export const appStateReducer = slice.reducer;

export const getWooConnectionStatus = (state: AppState) =>
  state.appState.wooConnectionStatus;
