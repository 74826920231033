import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  EuiEmptyPrompt,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import { useQueryClient } from "@tanstack/react-query";

import { ServerState } from "../@types/server";
import { ArPage } from "../arComponents/ArPage";
import { ArPanel } from "../arComponents/ArPanel";
import { ROUTES } from "../components/app/app";
import { Header } from "../components/header/Header";
import { ServerSubscriptions } from "../components/product/ServerSubscriptions";
import { useUser } from "../features/api/auth";
import { useServers } from "../features/api/servers";
import { useServerFromUrl } from "../features/hooks/hooks";

import { ErrorPage } from "./errorPage";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LoggedInDashboardProps {}

export const LoggedInDashboard: React.FC<LoggedInDashboardProps> = () => {
  const user = useUser();
  const servers = useServers();
  const queryClient = useQueryClient();
  const server = useServerFromUrl();

  const navigate = useNavigate();

  useEffect(() => {
    if (!user.data || !user.data.token) {
      queryClient.removeQueries(["user"]);
    }
  }, [user.data]);

  if (servers.error) {
    return <ErrorPage />;
  }

  return (
    <ArPage
      isLoading={servers.isLoading}
      header={<Header />}
      body={
        <EuiFlexGroup justifyContent={"flexStart"} direction={"column"}>
          <EuiFlexItem>
            <ArPanel>
              <EuiFlexGroup direction={"column"}>
                <EuiFlexItem>
                  <EuiTitle size={"s"}>
                    <h2>Subscriptions</h2>
                  </EuiTitle>
                </EuiFlexItem>

                {servers.data === undefined || servers.data.length === 0 ? (
                  <EuiEmptyPrompt
                    titleSize={"s"}
                    title={<h2>You are not associated with any servers</h2>}
                    body={
                      server.isSuccess && server.data.alias ? (
                        <EuiText>
                          I just paid for a subscription - what do I do now?{" "}
                          <EuiLink
                            onClick={() => {
                              queryClient.removeQueries(["subscription"]);
                              navigate(`${ROUTES.SERVER}/${server.data.alias}`);
                            }}
                          >
                            Link your Discord Account to your purchase.
                          </EuiLink>
                        </EuiText>
                      ) : (
                        <EuiText>
                          I just paid for a subscription - what do I do now?{" "}
                          <br />
                          Check your email to activate your purchase.
                        </EuiText>
                      )
                    }
                  />
                ) : (
                  <EuiFlexItem>
                    <EuiFlexGrid columns={1}>
                      {servers.isSuccess &&
                        servers.data.map((server: ServerState) => (
                          <ServerSubscriptions
                            server={server}
                            key={`server-${server.id}`}
                          />
                        ))}
                    </EuiFlexGrid>
                  </EuiFlexItem>
                )}
              </EuiFlexGroup>
            </ArPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      }
      restrictWidth={true}
    />
  );
};
