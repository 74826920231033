import React from "react";

import {
  EuiCodeBlock,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
} from "@elastic/eui";

import { ArPage } from "../arComponents/ArPage";
import { ArPanel } from "../arComponents/ArPanel";
import { Header } from "../components/header/Header";

interface LoggedInDashboardProps {
  error?: string;
}

export const ErrorPage: React.FC<LoggedInDashboardProps> = ({ error }) => {
  return (
    <ArPage
      isLoading={false}
      header={<Header />}
      body={
        <EuiFlexGroup justifyContent={"flexStart"} direction={"column"}>
          <EuiFlexItem>
            <ArPanel className={"opaque-panel"}>
              <EuiFlexGroup direction={"column"}>
                <EuiFlexItem>
                  <EuiTitle size={"s"}>
                    <h2>Something Went Wrong</h2>
                  </EuiTitle>
                </EuiFlexItem>

                <EuiFlexItem>
                  <EuiCodeBlock paddingSize="none" transparentBackground>
                    {error || "Please refresh the page."}
                  </EuiCodeBlock>
                </EuiFlexItem>
              </EuiFlexGroup>
            </ArPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      }
      restrictWidth={true}
    />
  );
};
