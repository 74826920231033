import React, { FC } from "react";
import { useSelector } from "react-redux";

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiText,
  EuiTextColor,
} from "@elastic/eui";
import { css } from "@emotion/react";

import { AppSettingsState } from "../../@types/appSettings";
import { ArModal } from "../../arComponents/ArModal";
import { getModal } from "../../features/redux/appState";
import gif from "../../images/autorole-role.gif";

export const MissingRolesModal: FC = () => {
  const dialog = useSelector(
    getModal("missingRoles")
  ) as AppSettingsState["modals"]["missingRoles"];

  if (dialog.isOpen) {
    return (
      <ArModal
        yesText={"Close"}
        modalKey={"missingRoles"}
        minWidth={"750px"}
        title={"How to move Autorole's role priority"}
        body={
          <EuiFlexGroup
            direction={"column"}
            css={css`
              @media (min-width: 768px) {
                height: 100%;
              }

              flex-wrap: nowrap;
            `}
          >
            <EuiFlexItem>
              <EuiText>
                Discord bots can only modify roles with a{" "}
                <EuiTextColor>lower</EuiTextColor> than the bot itself. Move the{" "}
                <EuiTextColor>autorole</EuiTextColor> role to the top of the
                role list.
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiImage size={"l"} alt={"autorole-role"} src={gif} />
            </EuiFlexItem>
          </EuiFlexGroup>
        }
      />
    );
  }
  return null;
};
