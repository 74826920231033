import React from "react";

import { EuiButton, EuiCallOut, EuiFlexItem } from "@elastic/eui";

import { ServerState } from "../../../../@types/server";
import { IntegrationStatus } from "../../../../features/api/servers";
import { useCreateAccount } from "../../../../features/api/stripe";

interface ArAccountSectionProps {
  server: ServerState;
}
export const ArAccountSection: React.FC<ArAccountSectionProps> = ({
  server,
}) => {
  const createAccount = useCreateAccount();

  if (server.integrationStatus < IntegrationStatus.BillingAccepted) {
    return (
      <EuiFlexItem grow={false}>
        <EuiCallOut title={"Stripe Account Setup Incomplete"} color={"warning"}>
          <p>Finish Stripe onboarding to see storefront.</p>
          <EuiButton
            isLoading={createAccount.isLoading}
            color="warning"
            onClick={() => createAccount.mutate(server.id)}
          >
            Finish Setup
          </EuiButton>
        </EuiCallOut>
      </EuiFlexItem>
    );
  } else if (
    server.integrationStatus === IntegrationStatus.MissingVerification
  ) {
    return (
      <EuiFlexItem grow={false}>
        <EuiCallOut title={"Stripe Account Setup Incomplete"} color={"warning"}>
          <p>Stripe needs additional verification documents.</p>
          <EuiButton
            isLoading={createAccount.isLoading}
            color="warning"
            onClick={() => createAccount.mutate(server.id)}
          >
            Finish Setup
          </EuiButton>
        </EuiCallOut>
      </EuiFlexItem>
    );
  }
  return null;
};
