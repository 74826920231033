import axios, { AxiosError } from "axios";

import {
  QueryObserverOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";

import { api } from "./api";

interface WooApiParams {
  serverId: string;
  domain: "products" | "webhooks";
  subDomain?: "subscriptions";
}

export const FetchWooApi = async (params: WooApiParams) => {
  const resp = await api.get<any>(`/woo-api`, { params });
  return resp.data;
};

export const useWooApi = <Response>(
  params: WooApiParams,
  options?: QueryObserverOptions<Response, Error>
) =>
  useQuery<Response, Error>(["woo-api", params], async () => FetchWooApi(params), {
    enabled: params.serverId !== "",
    ...options,
  });

export const TestWooConnection = async (siteUrl: string) => {
  const resp = await axios.get(`${siteUrl}/wp-json/autorole/v1/testInstall`);
  return resp.data;
};

export const useTestWooConnection = <Response>() => {
  return useMutation<any, Error | AxiosError, any>(async (siteUrl: string) =>
    TestWooConnection(siteUrl)
  );
};
