import axios from "axios";

import { Toast } from "@elastic/eui/src/components/toast/global_toast_list";
import {
  QueryObserverOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import {
  DemoteUserToDefault,
  ModifySubscriptionStatus,
  SubscriptionRerun,
  SubscriptionState,
  SubscriptionStatus,
} from "../../@types/subscription";
import { addToast } from "../../components/toast";

import { api } from "./api";

export const subscriptionKeys = {
  all: (serverId: string) => [{ scope: "subscriptions", serverId }],
  one: (serverId: string, email: string, sourceId: string) => [
    { scope: "subscription", serverId, email, sourceId },
  ],
  names: (serverId: string) => [{ scope: "subscriptionNames", serverId }],
};
export const fetchSubscriptions = async (
  serverId: string,
  email?: string,
  sourceId?: string
) => {
  const resp = await api.get<SubscriptionState[]>("/subscriptions", {
    params: { serverId, email, sourceId },
  });
  return resp.data;
};

export const useSubscriptions = (
  serverId: string,
  email?: string,
  sourceId?: string,
  options?: QueryObserverOptions<SubscriptionState[], Error>
) =>
  useQuery<SubscriptionState[], Error>(
    email && sourceId
      ? subscriptionKeys.one(serverId, email, sourceId)
      : subscriptionKeys.all(serverId),
    async () => fetchSubscriptions(serverId, email, sourceId),
    {
      onError: (error) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 404) {
            addToast({
              id: "fetch-subscriptions-failure",
              color: "danger",
              title: "No Subscriptions Found",
            });
            return;
          }
          return;
        } else {
          throw error;
        }
      },
      ...options,
    }
  );

const subscriptionStatusKeys = {
  all: [{ scope: "subscriptionStatus" }] as const,
  bySourceId: (serverId: string, sourceId: string) => [
    { ...subscriptionStatusKeys.all[0], serverId, sourceId },
  ],
};
export const fetchSubscriptionStatus = async (
  serverId: string,
  sourceId: string
) => {
  const resp = await api.get<SubscriptionStatus>("/subscriptions/verify", {
    params: { serverId, sourceId },
  });
  return resp.data;
};

export const useSubscriptionStatus = (
  serverId: string,
  sourceId: string,
  options?: QueryObserverOptions<SubscriptionStatus, Error>
) =>
  useQuery<SubscriptionStatus, Error>(
    subscriptionStatusKeys.bySourceId(serverId, sourceId),
    async () => fetchSubscriptionStatus(serverId, sourceId),
    options
  );

export const fetchSubscriptionNames = async (serverId: string) => {
  const resp = await api.get<string[]>("/subscriptions/names", {
    params: { serverId },
  });
  return resp.data;
};

export const useSubscriptionNames = (
  serverId: string,
  options?: QueryObserverOptions<string[], Error>
) =>
  useQuery<string[], Error>(
    subscriptionKeys.names(serverId),
    async () => fetchSubscriptionNames(serverId),
    options
  );

export const SubscriptionsRerun = async (item: SubscriptionRerun) => {
  return api.post("/subscriptions/rerun", item);
};

export const demoteUserToDefault = async (item: DemoteUserToDefault) => {
  return api.post("/subscriptions/demoteToDefault", item);
};

export const subscriptionsEnableDisable = async (
  item: ModifySubscriptionStatus
) => {
  const resp = await api.post<Toast>("/subscriptions/modify", item);
  return resp.data;
};

export const useSubscriptionEnableDisable = () => {
  const queryClient = useQueryClient();
  return useMutation<Toast, Error, any, any>(
    async (item: ModifySubscriptionStatus) => subscriptionsEnableDisable(item),
    {
      onSuccess: (data) => {
        void queryClient.invalidateQueries(subscriptionStatusKeys.all);
        addToast({
          id: "enable-disable-subscription-success",
          title: "Subscription Updated!",
          color: "success",
        });
      },
    }
  );
};
