import React from "react";

import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { DataTimePeriod } from "../../@types/data";
import { ServerState } from "../../@types/server";
import { useGrossRevenueOverTime } from "../../features/api/data";

import { OverTimeBarChart } from "./OverTimeBarChart";

interface SubscriptionOverTimeChartProps {
  server: ServerState;
  timePeriod: DataTimePeriod;
}

export const SubscriptionSalesOverTime: React.FC<
  SubscriptionOverTimeChartProps
> = ({ server, timePeriod }) => {
  const grossRevenueOverTime = useGrossRevenueOverTime(server.id, timePeriod);

  return (
    <EuiFlexGroup direction={"column"}>
      <EuiFlexItem>
        <OverTimeBarChart
          title={"Gross Sales"}
          data={grossRevenueOverTime.data}
          isLoading={grossRevenueOverTime.isLoading}
          xAccessor={"formattedInterval"}
          yAccessors={["totalRevenue"]}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
