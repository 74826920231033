import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useImmer } from "use-immer";

import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@elastic/eui";
import { css } from "@emotion/react";

import { ServerState } from "../../@types/server";
import { s3Upload } from "../../features/api/uploads";
import { isDialogOpen, setModal } from "../../features/redux/appState";

import { ImageUpload } from "./ImageUpload";

interface ImageUploadModalProps {
  id: string;
  initialImgSrc?: string;
  server: ServerState;
  aspectRatio?: number;
  initialAspectRatio?: number;
  onComplete: (location: string) => void;
}

export const ImageUploadModal: FC<ImageUploadModalProps> = ({
  id,
  server,
  initialImgSrc,
  onComplete,
  initialAspectRatio,
  aspectRatio,
}) => {
  const isOpen = useSelector(isDialogOpen("imageUpload"));
  const [isUploading, setIsUploading] = useImmer<boolean>(false);
  const [triggerSave, setTriggerSave] = useImmer<boolean | undefined>(
    undefined
  );
  const [completedCrop, setCompletedCrop] = useImmer<
    { fileName: string; uri: string } | undefined
  >(undefined);

  const dispatch = useDispatch();

  const closeModal = () => {
    setTriggerSave(undefined);
    dispatch(setModal({ modal: "imageUpload", open: false }));
  };

  const onSaveClick = () => {
    if (triggerSave === undefined) {
      setTriggerSave(true);
    } else {
      setTriggerSave((draft) => !draft);
    }
  };

  const dataURItoBlob = (dataURI: string) => {
    const binary = atob(dataURI.split(",")[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };

  useEffect(() => {
    const upload = async () => {
      if (triggerSave !== undefined && completedCrop) {
        setIsUploading(true);
        const blob = dataURItoBlob(completedCrop.uri);
        await s3Upload(
          server,
          blob,
          "images",
          completedCrop.fileName,
          onComplete
        );
        setIsUploading(false);
      }
    };
    void upload();
  }, [completedCrop, triggerSave]);
  let modal;

  if (isOpen) {
    modal = (
      <EuiModal onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Choose Image </h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody
          css={css`
            min-height: 100px;
          `}
        >
          <ImageUpload
            id={id}
            initialImgSrc={initialImgSrc}
            triggerSave={triggerSave}
            initialAspectRatio={initialAspectRatio}
            aspectRatio={aspectRatio}
            completedCrop={completedCrop}
            setCompletedCrop={setCompletedCrop}
            setIsLoading={setIsUploading}
          />
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButtonEmpty disabled={isUploading} onClick={closeModal}>
            Cancel
          </EuiButtonEmpty>
          <EuiButton
            color={"accent"}
            disabled={isUploading}
            onClick={onSaveClick}
          >
            Save
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  return <div>{modal}</div>;
};
