import React, { FC } from "react";

import { EuiImage } from "@elastic/eui";
import { EuiImageProps } from "@elastic/eui/src/components/image/image_types";
import { css } from "@emotion/react";

import Placeholder from "../images/image-placeholder.svg";

type ArImageProps = { src: string } & EuiImageProps;

export const ArImage: FC<ArImageProps> = (props) => {
  const { src, alt, ...rest } = props;
  return (
    <EuiImage
      css={css`
        border: 1px solid #343741;
        border-radius: 6px;
        max-width: 150px;
      `}
      size={"s"}
      {...rest}
      alt={alt}
      src={src || Placeholder}
    />
  );
};
