import moment from "moment";
import { v4 } from "uuid";

import { ArPromotionCode } from "../../@types/coupon";
import { ArProductLinkPayload, ArProductState } from "../../@types/product";

export const initialStoreProduct = (serverId: string): ArProductState => {
  const id = v4();
  return {
    id: id,
    productId: "",
    name: "",
    description: "",
    price: 99,
    compareAtPrice: undefined,
    imageUrl: "",
    serverId: serverId,
    subscriptionInterval: 1,
    subscriptionIntervalUnit: "month",
  };
};

export const initialCoupon = (
  serverId: string,
  currency: string
): ArPromotionCode => {
  const id = v4();
  const oneMonth = moment().add(1, "M").utc().unix();
  return {
    id: "",
    active: true,
    code: "",
    coupon: {
      id: "",
      name: "",
      currency: currency,
      amountOff: undefined,
      percentOff: undefined,
      duration: "forever",
      durationInMonths: undefined,
      appliesTo: undefined,
      maxRedemptions: undefined,
      redeemBy: oneMonth,
    },
    customer: undefined,
    expiresAt: oneMonth,
    maxRedemptions: undefined,
    restrictions: {
      firstTimeTransaction: false,
      minimumAmount: undefined,
      minimumAmountCurrency: undefined,
    },
  };
};

export const initialArProductRoleLinks = (
  serverId: string,
  productId?: string
): ArProductLinkPayload => {
  return {
    serverId,
    productId,
    links: [],
  };
};
