import React, {FC} from "react";

import {EuiFlexGroup, EuiFlexItem, EuiTitle} from "@elastic/eui";
import {css} from "@emotion/react";

import {ArPage} from "../../arComponents/ArPage";
import {ArPanel} from "../../arComponents/ArPanel";

import {ArBenefitsPanel} from "./ArBenefitsPanel";
import {WooBenefitsPanel} from "./WooBenefitsPanel";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SignupBaseRouteProps {}

export const ChooseStoreType: FC<SignupBaseRouteProps> = () => {
  return (
    <ArPage
      isLoading={false}
      body={
        <ArPanel paddingSize={"l"}>
          <EuiFlexGroup direction={"column"} alignItems={"center"}>
            <EuiFlexItem>
              <EuiTitle size={"m"}>
                <h2>Choose Store Type</h2>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem
              css={css`
                width: 100%;
              `}
            >
              <EuiFlexGroup direction={"row"}>
                <EuiFlexItem>
                  <ArBenefitsPanel />
                </EuiFlexItem>
                <EuiFlexItem>
                  <WooBenefitsPanel />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </ArPanel>
      }
      restrictWidth={true}
    />
  );
};
