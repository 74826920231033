import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  EuiBadge,
  EuiHeader,
  EuiHeaderLink,
  EuiHeaderLinks,
  EuiHeaderSection,
  EuiHeaderSectionItem,
} from "@elastic/eui";
import { useQueryClient } from "@tanstack/react-query";

import { useLogout, useUser } from "../../features/api/auth";
import { clearSubscribeForm } from "../../features/redux/subscribeForm";
import logo from "../../images/logo.svg";
import { ROUTES } from "../app/app";
import { HelpIcon } from "../icon/HelpIcon";

export const Header = () => {
  const user = useUser();
  const logout = useLogout();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return (
    <EuiHeader className={"ar-panel ar-header"}>
      <EuiHeaderSection grow={false}>
        <EuiHeaderSectionItem>
          <EuiHeaderLink
            onClick={() => {
              queryClient.removeQueries(["server"]);
              queryClient.removeQueries(["subscription"]);
              dispatch(clearSubscribeForm());
              navigate(ROUTES.INDEX);
            }}
          >
            <img
              alt={"logo"}
              src={logo}
              style={{ height: "25px", marginTop: "5px" }}
            />
          </EuiHeaderLink>
        </EuiHeaderSectionItem>
        {user.isSuccess && user.data.scopes && user.data.scopes.length > 0 ? (
          <EuiHeaderSectionItem>
            <EuiHeaderLinks>
              {user.data.scopes.map((scope) => {
                return (
                  <EuiHeaderLink
                    isActive={window.location.href.includes(scope.alias)}
                    key={scope.serverId}
                    onClick={() =>
                      navigate(`${ROUTES.SERVER_MANAGE}/account/${scope.alias}`)
                    }
                  >
                    {scope.name}
                  </EuiHeaderLink>
                );
              })}
            </EuiHeaderLinks>
          </EuiHeaderSectionItem>
        ) : null}
      </EuiHeaderSection>
      <EuiHeaderSection>
        <EuiHeaderSectionItem>
          <EuiHeaderLinks>
            <EuiHeaderLink>
              <EuiBadge
                color={"#62DEA7"}
                iconType={"plus"}
                href={ROUTES.SIGN_UP}
              >
                Join Now
              </EuiBadge>
            </EuiHeaderLink>
            {user.isSuccess && (
              <>
                <EuiHeaderLink>{user.data.username}</EuiHeaderLink>
                <EuiHeaderLink onClick={() => logout.mutate()}>
                  Logout
                </EuiHeaderLink>
              </>
            )}
            <HelpIcon />
          </EuiHeaderLinks>
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </EuiHeader>
  );
};
