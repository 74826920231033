import React from "react";

import { EuiHorizontalRule } from "@elastic/eui";
import { EuiHorizontalRuleProps } from "@elastic/eui/src/components/horizontal_rule/horizontal_rule";

// eslint-disable-next-line @typescript-eslint/ban-types
interface ArHorizontalRuleProps extends EuiHorizontalRuleProps {
  threeQuarter?: boolean;
}

export const ArHorizontalRule: React.FC<ArHorizontalRuleProps> = (props) => {
  const { threeQuarter, ...rest } = props;
  if (threeQuarter) {
    return (
      <EuiHorizontalRule
        size={"quarter"}
        className={"ar-three-quarter-hr"}
        {...rest}
      />
    );
  }
  return <EuiHorizontalRule {...props} />;
};
