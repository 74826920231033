import {FLUSH, PAUSE, PERSIST, persistCombineReducers, persistStore, PURGE, REGISTER, REHYDRATE,} from "redux-persist";
import {PersistConfig} from "redux-persist/es/types";
import storage from "redux-persist/lib/storage";

import {configureStore, Store} from "@reduxjs/toolkit";

// import { userReducer } from "./user";
import {AppState} from "../../@types";

import {appStateReducer} from "./appState";
// import { serverReducer } from "./server";
import {subscribeFormReducer} from "./subscribeForm";
// import { subscriptionReducer } from "./subscription";

const persistConfig: PersistConfig<AppState> = {
  key: "root",
  storage,
  whitelist: ["user", "subscribeForm", "subscription"],
};

// Create the root reducer
const reducer = persistCombineReducers<AppState>(persistConfig, {
  appState: appStateReducer,
  subscribeForm: subscribeFormReducer,
});

export const store: Store<AppState, any> = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: true,
});

export const persistor = persistStore(store);
