import React from "react";

import { Axis, Chart, LineSeries, Settings } from "@elastic/charts";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingChart,
  EuiTitle,
} from "@elastic/eui";
import { EUI_CHARTS_THEME_DARK } from "@elastic/eui/dist/eui_charts_theme";
import { css } from "@emotion/react";

import { OverTimeChartProps } from "../../@types/data";

export const OverTimeLineChart: React.FC<OverTimeChartProps> = ({
  title,
  data,
  xAccessor,
  yAccessors,
  xAxisTitle,
  yAxisTitle,
  showGridLines,
  isLoading,
  legendPosition,
  settingsProps,
  height,
}) => {
  if (isLoading || !data) {
    return (
      <EuiFlexGroup
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <EuiFlexItem
          className={"center-mobile"}
          css={css`
            min-height: ${height || 300}px;
            justify-content: center;
          `}
        >
          <EuiLoadingChart size="xl" />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
  return (
    <EuiFlexGroup direction={"column"} gutterSize={"s"}>
      <EuiFlexItem>
        <EuiTitle size={"xs"}>
          <h1>{title}</h1>
        </EuiTitle>
      </EuiFlexItem>
      <EuiFlexItem>
        {/*// @ts-ignore*/}
        <Chart size={{ height: height || 300 }}>
          <Settings
            theme={EUI_CHARTS_THEME_DARK.theme}
            showLegend={data.length > 1}
            legendPosition={legendPosition || "right"}
            {...settingsProps}
          />
          {data!.map((item) => {
            return (
              <LineSeries
                key={item.title}
                id={item.title}
                name={item.title}
                data={item.values}
                xScaleType="time"
                xAccessor={xAccessor}
                yAccessors={yAccessors}
              />
            );
          })}
          <Axis
            title={yAxisTitle}
            id="bottom-axis"
            position="bottom"
            showGridLines={showGridLines || true}
            // tickFormat={dateFormatter}
          />
          <Axis
            title={xAxisTitle}
            id="left-axis"
            position="left"
            showGridLines={showGridLines || true}
            tickFormat={(d) => Number(d).toFixed(2)}
          />
        </Chart>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
