import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { ArSignupRoot } from "./ArSignupRoot";
import { WooSignupRoot } from "./WooSignupRoot";

export const SignupRoot: FC = () => {
  const { type } = useParams<{ type: "woo" | "autorole" }>();

  if (type === "woo") {
    return <WooSignupRoot />;
  } else if (type === "autorole") {
    return <ArSignupRoot />;
  }
  return null;
};
