export const toNumberString = (value: string | number) => {
  if (!value) {
    return "N/A";
  }
  if (typeof value === "string") {
    return parseFloat(value).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }
  return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
};
