import axios from "axios";
import { FormikProps, withFormik } from "formik";
import React, { Dispatch, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import {
  EuiButton,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiLoadingContent,
} from "@elastic/eui";
import { UseMutationResult } from "@tanstack/react-query";

import { ServerState } from "../../../@types/server";
import { SubscriptionState } from "../../../@types/subscription";
import { addToast } from "../../../components/toast";
import { useDiscordLogin } from "../../../features/api/auth";
import { fetchSubscriptions } from "../../../features/api/subscriptions";
import {
  getSubscribeForm,
  setSubscribeFormEmail,
  setSubscribeFormSourceId,
} from "../../../features/redux/subscribeForm";

interface ServerLoginPanelProps {
  server: ServerState;
  subscription: SubscriptionState | undefined;
  children?: ReactNode;
  setSubscription: Dispatch<
    React.SetStateAction<SubscriptionState | undefined>
  >;
}

interface FormValues {
  email: string;
  sourceId: string;
}

interface OuterProps {
  server: ServerState;
  token: string | undefined;
  saveFormData: (email: string, sourceId: string) => void;
  subscription: SubscriptionState | undefined;
  initialValues: Partial<FormValues>;
  discordLogin: UseMutationResult<
    any,
    unknown,
    { email: string; sourceId: string; path: string },
    unknown
  >;
  onNextButtonClick: (email: string, sourceId: string) => void;
}

const BaseForm = (props: OuterProps & FormikProps<FormValues>) => {
  const {
    server,
    token,
    subscription,
    saveFormData,
    discordLogin,
    onNextButtonClick,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    isSubmitting,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <EuiForm>
        <EuiFlexGroup direction={"column"} justifyContent={"spaceBetween"}>
          <EuiFlexItem grow={10}>
            <EuiFormRow
              label={`Email Registered on ${server.companyName}`}
              isInvalid={(isSubmitting || touched.email) && !!errors.email}
              error={errors.email}
              helpText={
                <>
                  Not registered?{" "}
                  <a
                    href={server.purchaseUrl}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    Sign up here.
                  </a>
                </>
              }
            >
              <EuiFieldText
                fullWidth={true}
                name={"email"}
                value={values.email}
                required={touched.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isLoading={isSubmitting}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow
              label={"Subscription Number"}
              isInvalid={
                (isSubmitting || touched.sourceId) && !!errors.sourceId
              }
              error={errors.sourceId}
              helpText={
                <a href={server.orderIdUrl} target={"_blank"} rel="noreferrer">
                  What's my Subscription number?
                </a>
              }
            >
              <EuiFieldText
                name={"sourceId"}
                value={values.sourceId}
                required={touched.sourceId}
                onChange={handleChange}
                onBlur={handleBlur}
                isLoading={isSubmitting}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                {subscription ? (
                  <EuiButton
                    isLoading={isSubmitting}
                    type={"submit"}
                    onClick={() => handleSubmit()}
                  >
                    Next
                  </EuiButton>
                ) : (
                  <EuiButton
                    isLoading={isSubmitting}
                    type={"submit"}
                    onClick={() => handleSubmit()}
                    color={"success"}
                  >
                    {token ? "Next" : "Login with Discord"}
                  </EuiButton>
                )}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
    </form>
  );
};

const FormikForm = withFormik<OuterProps, FormValues>({
  mapPropsToValues: (props) => ({
    email: props.initialValues.email || "",
    sourceId: props.initialValues.sourceId || "",
  }),

  enableReinitialize: true,
  validationSchema: Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    sourceId: Yup.string().required("Subscription Number is required"),
  }),

  handleSubmit(
    { email, sourceId }: FormValues,
    { props, setSubmitting, setErrors }
  ) {
    if (props.subscription) {
      props.onNextButtonClick(email, sourceId);
      setSubmitting(false);
    } else {
      if (props.token) {
        props.onNextButtonClick(email, sourceId);
        setSubmitting(false);
      } else {
        props.saveFormData(email, sourceId);
        setSubmitting(true);
        props.discordLogin.mutate(
          {
            email,
            sourceId,
            path: window.location.href,
          },
          {
            onSettled: (data) => {
              setSubmitting(false);
            },
          }
        );
      }
    }
  },
})(BaseForm);

export const FindPurchasePanel: React.FC<ServerLoginPanelProps> = ({
  server,
  subscription,
  setSubscription,
}) => {
  const token = localStorage.getItem("token");
  const subscribeForm = useSelector(getSubscribeForm);
  const dispatch = useDispatch();
  const discordLogin = useDiscordLogin();

  const saveFormData = (email: string, sourceId: string) => {
    dispatch(setSubscribeFormEmail(email));
    dispatch(setSubscribeFormSourceId(sourceId));
  };

  const onNextButtonClick = async (email: string, sourceId: string) => {
    if (email && sourceId) {
      let findSubscriptionResp;

      saveFormData(email, sourceId);

      try {
        findSubscriptionResp = await fetchSubscriptions(
          server.id,
          email,
          sourceId
        );
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 404) {
            addToast({
              id: "no-subscriptions-found",
              color: "danger",
              title: "No Subscriptions Found",
            });
          }
        } else {
          throw error;
        }
      }

      if (findSubscriptionResp) {
        if (Array.isArray(findSubscriptionResp)) {
          setSubscription(findSubscriptionResp[0]);
        } else {
          setSubscription(findSubscriptionResp);
        }
        return;
      }
    }
  };

  if (server) {
    return (
      <FormikForm
        server={server}
        subscription={subscription}
        initialValues={{
          email: subscribeForm.email || "",
          sourceId: subscribeForm.sourceId || "",
        }}
        token={token || undefined}
        discordLogin={discordLogin}
        saveFormData={saveFormData}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onNextButtonClick={onNextButtonClick}
      />
    );
  } else {
    return (
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiLoadingContent lines={6} />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};
