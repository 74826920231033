import React from "react";

import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { ServerState } from "../../../@types/server";
import { ManageMembersPanel } from "../panels/manageMembers/ManageMembersPanel";

interface ManageServerManageMembersSubPageProps {
  server: ServerState;
}

export const ManageServerManageMembersSubPage: React.FC<
  ManageServerManageMembersSubPageProps
> = ({ server }) => {
  return (
    <EuiFlexGroup gutterSize={"m"} direction={"column"}>
      <EuiFlexItem>
        <ManageMembersPanel server={server} />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
