import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSteps,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import { EuiContainedStepProps } from "@elastic/eui/src/components/steps/steps";

import { ArPage } from "../../arComponents/ArPage";
import { ArPanel } from "../../arComponents/ArPanel";
import { ROUTES } from "../../components/app/app";
import { Header } from "../../components/header/Header";
import { useDiscordBotLogin, useUser } from "../../features/api/auth";
import { useServerOwner } from "../../features/api/server-owners";
import {
  IntegrationStatus,
  useAcceptBilling,
} from "../../features/api/servers";
import { useServerIdFromUrl } from "../../features/hooks/hooks";
import { WooConnectToSiteForm } from "../manage/panels/woocommerceStore/WooConnectToSiteForm";

import { ServerOwnerForm } from "./forms/ServerOwnerForm";

export const WooSignupRoot: FC = () => {
  const server = useServerIdFromUrl();
  const user = useUser();
  const navigate = useNavigate();

  const serverOwner = useServerOwner(server.data);
  const acceptBilling = useAcceptBilling();
  const botAuth = useDiscordBotLogin();

  const steps: EuiContainedStepProps[] = [
    {
      title: "Add Autorole to Your Server",
      children: (
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            {!server.data ||
            (server.data &&
              server.data.integrationStatus <
                IntegrationStatus.ServerSelected) ? (
              <EuiButton
                isLoading={botAuth.isLoading}
                onClick={() => botAuth.mutate({ path: window.location.href })}
              >
                Choose Server
              </EuiButton>
            ) : (
              <EuiText color={"success"}>
                Connected to <b>{server.data.companyName}!</b>
              </EuiText>
            )}
          </EuiFlexItem>
        </EuiFlexGroup>
      ),
    },
    {
      title: "Connect Your Store",
      children: (
        <EuiFlexGroup direction={"column"}>
          <EuiFlexItem>
            <EuiText
              color={
                !server.data ||
                (server.data &&
                  server.data.integrationStatus <
                    IntegrationStatus.ServerSelected)
                  ? "subdued"
                  : undefined
              }
            >
              WooCommerce sites are currently supported.
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <WooConnectToSiteForm
              server={server.data}
              disabled={
                !server.data ||
                (server.data &&
                  server.data.integrationStatus <
                    IntegrationStatus.ServerSelected)
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      ),
    },
    {
      title: "Your Information",
      children: user.isSuccess ? (
        <ServerOwnerForm
          disabled={
            !server.data ||
            (server.data &&
              server.data.integrationStatus < IntegrationStatus.PluginInstalled)
          }
          serverOwner={serverOwner.data}
          user={user.data}
          server={server.data}
        />
      ) : (
        <EuiText color={"subdued"}>Choose a server to see this step.</EuiText>
      ),
    },
    {
      title: "Billing",
      children: (
        <>
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiText
                color={
                  !server.data ||
                  (server.data &&
                    server.data.integrationStatus <
                      IntegrationStatus.OwnerInformationSaved)
                    ? "subdued"
                    : undefined
                }
              >
                Autorole charges a <strong>4%</strong> transaction fee. You will
                be sent an invoice each month.
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton
                onClick={() =>
                  acceptBilling.mutate(server.data!.id, {
                    onSuccess: (data) => {
                      navigate(
                        `${ROUTES.SERVER_MANAGE}/account/${server.data!.alias}`
                      );
                    },
                  })
                }
                isLoading={acceptBilling.isLoading}
                fullWidth={false}
                color={"success"}
                disabled={
                  !server.data ||
                  (server.data &&
                    server.data.integrationStatus <
                      IntegrationStatus.OwnerInformationSaved)
                }
              >
                Accept and Continue
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </>
      ),
    },
  ];

  return (
    <ArPage
      isLoading={false}
      header={<Header />}
      body={
        <EuiFlexGroup justifyContent={"flexStart"} direction={"column"}>
          <EuiFlexItem>
            <ArPanel>
              <EuiFlexGroup direction={"column"}>
                <EuiFlexItem>
                  <EuiTitle size={"m"}>
                    <h2>Add Your Server</h2>
                  </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiSteps headingElement="h2" steps={steps} />
                </EuiFlexItem>
              </EuiFlexGroup>
            </ArPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      }
      restrictWidth={true}
    />
  );
};
