import React, { FC, ReactNode } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiIcon } from "@elastic/eui";
import { IconType } from "@elastic/eui/src/components/icon/icon";
import { css } from "@emotion/react";

type ArImageProps = {
  icon: IconType;
  description: ReactNode;
};

export const ArIconDescription: FC<ArImageProps> = ({ icon, description }) => {
  return (
    <EuiFlexGroup responsive={false}>
      <EuiFlexItem
        grow={false}
        css={css`
          justify-content: center;
        `}
      >
        <EuiIcon type={icon} size={"l"} color={"primary"} />
      </EuiFlexItem>
      <EuiFlexItem>{description}</EuiFlexItem>
    </EuiFlexGroup>
  );
};
