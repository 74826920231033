import { useEffect } from "react";
import { useImmer } from "use-immer";

import { EuiSuperSelect, EuiText } from "@elastic/eui";
import { EuiSuperSelectOption } from "@elastic/eui/src/components/form/super_select";

import { DiscordGuildChannel } from "../../@types/discord";
import { useDiscordApi } from "../../features/api/discord-server-api";
import { useServerFromUrl } from "../../features/hooks/hooks";

interface SelectGuildChannelProps {
  value: string;
  name: string;
  isLoading: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const SelectGuildChannel: React.FC<SelectGuildChannelProps> = ({
  value,
  name,
  setFieldValue,
  isLoading,
}) => {
  const server = useServerFromUrl();
  const [options, setOptions] = useImmer<EuiSuperSelectOption<string>[]>([]);

  const serverId = server.data ? server.data.id : undefined;
  const discordChannels = useDiscordApi<DiscordGuildChannel[]>(
    serverId!,
    "guilds",
    "channels",
    { enabled: !!serverId }
  );

  const onSelectionChange = (value: string) => {
    setFieldValue(name, value);
  };
  useEffect(() => {
    if (discordChannels.data) {
      const optionsFiltered = discordChannels.data.filter((o) => o.type === 0);
      setOptions(
        optionsFiltered.map((o) => {
          return {
            value: o.id,
            inputDisplay: o.name,
            dropdownDisplay: (
              <>
                <strong>{o.name}</strong>
                <EuiText size="s" color="subdued">
                  <p className="euiTextColor--subdued">{o.id}</p>
                </EuiText>
              </>
            ),
          };
        })
      );
    }
  }, [discordChannels.data]);
  return (
    <EuiSuperSelect
      options={options}
      valueOfSelected={value}
      onChange={(e) => onSelectionChange(e)}
      hasDividers={true}
      disabled={isLoading}
    />
  );
};
