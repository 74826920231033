import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { useImmer } from "use-immer";

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiLoadingContent,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import { css } from "@emotion/react";

import { ServerState } from "../../../@types/server";
import { SubscribeFormState } from "../../../@types/subscribeForm";
import {
  ModifySubscriptionStatus,
  SubscriptionState,
  SubscriptionStatus,
} from "../../../@types/subscription";
import { UserState } from "../../../@types/user";
import { ArPanel } from "../../../arComponents/ArPanel";
import { useUser } from "../../../features/api/auth";
import {
  useSubscriptionEnableDisable,
  useSubscriptionStatus,
} from "../../../features/api/subscriptions";
import { ACTIVE, statusPrettier } from "../../../features/constants";
import { getSubscribeForm } from "../../../features/redux/subscribeForm";

interface ManagePanelProps {
  server: ServerState;
  subscription: SubscriptionState;
  children?: ReactNode;
}

interface ActivateButtonProps {
  subscribeForm: SubscribeFormState;
  server: ServerState;
  subscription: SubscriptionState;
  user: UserState;
  subscriptionUseStatus: SubscriptionStatus;
}

const ActivateButton: React.FC<ActivateButtonProps> = ({
  subscribeForm,
  subscriptionUseStatus,
  subscription,
  server,
  user,
}) => {
  const subscriptionEnableDisable = useSubscriptionEnableDisable();
  const [isDisabled, setIsDisabled] = useImmer<boolean>(false);

  const onEnableDisableClick = (
    sourceId: string,
    action: "enable" | "disable"
  ) => {
    if (server && subscribeForm.init) {
      setIsDisabled(true);
      const item: ModifySubscriptionStatus = {
        serverId: server.id,
        sourceId,
        email: subscribeForm.email,
        action: action,
      };
      subscriptionEnableDisable.mutate(item, {
        onSettled: (data) => {
          setIsDisabled(false);
        },
      });
    }
  };

  if (subscriptionUseStatus === "LOADING") {
    return <EuiButton disabled={true}>Loading...</EuiButton>;
  } else if (subscriptionUseStatus === "OTHER") {
    return (
      <EuiText>
        Subscription in use by another Discord account. Unlink there to use
        here.
      </EuiText>
    );
  } else if (subscriptionUseStatus === "NO" && subscription) {
    return (
      <EuiButton
        isLoading={isDisabled}
        color={"success"}
        onClick={() => onEnableDisableClick(subscription.sourceId, "enable")}
      >
        Link Account
      </EuiButton>
    );
  } else if (
    subscriptionUseStatus === "ME" &&
    subscription.sourceId !== undefined
  ) {
    return (
      <EuiButton
        isLoading={isDisabled}
        color={"warning"}
        onClick={() => {
          onEnableDisableClick(subscription.sourceId, "disable");
        }}
      >
        Unlink Account
      </EuiButton>
    );
  } else {
    return <EuiButton isLoading={true}>Loading...</EuiButton>;
  }
};

// interface ServerStatusItemProps {
//   status: "OK" | "ERROR";
//   text: string;
// }

// const ServerStatusItem: React.FC<ServerStatusItemProps> = ({
//   status,
//   text,
// }) => {
//   return (
//     <EuiFlexGroup alignItems={"center"} gutterSize={"s"} responsive={false}>
//       <EuiFlexItem grow={false}>
//         {status === "OK" ? (
//           <EuiIcon type={"check"} color={"success"} />
//         ) : (
//           <EuiIcon type={"cross"} color={"danger"} />
//         )}
//       </EuiFlexItem>
//       <EuiFlexItem grow={false}>
//         <EuiText>{text}</EuiText>
//       </EuiFlexItem>
//     </EuiFlexGroup>
//   );
// };

export const ManageSubscriptionPanel: React.FC<ManagePanelProps> = ({
  server,
  subscription,
  children,
}) => {
  const user = useUser();
  const subscribeForm = useSelector(getSubscribeForm);

  const subscriptionStatus = useSubscriptionStatus(
    server.id,
    subscribeForm.sourceId,
    {
      enabled:
        (subscribeForm.init &&
          subscription.email !== "" &&
          subscribeForm.sourceId !== "") ||
        false,
    }
  );

  if (server) {
    return (
      <EuiFlexGroup direction={"column"}>
        <EuiFlexItem>
          <ArPanel hasShadow={false} hasBorder={true}>
            {subscription && subscriptionStatus.data && user.isSuccess ? (
              <EuiFlexGroup
                direction={"column"}
                alignItems={"center"}
                justifyContent={"spaceAround"}
              >
                <EuiFlexItem grow={false}>
                  <EuiText color={"accent"} size={"xs"} textAlign={"center"}>
                    Link your Discord Account to activate your purchase
                  </EuiText>
                </EuiFlexItem>
                <EuiFlexItem
                  css={css`
                    align-items: center;
                  `}
                >
                  <EuiTitle size={"m"}>
                    <h1>
                      <b>{subscription.name}</b>
                    </h1>
                  </EuiTitle>
                  <EuiHealth
                    color={
                      ACTIVE.includes(subscription.status)
                        ? "success"
                        : "danger"
                    }
                  >
                    {statusPrettier(subscription.status) === "Active"
                      ? "Active"
                      : "Inactive"}
                  </EuiHealth>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <ActivateButton
                    server={server}
                    subscribeForm={subscribeForm}
                    subscription={subscription}
                    user={user.data}
                    subscriptionUseStatus={subscriptionStatus.data}
                  />
                </EuiFlexItem>
                {/*{subscriptionUseStatus === "ME" ? (*/}
                {/*  <EuiFlexItem>*/}
                {/*    {activationStatus && activationStatus.joinServer ? (*/}
                {/*      <>*/}
                {/*        <ServerStatusItem*/}
                {/*          status={activationStatus.joinServer.status}*/}
                {/*          text={activationStatus.joinServer.text}*/}
                {/*        />*/}
                {/*        <ServerStatusItem*/}
                {/*          status={activationStatus.addRole.status}*/}
                {/*          text={activationStatus.addRole.text}*/}
                {/*        />*/}
                {/*      </>*/}
                {/*    ) : (*/}
                {/*      activationStatus && (*/}
                {/*        <EuiText>{activationStatus.text}</EuiText>*/}
                {/*      )*/}
                {/*    )}*/}
                {/*  </EuiFlexItem>*/}
                {/*) : null}*/}
              </EuiFlexGroup>
            ) : (
              <EuiFlexGroup alignItems={"center"}>
                <EuiFlexItem>
                  <EuiLoadingContent lines={5} />
                </EuiFlexItem>
              </EuiFlexGroup>
            )}
          </ArPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  } else {
    return (
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiLoadingContent lines={6} />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};
