import React, { ReactNode } from "react";

import {
  EuiBottomBar,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
} from "@elastic/eui";
import { SerializedStyles } from "@emotion/react";

import { LoadingOverlay } from "../components/loading/LoadingOverlay";

interface PageTemplateProps {
  header?: ReactNode;
  subHeader?: ReactNode;
  sidebar?: ReactNode;
  body: ReactNode;
  bodyCss?: any;
  footer?: ReactNode;
  restrictWidth: boolean;
  isLoading: boolean;
}

export const ArPage: React.FC<PageTemplateProps> = ({
  header,
  subHeader,
  sidebar,
  body,
  bodyCss,
  footer,
  restrictWidth,
  isLoading,
}) => {
  const InnerBody = () => {
    return (
      <EuiPageBody paddingSize="m">
        <EuiPageContent
          hasBorder={false}
          hasShadow={false}
          paddingSize="none"
          color="transparent"
          borderRadius="none"
        >
          {isLoading ? (
            <LoadingOverlay />
          ) : (
            <>
              <EuiPageContentBody restrictWidth={restrictWidth}>
                {header ? header : null}
              </EuiPageContentBody>
              <EuiPageContentBody restrictWidth={restrictWidth}>
                {subHeader}
              </EuiPageContentBody>
              <EuiPageContentBody restrictWidth={restrictWidth} css={bodyCss}>
                {body}
              </EuiPageContentBody>
              {footer ? (
                <EuiBottomBar paddingSize="s" affordForDisplacement={false}>
                  {/* Wrapping the contents with EuiPageContentBody allows us to match the restrictWidth to keep the contents aligned */}
                  <EuiPageContentBody
                    paddingSize={"none"}
                    restrictWidth={restrictWidth}
                  >
                    {footer}
                  </EuiPageContentBody>
                </EuiBottomBar>
              ) : null}
            </>
          )}
        </EuiPageContent>
      </EuiPageBody>
    );
  };
  return (
    <>
      <div>
        <EuiPage paddingSize={"none"} grow={true} className={"ar-page"}>
          {sidebar}
          {/* Double EuiPageBody to accommodate for the bottom bar */}
          <EuiPageBody panelled={false} paddingSize="none">
            <InnerBody />
          </EuiPageBody>
        </EuiPage>
      </div>
    </>
  );
};
