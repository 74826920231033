import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { EuiBasicTable, EuiLink } from "@elastic/eui";
import { EuiBasicTableColumn } from "@elastic/eui/src/components/basic_table/basic_table";

import { ServerState } from "../../@types/server";
import { SubscriptionState } from "../../@types/subscription";
import { ACTIVE, statusPrettier } from "../../features/constants";
import { ROUTES } from "../app/app";

interface SubscriptionDetailsTableProps {
  server: ServerState;
  subscriptions: SubscriptionState[];
}

export const WooSubscriptionDetailsTable: React.FC<
  SubscriptionDetailsTableProps
> = ({ server, subscriptions }) => {
  const navigate = useNavigate();

  const onLinkUnlinkClick = (
    value: SubscriptionState,
    record: SubscriptionState
  ) => {
    if (value.email && value.sourceId) {
      const thisSubscription = subscriptions.find(
        (subscription) =>
          subscription.email === value.email &&
          subscription.sourceId === value.sourceId
      );
      if (thisSubscription) {
        navigate(
          `${ROUTES.SERVER}/${server.alias}?email=${value.email}&sourceId=${value.sourceId}`
        );
      }
    }
  };

  const columns = useMemo<EuiBasicTableColumn<SubscriptionState>[]>(() => {
    return [
      {
        field: "sourceId",
        name: "ID",
      },
      {
        field: "name",
        name: "Name",
      },
      {
        field: "status",
        name: "Status",
        render: (value: string) => {
          return ACTIVE.includes(value)
            ? "Active"
            : value === "trash"
            ? statusPrettier("cancelled")
            : statusPrettier(value);
        },
      },
      {
        field: "",
        name: "Discord Account",
        render: (value: SubscriptionState, record) => {
          return (
            <EuiLink onClick={() => onLinkUnlinkClick(value, record)}>
              {value.discordAccountLinked ? "Unlink Account" : "Link Account"}
            </EuiLink>
          );
        },
      },
      {
        field: "",
        actions: [
          {
            render: () => {
              return <EuiLink href={server.orderIdUrl}>Edit</EuiLink>;
            },
          },
        ],
      },
    ];
  }, []);

  return (
    <EuiBasicTable
      columns={columns}
      items={subscriptions}
      tableLayout={"auto"}
      hasActions={false}
    />
  );
};
