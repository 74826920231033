import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useImmer } from "use-immer";

import {
  EuiButtonEmpty,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSelect,
  EuiSwitch,
} from "@elastic/eui";
import { EuiSelectOption } from "@elastic/eui/src/components/form/select/select";
import { css } from "@emotion/react";

import { ServerState, SiteType } from "../../../@types/server";
import { CreateTestAccountModal } from "../../../components/modal/CreateTestAccountModal";
import { useUpdateServer } from "../../../features/api/servers";
import { setModal } from "../../../features/redux/appState";

interface StoreTypeSectionProps {
  server: ServerState;
}

const options: EuiSelectOption[] = [
  { value: "autorole", text: "AutoRole" },
  { value: "woo", text: "WooCommerce" },
];

export const SiteTypeSection: FC<StoreTypeSectionProps> = ({ server }) => {
  const [siteType, setSiteType] = useImmer<SiteType>(server.siteType);
  const [isTestModeLoading, setIsTestModeLoading] = useImmer<boolean>(false);

  const dispatch = useDispatch();
  const updateServer = useUpdateServer();

  const handleStoreTypeChange = () => {
    updateServer.mutate({ ...server, siteType });
  };

  const handleTestModeChange = (value: boolean) => {
    setIsTestModeLoading(true);
    updateServer.mutate(
      { ...server, testMode: value },
      {
        onSettled: () => setIsTestModeLoading(false),
      }
    );
  };

  useEffect(() => {
    if (
      server.testMode &&
      server.siteType === "autorole" &&
      !server.testStripeAccount
    ) {
      dispatch(
        setModal({
          modal: "createTestAccount",
          open: true,
        })
      );
    }
  }, [server.testMode, server.testStripeAccount]);

  return (
    <>
      <CreateTestAccountModal server={server} />
      <EuiFlexGroup direction={"column"}>
        <EuiFlexItem>
          <EuiFlexGroup
            justifyContent={"flexStart"}
            responsive={false}
            css={css`
              max-width: 496px;
            `}
          >
            <EuiFlexItem>
              <EuiFormRow label={"Store Type"}>
                <EuiSelect
                  options={options}
                  value={siteType}
                  onChange={(e) => setSiteType(e.target.value as SiteType)}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFormRow hasEmptyLabelSpace>
                <EuiButtonEmpty
                  color={"success"}
                  onClick={handleStoreTypeChange}
                >
                  Save
                </EuiButtonEmpty>
              </EuiFormRow>
            </EuiFlexItem>
            {server.siteType === "autorole" ? (
              <EuiFlexItem>
                <EuiFormRow hasEmptyLabelSpace>
                  <EuiSwitch
                    label={"Enable Test Mode"}
                    disabled={isTestModeLoading}
                    onChange={(e) => handleTestModeChange(e.target.checked)}
                    checked={server.testMode}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            ) : null}
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};
