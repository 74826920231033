import React, { useMemo } from "react";
import { useImmer } from "use-immer";

import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiInMemoryTable,
  EuiLink,
  EuiText,
  EuiTitle,
  EuiToken,
} from "@elastic/eui";
import {
  EuiTableFieldDataColumnType,
  EuiTableSortingType,
} from "@elastic/eui/src/components/basic_table/table_types";
import { css } from "@emotion/react";

import { DiscordGuildRole } from "../../@types/discord";
import { ArProductState } from "../../@types/product";
import { ServerState } from "../../@types/server";
import { ArImage } from "../../arComponents/ArImage";
import { useDiscordApi } from "../../features/api/discord-server-api";
import { useProducts, useRestoreProduct } from "../../features/api/products";
import { useProductServerRoleLinks } from "../../features/api/server-roles";
import { formatInterval } from "../../features/formatters/storeProduct";
import { useCurrencyFormatter } from "../../features/hooks/hooks";
import { Theme } from "../app/theme";

interface ArArchivedSubscriptionTableProps {
  server: ServerState;
}

export const ArArchivedProductsTable: React.FC<
  ArArchivedSubscriptionTableProps
> = ({ server }) => {
  const [isRestoring, setIsRestoring] = useImmer<Record<string, boolean>>({});
  const products = useProducts(server.id, true);

  const productServerRoleLinks = useProductServerRoleLinks(server.id);
  const currencyFormat = useCurrencyFormatter(server.id);
  const restoreProduct = useRestoreProduct(server.id);

  const discordRoles = useDiscordApi<DiscordGuildRole[]>(
    server.id,
    "guilds",
    "roles"
  );

  const handleRestore = (item: ArProductState) => {
    setIsRestoring((draft) => {
      draft[item.productId] = true;
      return draft;
    });

    restoreProduct.mutate(
      {
        serverId: server.id,
        item,
      },
      {
        onSuccess: (data) => {
          setIsRestoring((draft) => {
            if (item.productId in isRestoring) {
              delete isRestoring[item.productId];
            }
            return draft;
          });
        },
      }
    );

    setTimeout(() => {
      if (item.productId in isRestoring) {
        delete isRestoring[item.productId];
      }
    }, 30 * 1000);
  };
  const sort: EuiTableSortingType<any> = {
    enableAllColumns: true,
    allowNeutralSort: true,
    sort: {
      field: "name",
      direction: "desc",
    },
  };

  const columns = useMemo<EuiTableFieldDataColumnType<ArProductState>[]>(() => {
    if (
      products.isSuccess &&
      productServerRoleLinks.isSuccess &&
      discordRoles.isSuccess
    ) {
      return [
        {
          field: "name",
          name: "Name",
          render: (item: string, row: ArProductState) => {
            return (
              <EuiFlexGroup responsive={false}>
                <EuiFlexItem
                  grow={false}
                  css={css`
                    width: 50px;
                  `}
                >
                  <ArImage
                    size={50}
                    src={row.imageUrl}
                    alt={row.name + "logo"}
                  />{" "}
                </EuiFlexItem>
                <EuiFlexItem
                  css={css`
                    justify-content: center;
                  `}
                >
                  <span className={"euiTableCellContent"}>{item}</span>
                </EuiFlexItem>
              </EuiFlexGroup>
            );
          },
        },
        {
          field: "price",
          name: "Price",
          render: (item: number) => {
            if (currencyFormat) {
              return (
                <EuiFlexGroup gutterSize={"none"}>
                  <EuiFlexItem>
                    <EuiText size={"s"}>{currencyFormat.format(item)}</EuiText>
                  </EuiFlexItem>
                  {!item ? (
                    <EuiFlexItem
                      css={css`
                        justify-content: center;
                      `}
                    >
                      <EuiToken
                        iconType="tokenException"
                        color={`${Theme.colors!.DARK?.danger}`}
                      />
                    </EuiFlexItem>
                  ) : null}
                </EuiFlexGroup>
              );
            }
            return null;
          },
        },
        {
          field: "compareAtPrice",
          name: "Compare at Price",
          render: (item: number | undefined, row) => {
            if (row.compareAtPrice && currencyFormat) {
              return (
                <EuiFlexGroup gutterSize={"none"}>
                  <EuiFlexItem>
                    <EuiText size={"s"}>
                      {currencyFormat.format(row.compareAtPrice)}
                    </EuiText>
                  </EuiFlexItem>
                  {row.compareAtPrice < row.price ? (
                    <EuiFlexItem
                      css={css`
                        justify-content: center;
                      `}
                    >
                      <EuiToken
                        iconType="alert"
                        color={`${Theme.colors!.DARK?.warning}`}
                      />
                    </EuiFlexItem>
                  ) : null}
                </EuiFlexGroup>
              );
            }
          },
        },
        {
          name: "Interval",
          field: "",
          render: (item: ArProductState) => {
            const interval = formatInterval(
              item.subscriptionInterval,
              item.subscriptionIntervalUnit
            );

            if (!interval) {
              return (
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiToken
                      iconType="tokenException"
                      color={`${Theme.colors!.DARK?.danger}`}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              );
            }

            return (
              <EuiFlexGroup gutterSize={"none"}>
                <EuiFlexItem>
                  <EuiText size={"s"}>{interval}</EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            );
          },
        },
        {
          name: "Free Trial",
          field: "",
          render: (item: ArProductState) => {
            if (item.freeTrial) {
              return (
                <EuiFlexGroup gutterSize={"none"}>
                  <EuiFlexItem>
                    <EuiText size={"s"}>{item.freeTrial} Days</EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              );
            }
            return null;
          },
        },
        {
          name: "Assigned Roles",
          field: "",
          render: (item: ArProductState) => {
            const links = productServerRoleLinks.data.filter(
              (r) => r.product.productId === item.productId
            );
            return (
              <EuiFlexGroup
                gutterSize={"xs"}
                direction={"column"}
                css={css`
                  flex-grow: 0;
                `}
              >
                {links.map((r, index) => {
                  return links.map((link, index) => {
                    if (link.serverRole) {
                      return (
                        <EuiFlexItem
                          key={`${link.productId}-link=${index}`}
                          grow={false}
                        >
                          <EuiBadge
                            color={`#${
                              discordRoles.data
                                .find(
                                  (dr) =>
                                    dr.id === link.serverRole?.discordRoleId
                                )
                                ?.color.toString(16) || "43485b"
                            }`}
                          >
                            {link.serverRole.discordRoleName}
                          </EuiBadge>
                        </EuiFlexItem>
                      );
                    }
                    return null;
                  });
                })}
              </EuiFlexGroup>
            );
          },
        },
        {
          name: "",
          field: "",
          actions: [
            {
              render: (item: ArProductState) => (
                <EuiLink
                  disabled={item.productId in isRestoring}
                  onClick={() => handleRestore(item)}
                >
                  Restore
                </EuiLink>
              ),
            },
          ],
        },
      ];
    }
    return [];
  }, [products.isSuccess, products.data]);

  if (
    products.isSuccess &&
    productServerRoleLinks.isSuccess &&
    discordRoles.isSuccess
  ) {
    if (products.data.length === 0) {
      return null;
    }

    return (
      <>
        <EuiFlexItem>
          <EuiTitle size={"xs"}>
            <h1>Archived Products</h1>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiInMemoryTable
            items={products.data}
            columns={columns}
            itemId="id"
            isExpandable={true}
            tableLayout={"auto"}
            // @ts-ignore
            sorting={sort}
            pagination={true}
            hasActions={false}
          />
        </EuiFlexItem>
      </>
    );
  }

  return null;
};
