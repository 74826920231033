import * as React from "react";
import { SVGProps } from "react";

export const AutoroleFull = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 1876 1026"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    width="100%"
    height="100%"
    {...props}
  >
    <path
      d="M32.317 823.829C10.767 846.646 0 874.675 0 907.946c0 32.95 10.767 60.825 32.317 83.633 21.537 22.808 49.095 34.217 82.683 34.217 28.829 0 51-7.759 66.529-23.283v18.529h53.696V794.367h-53.696v18.537C166 797.383 143.829 789.621 115 789.621c-33.588 0-61.146 11.4-82.683 34.208Zm43.716 132.109c-12.354-12.671-18.537-28.667-18.537-47.992 0-19.321 6.183-35.479 18.537-48.471 12.354-12.671 27.875-19.008 46.571-19.008 21.542 0 40.388 6.496 56.55 19.479v95.517c-16.162 12.995-35.008 19.487-56.55 19.487-18.696 0-34.217-6.333-46.571-19.012Z"
      style={{
        fill: "url(#a)",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M457.617 794.367v165.85c-14.892 9.821-30.413 14.733-46.571 14.733-18.371 0-31.679-4.596-39.909-13.787-8.245-9.184-12.358-22.963-12.358-41.338V794.367h-56.554V924.1c0 32.317 7.846 57.338 23.529 75.079 15.679 17.746 39.038 26.617 70.092 26.617 12.662 0 25.021-2.067 37.062-6.179 12.038-4.117 21.221-9.817 27.567-17.104v18.529h53.696V794.367h-56.554Z"
      style={{
        fill: "url(#b)",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M606.829 731.163v63.204h-43.717v47.525h43.717v95.516c0 29.146 8.079 50.775 24.233 64.863 16.159 14.1 38.65 21.15 67.48 21.15 15.525 0 28.991-1.425 40.395-4.279V971.15c-8.241 1.896-17.275 2.846-27.087 2.846-16.154 0-28.358-3.246-36.592-9.742-8.241-6.487-12.358-18.292-12.358-35.4v-86.962h76.037v-47.525H662.9v-63.204h-56.071Z"
      style={{
        fill: "url(#c)",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M810.696 823.829c-23.446 22.817-35.163 50.846-35.163 84.117 0 32.95 11.717 60.825 35.163 83.633 23.446 22.809 52.425 34.217 86.962 34.217 34.85 0 64.075-11.408 87.679-34.217 23.6-22.808 35.405-50.683 35.405-83.633 0-33.271-11.805-61.3-35.405-84.117-23.604-22.808-52.829-34.208-87.679-34.208-34.537 0-63.516 11.4-86.962 34.208Zm40.629 131.634c-12.192-12.663-18.3-28.509-18.3-47.517 0-19.321 6.108-35.325 18.3-48 12.192-12.663 27.642-19.009 46.333-19.009 19.009 0 34.688 6.346 47.046 19.009 12.358 12.675 18.533 28.679 18.533 48 0 19.008-6.175 34.854-18.533 47.517-12.358 12.674-28.037 19.008-47.046 19.008-18.691 0-34.141-6.333-46.333-19.008Z"
      style={{
        fill: "url(#d)",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M1116.72 799.597v24.233c18.063-17.733 42.292-26.608 72.705-26.608 4.754 0 11.562.321 20.437.95v28.987c-5.075-.633-12.517-.954-22.333-.954-29.463 0-52.746 8.4-69.854 25.188v169.65h-31.842V799.597h30.887ZM1274.03 910.316c0 24.084 8.07 44.2 24.233 60.354 16.158 16.48 36.117 24.709 59.871 24.709 23.766 0 43.725-8.15 59.879-24.471 16.154-16.313 24.237-36.508 24.237-60.592 0-24.387-8.083-44.662-24.237-60.825-16.154-16.154-36.113-24.233-59.879-24.233-23.754 0-43.713 8.079-59.871 24.233-16.163 16.163-24.233 36.438-24.233 60.825m.945-82.208c22.171-22.175 49.896-33.267 83.159-33.267 33.271 0 61.066 11.167 83.4 33.504 22.337 22.334 33.504 49.663 33.504 81.971 0 32.009-11.092 59.246-33.263 81.742-22.5 22.496-50.37 33.737-83.641 33.737-33.263 0-60.988-11.166-83.159-33.504-22.175-22.333-33.266-49.658-33.266-81.975 0-32.308 11.091-59.712 33.266-82.208"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
    />
    <path
      style={{
        fill: "#fff",
      }}
      d="M1545.35 693.146h31.838v327.896h-31.838z"
    />
    <path
      d="M1679.84 896.062h164.42c-3.17-21.217-12.354-38.492-27.562-51.796-15.208-13.308-33.429-19.958-54.65-19.958-21.229 0-39.438 6.649-54.654 19.958-15.2 13.304-24.396 30.579-27.554 51.796m.47-67.955c21.538-22.17 48.788-33.262 81.738-33.262 32.942 0 60.117 11.092 81.496 33.262 21.387 22.184 32.079 49.584 32.079 82.209v12.362H1679.84c2.845 22.175 12.508 39.917 28.987 53.217 16.471 13.308 38.646 19.963 66.529 19.963 30.413 0 58.129-7.134 83.159-21.388v30.888c-23.455 13.629-51.171 20.437-83.159 20.437-39.291 0-70.337-11.004-93.141-33.025-22.813-22.017-34.213-49.5-34.213-82.454 0-32.625 10.763-60.025 32.308-82.208"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M793.475 10.6c.379 43.588.175 87.188.175 130.779v11.629h-12.729c-42.954 0-85.909.175-128.863-.15-8.279-.062-11.325 1.9-11.304 10.93.283 142.387.292 284.775-.008 427.162-.021 9.492 3.254 11.392 11.904 11.317 31.546-.275 63.1-.238 94.654-.2 11.4.016 22.8.029 34.204.025 14.392 0 14.371-.004 14.371-14.479-.012-140.188.063-280.384-.2-420.575-.012-8.867 2.104-11.713 11.392-11.675 87.162.358 174.329.358 261.491 0 9.309-.038 11.421 2.85 11.384 11.691-.317 79.034-.321 158.067 0 237.104.037 9.059-2.584 11.775-11.646 11.675-39.813-.433-79.633-.191-119.454-.145-3.634.004-7.271.345-12.734.625 3.1 10.858 5.613 20.425 8.563 29.849 25.379 81.071 108.996 147.959 193.758 153.905 28.409 1.991 57.046 2.896 85.496 2.179 8.883-.221 10.933-2.875 10.917-11.308-.234-142.388-.254-284.775.029-427.163.021-9.013-2.992-11-11.292-10.937-43.271.333-86.541.166-129.808.133-3.654-.004-7.308-.308-11.796-.508v-11.067c0-43.6-.204-87.192.175-130.788.075-8.391-2.637-10.62-10.762-10.6C982.35.246 893.3.246 804.258 0h-.125c-8 0-10.733 2.225-10.658 10.6Z"
      style={{
        fill: "url(#e)",
        fillRule: "nonzero",
      }}
    />
    <defs>
      <linearGradient
        id="a"
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="scale(1010.9009) rotate(.99 -51.849 -.535)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#3aaed8",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#62dea7",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="scale(1010.9009) rotate(.99 -51.649 -.534)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#3aaed8",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#62dea7",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="scale(1010.9009) rotate(.99 -49.549 -.515)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#3aaed8",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#62dea7",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="d"
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="scale(1010.9009) rotate(.99 -51.074 -.529)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#3aaed8",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#62dea7",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
      <linearGradient
        id="e"
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(640.747 301.246) scale(594.13)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#3aaed8",
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#62dea7",
            stopOpacity: 1,
          }}
        />
      </linearGradient>
    </defs>
  </svg>
);
