import axios from "axios";

import { QueryObserverOptions, useQuery } from "@tanstack/react-query";

import { City, Country, State } from "../../@types/csc";

const cscClient = axios.create({
  baseURL: `https://api.countrystatecity.in/v1/`,
  headers: {
    "Content-type": "application/json",
  },
});

cscClient.interceptors.request.use(function (config) {
  if (config.headers === undefined) {
    config.headers = {};
  }
  config.headers[
    "X-CSCAPI-KEY"
  ] = `WmNHakJhR0plUDhSZ0ltaUVtdlYxSGxzQXRqVTNzOU5TNVVROWxBeA==`;

  return config;
});

export const cscKeys = {
  countries: [{ scope: "countries" }] as const,
  states: (country: string) => [{ country, scope: "states" }] as const,
  cities: (country: string, state: string) =>
    [{ country, state, scope: "cities" }] as const,
};

const fetchCountries = async () => {
  const resp = await cscClient.get<Country[]>("countries");
  return resp.data;
};

export const useCountries = (
  options?: QueryObserverOptions<Country[], Error>
) =>
  useQuery<Country[], Error>(
    cscKeys.countries,
    async () => fetchCountries(),
    options
  );

const fetchStates = async (countryCode: string): Promise<State[]> => {
  const resp = await cscClient.get<State[]>(`countries/${countryCode}/states`);
  return resp.data;
};

export const useStates = (
  countryCode: string,
  options?: QueryObserverOptions<State[], Error>
) =>
  useQuery<State[], Error>(
    cscKeys.states(countryCode),
    async () => fetchStates(countryCode),
    options
  );

const fetchCities = async (countryCode: string, stateCode: string) => {
  const resp = await cscClient.get<City[]>(
    `countries/${countryCode}/states${stateCode}/cities`
  );
  return resp.data;
};

export const useCities = (
  countryCode: string,
  stateCode: string,
  options?: QueryObserverOptions<City[], Error>
) =>
  useQuery<City[], Error>(
    cscKeys.cities(countryCode, stateCode),
    async () => fetchCities(countryCode, stateCode),
    options
  );
