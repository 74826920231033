import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
} from "@elastic/eui";
import { css } from "@emotion/react";

import { ArProductState } from "../../@types/product";
import { ProductPrice } from "../../features/formatters/storeProduct";
import { useCurrencyFormatter } from "../../features/hooks/hooks";
import { setModal } from "../../features/redux/appState";
import Placeholder from "../../images/image-placeholder.svg";
import { ROUTE_BUILDER } from "../app/app";

interface ProductCardProps {
  serverId: string;
  product: ArProductState;
  currencyFormat: Intl.NumberFormat;
}

export const ProductCard: FC<ProductCardProps> = ({
  product,
  serverId,
  currencyFormat,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <EuiCard
      hasBorder={true}
      textAlign="left"
      image={product.imageUrl || Placeholder}
      css={css`
        & .euiBetaBadge--hollow {
          background-color: #55b9dd;
          color: #141519;
        }
      `}
      paddingSize={"m"}
      betaBadgeProps={
        product.freeTrial
          ? {
              label: `${product.freeTrial} Day Free Trial`,
            }
          : undefined
      }
      title={
        <>
          <EuiFlexGroup
            gutterSize={"xs"}
            direction={"column"}
            alignItems={"center"}
            css={css`
              text-align: center;
            `}
          >
            <EuiFlexItem>
              {product.name.length <= 36
                ? product.name
                : product.name.slice(0, 36) + "..."}
            </EuiFlexItem>
            <EuiFlexItem>
              <ProductPrice formatter={currencyFormat} product={product} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </>
      }
      description={
        <EuiFlexGroup
          direction={"column"}
          gutterSize={"xs"}
          alignItems={"center"}
        ></EuiFlexGroup>
      }
      footer={
        <EuiFlexGroup
          justifyContent="spaceAround"
          direction={"column"}
          gutterSize={"s"}
        >
          <EuiFlexItem
            css={css`
              align-items: center;
            `}
          >
            {product.description.length > 0 ? (
              <EuiLink
                color={"accent"}
                onClick={() =>
                  dispatch(
                    setModal({
                      modal: "productDescription",
                      open: true,
                      props: product,
                    })
                  )
                }
              >
                View More
              </EuiLink>
            ) : null}
          </EuiFlexItem>
          <EuiFlexItem grow={true}>
            <EuiButton
              onClick={() =>
                navigate(
                  ROUTE_BUILDER.STRIPE_PURCHASE(
                    product.serverId,
                    product.productId
                  )
                )
              }
              fullWidth
              disabled={!product.price || !product.subscriptionIntervalUnit}
            >
              Subscribe
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      }
    />
  );
};
