import React from "react";
import { useImmer } from "use-immer";
import { v4 } from "uuid";

import { EuiGlobalToastList } from "@elastic/eui";
import { Toast } from "@elastic/eui/src/components/toast/global_toast_list";

let addToastHandler: any;
let removeAllToastsHandler: any;
const toastId = 0;

export function addToast(toast: Toast) {
  if (!("id" in toast)) {
    // @ts-ignore
    toast.id = v4();
  }
  addToastHandler(toast);
}

export function removeAllToasts() {
  removeAllToastsHandler();
}

export default () => {
  const [toasts, setToasts] = useImmer<Toast[]>([]);

  addToastHandler = (toast: Toast) => {
    setToasts(toasts.concat(toast));
  };

  const removeToast = (removedToast: Toast) => {
    setToasts(toasts.filter((toast) => toast.id !== removedToast.id));
  };

  removeAllToastsHandler = () => {
    setToasts([]);
  };

  return (
    // @ts-ignore
    <EuiGlobalToastList
      toasts={toasts}
      dismissToast={removeToast}
      toastLifeTimeMs={6000}
    />
  );
};
