import { FormikProps, withFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useImmer } from "use-immer";
import * as Yup from "yup";

import {
  EuiButton,
  EuiCallOut,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiLink,
  EuiText,
} from "@elastic/eui";
import { css } from "@emotion/react";
import { UseMutationResult } from "@tanstack/react-query";

import { ServerState } from "../../../../@types/server";
import { addToast } from "../../../../components/toast";
import { useWooLogin } from "../../../../features/api/auth";
import { useTestWooConnection } from "../../../../features/api/woo-api";
import { AUTOROLE_APP_VERSION } from "../../../../features/constants";
import {
  getWooConnectionStatus,
  setWooConnectionStatus,
} from "../../../../features/redux/appState";

const STORE_TYPE_OPTIONS = [
  {
    value: "woocommerceStore",
    text: "Woocommerce",
  },
];

interface FormValues {
  url?: string;
}

interface OuterProps {
  server?: ServerState;
  onClick: (siteUrl: string | undefined) => void;
  disabled?: boolean;
  isConnected: boolean;
  isLatest: boolean;
  initialValues: Partial<FormValues> | undefined;
  wooLogin: UseMutationResult<
    any,
    unknown,
    { serverId: string; url: string },
    unknown
  >;
}

interface SiteSettingsFormProps {
  server?: ServerState;
  disabled?: boolean;
}

const BaseForm = (props: OuterProps & FormikProps<FormValues>) => {
  const {
    server,
    disabled,
    onClick,
    isConnected,
    isLatest,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    isSubmitting,
  } = props;

  return (
    <EuiFlexGroup
      direction={"column"}
      css={css`
        max-width: 600px;
      `}
    >
      <EuiFlexItem>
        <form onSubmit={handleSubmit}>
          <EuiForm>
            <EuiFlexGroup direction={"column"}>
              {isLatest ? null : (
                <EuiCallOut
                  title="App Out of Date"
                  color="warning"
                  iconType="alert"
                >
                  <EuiFlexGroup justifyContent={"spaceBetween"}>
                    <EuiFlexItem grow={false}>
                      <EuiText color={"white"}>
                        The Autorole app on WooCommerce is out of date. Please
                        update!
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton href="#" color="warning">
                        Download Update
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiCallOut>
              )}
              <EuiFlexItem>
                <EuiText size={"s"} color={disabled ? "subdued" : undefined}>
                  <p>
                    The WooCommerce <i>Autorole</i> plugin in required and may
                    be downloaded <EuiLink>here</EuiLink>. Once installed, click{" "}
                    <i>Connect Store</i> below.
                  </p>
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow
                  fullWidth={true}
                  label={"Site URL"}
                  isInvalid={(isSubmitting || touched.url) && !!errors.url}
                  error={errors.url}
                >
                  <EuiFieldText
                    disabled={disabled || false}
                    fullWidth={true}
                    name={"url"}
                    value={values.url}
                    required={touched.url}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isLoading={isSubmitting}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiFlexGroup>
                  <EuiFlexItem grow={false}>
                    <EuiFormRow hasEmptyLabelSpace={true}>
                      <EuiButton
                        disabled={disabled || false}
                        type={"submit"}
                        isLoading={
                          !errors || isSubmitting || props.wooLogin.isLoading
                        }
                        onClick={() => handleSubmit()}
                      >
                        {isConnected ? "Reconnect Store" : "Connect Store"}
                      </EuiButton>
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiFormRow hasEmptyLabelSpace={true}>
                      {isConnected ? (
                        <EuiText color={"success"}>
                          Successful Connection!
                        </EuiText>
                      ) : (
                        <EuiButton
                          disabled={disabled || false}
                          color={"accent"}
                          isLoading={!errors || isSubmitting}
                          onClick={() => onClick(values.url)}
                        >
                          Test Connection
                        </EuiButton>
                      )}
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiForm>
        </form>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

const FormikForm = withFormik<OuterProps, FormValues>({
  mapPropsToValues: (props) => ({
    url: props.initialValues?.url || "",
  }),

  enableReinitialize: true,

  validationSchema: Yup.object().shape({
    url: Yup.string()
      .url("Site URL must be a valid URL")
      .required("Site URL is required"),
  }),

  handleSubmit({ url }: FormValues, { props, setSubmitting, setErrors }) {
    if (props.server && props.server.id && url) {
      setSubmitting(true);
      props.wooLogin.mutate({ serverId: props.server.id, url });
    }
    setSubmitting(false);
  },
})(BaseForm);

export const WooConnectToSiteForm: React.FC<SiteSettingsFormProps> = ({
  server,
  disabled,
}) => {
  const wooLogin = useWooLogin();

  const [isLatest, setIsLatest] = useImmer<boolean>(true);
  const connection = useTestWooConnection();

  const dispatch = useDispatch();

  const onClick = (url: string | undefined) => {
    if (url) {
      connection.mutate(url, {
        onSuccess: (data) => {
          setIsLatest(JSON.parse(data).version === AUTOROLE_APP_VERSION);
          dispatch(setWooConnectionStatus(true));
        },
        onError: (data) => {
          addToast({
            id: "woo-connect-failure",
            title: data.message,
            color: "danger",
          });
          dispatch(setWooConnectionStatus(false));
          setIsLatest(true);
        },
      });
    }
  };
  useEffect(() => {
    onClick(server?.url);
  }, []);

  return (
    <FormikForm
      isConnected={useSelector(getWooConnectionStatus)}
      isLatest={isLatest}
      server={server}
      onClick={onClick}
      disabled={disabled}
      initialValues={server}
      wooLogin={wooLogin}
    />
  );
};
