import React, { Dispatch, FC, SetStateAction, useEffect } from "react";
import { Cropper } from "react-cropper";
import { useImmer } from "use-immer";

import { EuiFilePicker, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import "cropperjs/dist/cropper.css";

interface ImageUploadProps {
  id: string;
  initialAspectRatio?: number;
  aspectRatio?: number;
  initialImgSrc?: string;
  completedCrop: { fileName: string; uri: string } | undefined;
  setCompletedCrop: Dispatch<
    SetStateAction<{ fileName: string; uri: string } | undefined>
  >;
  triggerSave?: boolean;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
}

export const ImageUpload: FC<ImageUploadProps> = ({
  id,
  initialImgSrc,
  initialAspectRatio,
  aspectRatio,
  completedCrop,
  setCompletedCrop,
  triggerSave,
  setIsLoading,
}) => {
  const [fileName, setFileName] = useImmer<string>("");
  const [imgSrc, setImgSrc] = useImmer(initialImgSrc || "");
  const [cropper, setCropper] = useImmer<any>(undefined);

  const onFileChange = (files: FileList | null) => {
    if (files) {
      const reader = new FileReader();
      reader.onload = () => {
        setImgSrc(reader.result as any);
      };
      reader.readAsDataURL(files[0]);
      setFileName(`${files[0].name}`);
    }
  };

  useEffect(() => {
    if (triggerSave !== undefined) {
      if (typeof cropper !== "undefined") {
        setCompletedCrop({
          fileName,
          uri: cropper.getCroppedCanvas().toDataURL(),
        });
      }
    }
  }, [triggerSave]);

  return (
    <EuiFlexGroup direction={"column"}>
      <EuiFlexItem grow={false}>
        <EuiFilePicker
          fullWidth={true}
          id={`image-upload-${id}`}
          onChange={onFileChange}
          display={"default"}
        />
      </EuiFlexItem>
      {Boolean(imgSrc) && (
        <EuiFlexItem>
          <Cropper
            style={{ height: 400, width: "100%" }}
            zoomTo={0.5}
            initialAspectRatio={initialAspectRatio || 1}
            aspectRatio={aspectRatio ? aspectRatio : undefined}
            preview=".img-preview"
            src={imgSrc}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={true} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            guides={true}
          />
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  );
};
