import React from "react";

import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { ServerState } from "../../../@types/server";
import { ChartsPanel } from "../panels/performance/ChartsPanel";

interface ManageServerPerformanceSubPageProps {
  server: ServerState;
}
export const ManageServerPerformanceSubPage: React.FC<
  ManageServerPerformanceSubPageProps
> = ({ server }) => {
  return (
    <EuiFlexGroup gutterSize={"m"} direction={"column"}>
      <EuiFlexItem>
        <ChartsPanel server={server} />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
