import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useImmer } from "use-immer";

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiText } from "@elastic/eui";
import { css } from "@emotion/react";

import { AppSettingsState } from "../../@types/appSettings";
import { ServerState } from "../../@types/server";
import { ArModal } from "../../arComponents/ArModal";
import { useCreateAccount } from "../../features/api/stripe";
import { getModal } from "../../features/redux/appState";

interface CreateTestAccountModalProps {
  server: ServerState;
}

export const CreateTestAccountModal: FC<CreateTestAccountModalProps> = ({
  server,
}) => {
  const createAccount = useCreateAccount();

  const [isLoading, setIsLoading] = useImmer<boolean>(false);
  const dialog = useSelector(
    getModal("createTestAccount")
  ) as AppSettingsState["modals"]["createTestAccount"];

  if (dialog.isOpen) {
    return (
      <ArModal
        yesText={"Close"}
        modalKey={"createTestAccount"}
        minWidth={"750px"}
        title={"Test Account Not Yet Created"}
        body={
          <EuiFlexGroup
            direction={"column"}
            css={css`
              @media (min-width: 768px) {
                height: 100%;
              }

              flex-wrap: nowrap;
            `}
          >
            <EuiFlexItem>
              <EuiText>
                A Stripe Test Account is required to perform test purchases.
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton
                isLoading={isLoading}
                fullWidth={false}
                onClick={() => {
                  setIsLoading(true);
                  createAccount.mutate(server.id);
                }}
              >
                Create Test Store
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        }
      />
    );
  }
  return null;
};
