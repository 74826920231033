import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useImmer } from "use-immer";

import { EuiFormRow, EuiLink, EuiSuperSelect, EuiText } from "@elastic/eui";
import { EuiSuperSelectOption } from "@elastic/eui/src/components/form/super_select";
import { EuiSuperSelectProps } from "@elastic/eui/src/components/form/super_select/super_select";

import { DiscordGuildRole } from "../../@types/discord";
import { setModal } from "../../features/redux/appState";

interface SelectGuildRoleProps {
  value: string;
  setFieldValue: (name: string, value: string) => void;
  guildRoles: DiscordGuildRole[];
  disabled?: boolean;
  selectProps?: Partial<EuiSuperSelectProps<any>>;
  showLabel?: boolean;
  showHelpText?: boolean;
}

export const SelectGuildRole: React.FC<SelectGuildRoleProps> = (props) => {
  const {
    value,
    setFieldValue,
    guildRoles,
    disabled,
    selectProps,
    showHelpText,
    showLabel,
    ...rest
  } = props;

  const [options, setOptions] = useImmer<EuiSuperSelectOption<string>[]>([]);

  const onSelectionChange = (value: string) => {
    const name = options.find((o) => o.value === value);
    if (name && name.inputDisplay) {
      setFieldValue(name.inputDisplay as string, value);
    }
  };

  useEffect(() => {
    if (guildRoles) {
      const botRole = guildRoles.find(
        (r) => r.name.toLowerCase() === "autorole"
      );
      if (botRole) {
        setOptions(
          guildRoles
            .filter((r) => r.position < botRole.position)
            .map((o) => {
              return {
                value: o.id,
                inputDisplay: o.name,
                dropdownDisplay: (
                  <>
                    <strong>{o.name}</strong>
                    {/*<EuiText size="s" color="subdued">*/}
                    {/*  <p className="euiTextColor--subdued">Role ID: {o.id}</p>*/}
                    {/*</EuiText>*/}
                  </>
                ),
              };
            })
        );
      }
    }
  }, [guildRoles]);

  const dispatch = useDispatch();

  return (
    <EuiFormRow
      label={showLabel ? "Role" : undefined}
      hasEmptyLabelSpace={!showLabel}
      helpText={
        showHelpText ? (
          <EuiLink
            onClick={() =>
              dispatch(setModal({ modal: "missingRoles", open: true }))
            }
          >
            I'm missing roles / only see @everyone
          </EuiLink>
        ) : null
      }
    >
      <EuiSuperSelect
        disabled={disabled}
        valueOfSelected={value}
        onChange={(e) => onSelectionChange(e)}
        hasDividers={true}
        {...selectProps}
        options={options}
      />
    </EuiFormRow>
  );
};
