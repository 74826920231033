import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  EuiBadge,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiInMemoryTable,
  EuiLink,
  EuiLoadingContent,
  EuiText,
  EuiTitle,
  EuiToken,
  EuiToolTip,
} from "@elastic/eui";
import {
  EuiTableFieldDataColumnType,
  EuiTableSortingType,
} from "@elastic/eui/src/components/basic_table/table_types";
import { css } from "@emotion/react";

import { DiscordGuildRole } from "../../@types/discord";
import { ArProductState } from "../../@types/product";
import { ServerState } from "../../@types/server";
import { ArImage } from "../../arComponents/ArImage";
import { useDiscordApi } from "../../features/api/discord-server-api";
import { initialStoreProduct } from "../../features/api/initials";
import { useProducts } from "../../features/api/products";
import {
  useDefaultServerRole,
  useProductServerRoleLinks,
} from "../../features/api/server-roles";
import { formatInterval } from "../../features/formatters/storeProduct";
import { useCurrencyFormatter } from "../../features/hooks/hooks";
import { setModal } from "../../features/redux/appState";
import { ROUTES } from "../app/app";
import { Theme, ThemeDanger } from "../app/theme";
import { ArEditProductModal } from "../modal/ArProductModal/ArProductModal";

interface ArSubscriptionTableProps {
  server: ServerState;
}

export const ArActiveProductsTable: React.FC<ArSubscriptionTableProps> = ({
  server,
}) => {
  const products = useProducts(server.id);
  const productServerRoleLinks = useProductServerRoleLinks(server.id);
  const navigate = useNavigate();
  const currencyFormat = useCurrencyFormatter(server.id);

  const defaultServerRole = useDefaultServerRole(server.id);

  const discordRoles = useDiscordApi<DiscordGuildRole[]>(
    server.id,
    "guilds",
    "roles"
  );

  const dispatch = useDispatch();

  const sort: EuiTableSortingType<any> = {
    enableAllColumns: true,
    allowNeutralSort: true,
    sort: {
      field: "name",
      direction: "desc",
    },
  };

  const rowProps = (product: ArProductState) => {
    if (productServerRoleLinks.data) {
      const roles = productServerRoleLinks.data.filter(
        (r) => r.product.productId === product.productId
      );

      if (roles.length === 0) {
        return {
          className: "ar-danger-row",
        };
      }
    }
  };

  if (
    products.isSuccess &&
    productServerRoleLinks.isSuccess &&
    discordRoles.isSuccess
  ) {
    const columns: EuiTableFieldDataColumnType<ArProductState>[] = [
      {
        field: "name",
        name: "Name",
        render: (item: string, row: ArProductState) => {
          return (
            <EuiFlexGroup responsive={false}>
              <EuiFlexItem
                grow={false}
                css={css`
                  width: 50px;
                `}
              >
                <ArImage size={50} src={row.imageUrl} alt={row.name + "logo"} />{" "}
              </EuiFlexItem>
              <EuiFlexItem
                css={css`
                  justify-content: center;
                `}
              >
                <span className={"euiTableCellContent"}>{item}</span>
              </EuiFlexItem>
            </EuiFlexGroup>
          );
        },
      },
      {
        field: "price",
        name: "Price",
        render: (item: number) => {
          if (currencyFormat) {
            return (
              <EuiFlexGroup gutterSize={"none"}>
                <EuiFlexItem>
                  <EuiText size={"s"}>{currencyFormat.format(item)}</EuiText>
                </EuiFlexItem>
                {!item ? (
                  <EuiFlexItem
                    css={css`
                      justify-content: center;
                    `}
                  >
                    <EuiToken
                      iconType="tokenException"
                      color={`${Theme.colors!.DARK?.danger}`}
                    />
                  </EuiFlexItem>
                ) : null}
              </EuiFlexGroup>
            );
          }
          return null;
        },
      },
      {
        field: "compareAtPrice",
        name: "Compare at Price",
        render: (item: number | undefined, row) => {
          if (row.compareAtPrice) {
            if (currencyFormat) {
              return (
                <EuiFlexGroup gutterSize={"none"}>
                  <EuiFlexItem>
                    <EuiText size={"s"}>
                      {currencyFormat.format(row.compareAtPrice)}
                    </EuiText>
                  </EuiFlexItem>
                  {row.compareAtPrice < row.price ? (
                    <EuiFlexItem
                      css={css`
                        justify-content: center;
                      `}
                    >
                      <EuiToken
                        iconType="alert"
                        color={`${Theme.colors!.DARK?.warning}`}
                      />
                    </EuiFlexItem>
                  ) : null}
                </EuiFlexGroup>
              );
            }
            return null;
          }
        },
      },
      {
        name: "Interval",
        field: "",
        render: (item: ArProductState) => {
          const interval = formatInterval(
            item.subscriptionInterval,
            item.subscriptionIntervalUnit
          );

          if (!interval) {
            return (
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiToken
                    iconType="tokenException"
                    color={`${Theme.colors!.DARK?.danger}`}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            );
          }

          return (
            <EuiFlexGroup gutterSize={"none"}>
              <EuiFlexItem>
                <EuiText size={"s"}>{interval}</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          );
        },
      },
      {
        name: "Free Trial",
        field: "",
        render: (item: ArProductState) => {
          if (item.freeTrial) {
            return (
              <EuiFlexGroup gutterSize={"none"}>
                <EuiFlexItem>
                  <EuiText size={"s"}>{item.freeTrial} Days</EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            );
          }
          return null;
        },
      },
      {
        name: "Assigned Roles",
        field: "",
        render: (item: ArProductState) => {
          const links = productServerRoleLinks.data.filter(
            (r) => r.product.productId === item.productId
          );
          return (
            <EuiFlexGroup
              gutterSize={"xs"}
              direction={"column"}
              css={css`
                flex-grow: 0;
              `}
            >
              {
                links.length > 0
                  ? links.map((link, index) => {
                      if (link.serverRole) {
                        return (
                          <EuiFlexItem
                            key={`${link.productId}-link=${index}`}
                            grow={false}
                          >
                            <EuiBadge
                              color={`#${
                                discordRoles.data
                                  .find(
                                    (dr) =>
                                      dr.id === link.serverRole?.discordRoleId
                                  )
                                  ?.color.toString(16) || "43485b"
                              }`}
                            >
                              {link.serverRole.discordRoleName}
                            </EuiBadge>
                          </EuiFlexItem>
                        );
                      }
                      return null;
                    })
                  : null
                // <EuiFlexItem grow={false}>
                //   {/* @ts-ignore */}
                //   <EuiBadge
                //     iconType={"tokenException"}
                //     color={ThemeDanger}
                //     css={css`
                //       &:hover {
                //         cursor: pointer;
                //       }
                //     `}
                //     onClick={() =>
                //       navigate(`${ROUTES.SERVER_MANAGE}/roles/${server.alias}`)
                //     }
                //   >
                //     Missing Role Link
                //   </EuiBadge>
                // </EuiFlexItem>
              }
            </EuiFlexGroup>
          );
        },
      },
      {
        name: "",
        field: "",
        // @ts-ignore
        actions: [
          {
            render: (item: ArProductState) => {
              return (
                <EuiLink
                  onClick={() =>
                    dispatch(
                      setModal({
                        modal: "editProduct",
                        open: true,
                        props: { ...item, isEdit: true },
                      })
                    )
                  }
                >
                  Edit
                </EuiLink>
              );
            },
          },
        ],
      },
    ];

    return (
      <>
        <EuiFlexItem>
          <EuiTitle size={"xs"}>
            <h1>Active Products</h1>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiToolTip
                content={
                  !defaultServerRole.data
                    ? "Choose a Default Role to continue"
                    : undefined
                }
              >
                <EuiButton
                  disabled={!defaultServerRole.data}
                  color={"accent"}
                  onClick={() =>
                    dispatch(
                      setModal({
                        modal: "editProduct",
                        open: true,
                        props: initialStoreProduct(server.id),
                      })
                    )
                  }
                >
                  Add Product
                </EuiButton>
              </EuiToolTip>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiInMemoryTable
            items={products.data}
            columns={columns}
            itemId="id"
            // rowProps={rowProps}
            isExpandable={true}
            tableLayout={"auto"}
            // @ts-ignore
            sorting={sort}
            pagination={true}
            hasActions={false}
          />
        </EuiFlexItem>
        <ArEditProductModal server={server} />
      </>
    );
  }

  return (
    <EuiFlexItem>
      <EuiLoadingContent lines={5} />
    </EuiFlexItem>
  );
};
