import React from "react";

import { EuiFlexGroup, EuiFlexItem, EuiTitle } from "@elastic/eui";

import { DiscordGuildRole } from "../../../../@types/discord";
import { ServerState } from "../../../../@types/server";
import {
  ProductServerRoleLink,
  ServerRole,
} from "../../../../@types/serverRole";

import { DefaultRoleSelection } from "./DefaultRoleSelectionPanel";

interface DefaultRolePanelProps {
  server: ServerState;
  guildRoles: DiscordGuildRole[] | undefined;
  defaultServerRole: ServerRole | undefined;
  productServerRoleLinks: ProductServerRoleLink[] | undefined;
  isLoading: boolean;
}
export const DefaultRoleSection: React.FC<DefaultRolePanelProps> = ({
  server,
  guildRoles,
  productServerRoleLinks,
  defaultServerRole,
  isLoading,
}) => {
  return (
    <EuiFlexGroup direction={"column"}>
      <EuiFlexItem>
        <EuiTitle size={"s"}>
          <h2>Default Role</h2>
        </EuiTitle>
        <EuiTitle size={"xxxs"}>
          <h2>
            Generally your lowest role level. Users will be assigned this role
            when their subscription expires.
          </h2>
        </EuiTitle>
      </EuiFlexItem>

      <EuiFlexItem>
        <DefaultRoleSelection
          server={server}
          guildRoles={guildRoles}
          defaultServerRole={defaultServerRole}
          productServerRoleLinks={productServerRoleLinks}
          isLoading={isLoading}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
