import React from "react";
import { useNavigate } from "react-router-dom";

import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
} from "@elastic/eui";

import { DiscordGuildRole } from "../../../../@types/discord";
import { ServerState } from "../../../../@types/server";
import { ArHorizontalRule } from "../../../../arComponents/ArHorizontalRule";
import { ArPanel } from "../../../../arComponents/ArPanel";
import { ROUTES } from "../../../../components/app/app";
import { SplitPanelTitle } from "../../../../components/header/SplitPanelTitle";
import { ArActiveProductsTable } from "../../../../components/table/ArActiveProductsTable";
import { ArArchivedProductsTable } from "../../../../components/table/ArArchivedProductsTable";
import { ArCouponsTable } from "../../../../components/table/ArCouponsTable";
import { useDiscordApi } from "../../../../features/api/discord-server-api";
import {
  useDefaultServerRole,
  useProductServerRoleLinks,
} from "../../../../features/api/server-roles";
import { DefaultRoleSection } from "../manageRoles/DefaultRoleSection";

interface ArMangeProductsPanelProps {
  server: ServerState;
}

export const ArMangeSubscriptionsPanel: React.FC<ArMangeProductsPanelProps> = ({
  server,
}) => {
  const productServerRoleLinks = useProductServerRoleLinks(server.id, {
    refetchOnWindowFocus: false,
  });

  const guildRoles = useDiscordApi<DiscordGuildRole[]>(
    server.id,
    "guilds",
    "roles"
  );

  const defaultServerRole = useDefaultServerRole(server.id);

  const navigate = useNavigate();

  return (
    <ArPanel>
      <EuiFlexGroup direction={"column"}>
        <SplitPanelTitle
          left={"Manage Coupons"}
          right={
            server ? (
              <EuiButtonEmpty
                onClick={() => {
                  navigate(`${ROUTES.STORE}/${server.alias}/collections`, {
                    replace: true,
                  });
                }}
              >
                View Store
              </EuiButtonEmpty>
            ) : null
          }
        />
        <ArCouponsTable server={server} />
        <EuiFlexItem>
          <ArHorizontalRule threeQuarter={true} />
        </EuiFlexItem>
        <EuiFlexItem>
          <DefaultRoleSection
            server={server}
            guildRoles={guildRoles.data}
            defaultServerRole={defaultServerRole.data}
            productServerRoleLinks={productServerRoleLinks.data}
            isLoading={productServerRoleLinks.isLoading}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <ArHorizontalRule threeQuarter={true} />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiTitle size={"s"}>
            <h1>Manage Products</h1>
          </EuiTitle>
        </EuiFlexItem>
        <ArActiveProductsTable server={server} />
        <ArArchivedProductsTable server={server} />
      </EuiFlexGroup>
    </ArPanel>
  );
};
