import { DateTime } from "luxon";
import React, { ReactNode, useMemo } from "react";
import { useImmer } from "use-immer";

import {
  EuiBadge,
  EuiButtonIcon,
  EuiCodeBlock,
  EuiFlexGroup,
  EuiFlexItem,
  EuiInMemoryTable,
  EuiLoadingContent,
  RIGHT_ALIGNMENT,
} from "@elastic/eui";
import {
  EuiTableFieldDataColumnType,
  EuiTableSortingType,
} from "@elastic/eui/src/components/basic_table/table_types";

import { ServerState } from "../../@types/server";
import { WooWebhook } from "../../@types/woo";
import { useWebhookLogsForAdmins } from "../../features/api/admin";
import { useServerFromUrl } from "../../features/hooks/hooks";

interface WebhookEventsTableProps {
  server: ServerState;
}

export const WebhookEventsTable: React.FC<WebhookEventsTableProps> = () => {
  const server = useServerFromUrl();
  const serverId = server.data ? server.data.id : undefined;
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useImmer<{
    [id: string]: ReactNode;
  }>({});

  const webhookLogsForAdmins = useWebhookLogsForAdmins(serverId!, {
    enabled: !!serverId,
  });

  const getRowProps = (item: WooWebhook) => {
    return {
      onClick: () => {
        toggleDetails(item);
      },
    };
  };

  const sort: EuiTableSortingType<any> = {
    enableAllColumns: true,
    allowNeutralSort: true,
    sort: {
      field: "updatedAt",
      direction: "desc",
    },
  };

  const toggleDetails = (item: WooWebhook) => {
    setItemIdToExpandedRowMap((draft) => {
      if (draft[item.id]) {
        delete draft[item.id];
      } else {
        draft[item.id] = (
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiCodeBlock language="json" overflowHeight={300} isCopyable>
                {JSON.stringify(item, null, 4)}
              </EuiCodeBlock>
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      }
      return draft;
    });
  };

  const columns = useMemo<EuiTableFieldDataColumnType<any>[]>(() => {
    if (webhookLogsForAdmins.isSuccess) {
      return [
        {
          field: "username",
          name: "Username",
        },
        {
          field: "status",
          name: "Status",
        },
        {
          field: "updatedAt",
          name: "Sent",
          render: (value: string) => {
            return DateTime.fromISO(value)
              .toLocal()
              .toFormat("yyyy-LL-dd hh:mm:ss");
          },
        },
        {
          field: "type",
          name: "Type",
          render: (value: string) => {
            return (
              <EuiBadge color={value === "subscription" ? "accent" : "primary"}>
                {value}
              </EuiBadge>
            );
          },
        },
        {
          name: "",
          field: "",
          align: RIGHT_ALIGNMENT,
          width: "40px",
          isExpander: true,
          render: (item: any) => (
            <EuiButtonIcon
              onClick={() => toggleDetails(item)}
              aria-label={
                itemIdToExpandedRowMap && [item.id] ? "Collapse" : "Expand"
              }
              iconType={
                itemIdToExpandedRowMap[item.id] ? "arrowUp" : "arrowDown"
              }
            />
          ),
        },
      ];
    }
    return [];
  }, [webhookLogsForAdmins.isSuccess]);
  if (webhookLogsForAdmins.data) {
    return (
      <EuiInMemoryTable
        items={webhookLogsForAdmins.data}
        columns={columns}
        itemId="id"
        itemIdToExpandedRowMap={itemIdToExpandedRowMap}
        isExpandable={true}
        tableLayout={"auto"}
        rowProps={getRowProps}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sorting={sort}
        pagination={true}
        hasActions={false}
      />
    );
  }
  return <EuiLoadingContent lines={5} />;
};
