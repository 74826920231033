import React from "react";

import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";

import { ServerState } from "../../../@types/server";
import { UserState } from "../../../@types/user";
import { ArHorizontalRule } from "../../../arComponents/ArHorizontalRule";
import { ArPanel } from "../../../arComponents/ArPanel";
import { ArAccountSection } from "../panels/autoroleStore/ArAccountSection";
import { ArPortalSettingsPanel } from "../panels/autoroleStore/ArPortalSettingsPanel";
import { SiteTypeSection } from "../panels/SiteTypeSection";
import { WooConnectToSiteForm } from "../panels/woocommerceStore/WooConnectToSiteForm";
import { WooEnabledWebhooksPanel } from "../panels/woocommerceStore/WooEnabledWebhooksPanel";
import { WooPortalSettingsPanel } from "../panels/woocommerceStore/WooPortalSettingsPanel";
import { WooWebhookEventsPanel } from "../panels/woocommerceStore/WooWebhookEventsPanel";

interface ManageServerSettingsSubPageProps {
  user: UserState;
  server: ServerState;
}

export const ManageServerSettingsSubPage: React.FC<
  ManageServerSettingsSubPageProps
> = ({ server, user }) => {
  return (
    <ArPanel>
      <EuiFlexGroup gutterSize={"m"} direction={"column"}>
        <EuiFlexItem>
          <SiteTypeSection server={server} />
        </EuiFlexItem>
        <ArHorizontalRule threeQuarter={true} />
        {server.siteType === "woo" ? (
          <>
            <EuiFlexItem>
              <WooConnectToSiteForm server={server} />
            </EuiFlexItem>
            <EuiFlexItem>
              <WooPortalSettingsPanel server={server} />
            </EuiFlexItem>
            {user.scopes.some(
              (scope) => scope.scope === "ADMIN" && scope.serverId === server.id
            ) && (
              <>
                <EuiFlexItem>
                  <WooEnabledWebhooksPanel server={server} />
                </EuiFlexItem>
                <EuiFlexItem>
                  <WooWebhookEventsPanel server={server} />
                </EuiFlexItem>
              </>
            )}
          </>
        ) : (
          <>
            <ArAccountSection server={server} />
            <EuiFlexItem>
              <ArPortalSettingsPanel server={server} />
            </EuiFlexItem>
          </>
        )}
      </EuiFlexGroup>
    </ArPanel>
  );
};
