import React, { useEffect } from "react";
import { useImmer } from "use-immer";

import { EuiSuperSelect, EuiText } from "@elastic/eui";
import { EuiSuperSelectOption } from "@elastic/eui/src/components/form/super_select";

import { WooProduct } from "../../@types/woo";

interface SelectWooProductProps {
  value: string;
  setFieldValue: (name: string, value: string) => void;
  wooProducts: WooProduct[];
  disabled?: boolean;
}

export const SelectWooProduct: React.FC<SelectWooProductProps> = ({
  value,
  setFieldValue,
  wooProducts,
  disabled,
}) => {
  const [options, setOptions] = useImmer<EuiSuperSelectOption<string>[]>([]);

  const onSelectionChange = (value: string) => {
    const name = options.find((o) => o.value === value);
    if (name && name.inputDisplay) {
      setFieldValue(name.inputDisplay as string, value);
    }
  };

  useEffect(() => {
    if (wooProducts) {
      setOptions(
        wooProducts.map((o) => {
          return {
            value: o.id,
            inputDisplay: o.name,
            dropdownDisplay: (
              <>
                <strong>{o.name}</strong>
                <EuiText size="s" color="subdued">
                  <p className="euiTextColor--subdued">
                    Product ID: {o.id.toString()}
                  </p>
                </EuiText>
              </>
            ),
          };
        })
      );
    }
  }, [wooProducts]);

  return (
    <EuiSuperSelect
      fullWidth={true}
      isLoading={disabled}
      options={options}
      valueOfSelected={value}
      onChange={(e) => onSelectionChange(e)}
      hasDividers={true}
    />
  );
};
