import React, { useMemo } from "react";

import { EuiSuperSelect } from "@elastic/eui";

import { ArProductState } from "../../@types/product";

interface SelectArProductProps {
  value: string;
  setFieldValue: (name: string, value: string) => void;
  arProducts: ArProductState[];
  disabled?: boolean;
}

export const SelectArProduct: React.FC<SelectArProductProps> = ({
  value,
  setFieldValue,
  arProducts,
  disabled,
}) => {
  const onSelectionChange = (value: string) => {
    const name = options.find((o) => o.value === value);
    if (name && name.inputDisplay) {
      setFieldValue(name.inputDisplay as string, value);
    }
  };

  const options = useMemo(() => {
    if (arProducts) {
      return arProducts.map((o) => {
        return {
          value: o.productId,
          inputDisplay: o.name,
          dropdownDisplay: <strong>{o.name}</strong>,
        };
      });
    }
    return [];
  }, [arProducts]);

  return (
    <EuiSuperSelect
      fullWidth={true}
      disabled={disabled}
      options={options}
      valueOfSelected={value}
      onChange={(e) => onSelectionChange(e)}
      hasDividers={true}
    />
  );
};
