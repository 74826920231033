import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { LoadingOverlay } from "../../components/loading/LoadingOverlay";
import { useStripeRefresh, useStripeReturn } from "../../features/api/stripe";

export const StripeReturn = () => {
  const { serverId } = useParams<{ serverId: string }>();

  const stripeReturn = useStripeReturn();

  useEffect(() => {
    stripeReturn.mutate(serverId);
  }, []);
  return <LoadingOverlay />;
};

export const StripeRefresh = () => {
  const { serverId } = useParams<{ serverId: string }>();
  const stripeRefresh = useStripeRefresh();

  useEffect(() => {
    stripeRefresh.mutate(serverId);
  }, []);
  return <LoadingOverlay />;
};
