import { capitalize } from "lodash";
import { FC } from "react";

import { EuiText } from "@elastic/eui";

import { ArProductState, SubscriptionIntervalUnit } from "../../@types/product";

export const formatInterval = (
  subscriptionInterval: number,
  subscriptionIntervalUnit: SubscriptionIntervalUnit
) => {
  let interval;
  if (subscriptionIntervalUnit === "lifetime") {
    interval = "Lifetime";
  } else {
    if (subscriptionInterval > 1) {
      interval = `${subscriptionInterval} ${capitalize(
        subscriptionIntervalUnit
      )}s`;
    } else {
      if (subscriptionIntervalUnit === "day") {
        interval = `Daily`;
      } else {
        interval = `${capitalize(subscriptionIntervalUnit)}ly`;
      }
    }
  }
  return interval;
};

interface ProductPriceProps {
  formatter: Intl.NumberFormat;
  product: ArProductState;
}

export const ProductPrice: FC<ProductPriceProps> = ({ formatter, product }) => {
  const interval = formatInterval(
    product.subscriptionInterval,
    product.subscriptionIntervalUnit
  );

  if (!product.price || !product.subscriptionIntervalUnit) {
    return <EuiText>Coming Soon!</EuiText>;
  }

  if (product.compareAtPrice) {
    return (
      <EuiText>
        {formatter.format(product.price)}{" "}
        <sup>
          <s style={{ color: "#EA4F58", verticalAlign: "super" }}>
            {formatter.format(product.compareAtPrice)}
          </s>
        </sup>{" "}
        / {interval}
      </EuiText>
    );
  }

  return (
    <EuiText>
      {formatter.format(product.price)} / {interval}
    </EuiText>
  );
};
