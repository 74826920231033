import getSymbolFromCurrency from "currency-symbol-map";
import { DateTime } from "luxon";
import React, { FC, useMemo } from "react";
import { useDispatch } from "react-redux";

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiInMemoryTable,
  EuiLink,
  EuiLoadingContent,
  EuiText,
} from "@elastic/eui";
import {
  EuiTableFieldDataColumnType,
  EuiTableSortingType,
} from "@elastic/eui/src/components/basic_table/table_types";

import { ArPromotionCodeResponse } from "../../@types/coupon";
import { ServerState } from "../../@types/server";
import { initialCoupon } from "../../features/api/initials";
import { useCoupons } from "../../features/api/products";
import { setModal } from "../../features/redux/appState";
import { ArEditCouponModal } from "../modal/ArEditCouponModal";

interface ArCouponsTableProps {
  server: ServerState;
}

export const ArCouponsTable: FC<ArCouponsTableProps> = ({ server }) => {
  const coupons = useCoupons(server.id);

  const dispatch = useDispatch();

  const sort: EuiTableSortingType<any> = {
    enableAllColumns: true,
    allowNeutralSort: true,
    sort: {
      field: "name",
      direction: "desc",
    },
  };

  const columns = useMemo<
    EuiTableFieldDataColumnType<ArPromotionCodeResponse>[]
  >(() => {
    if (coupons.isSuccess) {
      return [
        {
          field: "code",
          name: "Name",
        },
        {
          field: "",
          name: "Amount Off",
          render: (row: ArPromotionCodeResponse) => {
            return (
              <EuiFlexGroup gutterSize={"none"}>
                <EuiFlexItem>
                  <EuiText size={"s"}>
                    {row.coupon.percentOff
                      ? `${row.coupon.percentOff}% Off`
                      : `${getSymbolFromCurrency(row.coupon.currency)}${
                          row.coupon.amountOff
                        } Off`}
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            );
          },
        },
        {
          field: "duration",
          name: "Duration",
          render: (item: number | undefined, row) => {
            let content;
            if (row.coupon.duration === "once") {
              content = "One-time";
            } else if (row.coupon.duration === "forever") {
              content = "Forever";
            } else if (row.coupon.duration === "repeating") {
              content = `${row.coupon.durationInMonths} Months`;
            }
            return (
              <EuiFlexGroup gutterSize={"none"}>
                <EuiFlexItem>
                  <EuiText size={"s"}>{content}</EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            );
          },
        },
        {
          name: "Redemptions",
          field: "",
          render: (item: ArPromotionCodeResponse) => {
            let content = `${item.timesRedeemed}`;
            if (item.maxRedemptions) {
              content += ` / ${item.maxRedemptions}`;
            }
            return (
              <EuiFlexGroup gutterSize={"none"}>
                <EuiFlexItem>
                  <EuiText size={"s"}>
                    {content} {item.timesRedeemed === 1 ? "time" : "times"}
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            );
          },
        },
        {
          name: "Expires On",
          field: "",
          render: (item: ArPromotionCodeResponse) => {
            if (item.expiresAt) {
              return (
                <EuiFlexGroup gutterSize={"none"}>
                  <EuiFlexItem>
                    <EuiText size={"s"}>
                      {DateTime.fromSeconds(
                        Number(item.expiresAt)
                      ).toLocaleString()}
                    </EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              );
            }
            return "No Expiration";
          },
        },
        // {
        //   name: "",
        //   field: "Assigned Products",
        //   render: (item: ArPromotionCodeResponse) => {
        //     return <>TODO</>;
        //   },
        // },
        {
          name: "",
          field: "",
          actions: [
            {
              render: (item: ArPromotionCodeResponse) => {
                return (
                  <EuiLink
                    onClick={() =>
                      dispatch(
                        setModal({
                          modal: "editCoupon",
                          open: true,
                          props: { ...item, viewOnly: true },
                        })
                      )
                    }
                  >
                    View
                  </EuiLink>
                );
              },
            },
          ],
        },
      ];
    }
    return [];
  }, [coupons.isSuccess, coupons.data]);

  if (coupons.isSuccess) {
    return (
      <>
        <EuiFlexItem>
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton
                color={"accent"}
                onClick={() =>
                  dispatch(
                    setModal({
                      modal: "editCoupon",
                      open: true,
                      props: initialCoupon(server.id, server.currency),
                    })
                  )
                }
              >
                Add Coupon
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiInMemoryTable
            items={coupons.data}
            columns={columns}
            itemId="id"
            tableLayout={"auto"}
            // @ts-ignore
            sorting={sort}
            pagination={true}
            hasActions={false}
          />
        </EuiFlexItem>
        <ArEditCouponModal server={server} />
      </>
    );
  }

  return (
    <EuiFlexItem>
      <EuiLoadingContent lines={5} />
    </EuiFlexItem>
  );
};
