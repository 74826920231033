import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { App } from "./components/app/app";
import { store } from "./features/redux/store";
import reportWebVitals from "./reportWebVitals";

import "@elastic/eui/dist/eui_theme_dark.css";
import "@elastic/charts/dist/theme_dark.css";
import "./App.css";

const persistor = persistStore(store);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
  queryCache: new QueryCache({
    // only show error toasts if we already have data in the cache
    // which indicates a failed background update
    // onError: (error) => {
    //   const err = error as Error
    //   addToast({title: "Something went wrong", text: err.message, color: "danger"})
    // }
  }),
});

// Sentry.init({
//   dsn: "https://3d7824d52a3c481486a8a28229d9a58b@o403052.ingest.sentry.io/5896778",
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.reactRouterV5Instrumentation(
//         history,
//         routes,
//         matchPath
//       ),
//     }),
//   ],
//   // enabled: !process.env.REACT_APP_DEBUG,
//   enabled: true,
//   debug: false,
//   environment: process.env.REACT_APP_DEBUG ? "development" : "production",
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null} />
        <App />
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export { toNumberString } from "./features/formatters/general";
