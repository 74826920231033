import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingContent,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import { useQueryClient } from "@tanstack/react-query";

import { ServerState } from "../../@types/server";
import { ArPanel } from "../../arComponents/ArPanel";
import { useSubscriptions } from "../../features/api/subscriptions";
import { clearSubscribeForm } from "../../features/redux/subscribeForm";
import { ROUTES } from "../app/app";
import { ArSubscriptionDetailsTable } from "../table/ArSubscriptionDetailsTable";
import { WooSubscriptionDetailsTable } from "../table/WooSubscriptionDetailsTable";

interface ServerSubscriptionsProps {
  server: ServerState;
}

export const ServerSubscriptions: React.FC<ServerSubscriptionsProps> = ({
  server,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const subscriptions = useSubscriptions(server.id);

  if (subscriptions.error) {
    return <p>{subscriptions.error.message}</p>;
  }

  if (subscriptions.isLoading || !subscriptions.data) {
    return (
      <EuiFlexItem>
        <ArPanel hasBorder={true} hasShadow={false}>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiLoadingContent lines={6} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </ArPanel>
      </EuiFlexItem>
    );
  } else {
    return (
      <EuiFlexItem>
        <ArPanel hasBorder={true} hasShadow={false}>
          <EuiFlexGroup direction={"column"}>
            <EuiFlexItem>
              <EuiTitle size={"xs"}>
                <h2>{server.companyName}</h2>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup alignItems={"center"}>
                {subscriptions.data.length === 0 ? (
                  server.siteType === "woo" ? (
                    <>
                      <EuiFlexItem>
                        <EuiText>
                          Your account is not linked to any subscriptions.
                        </EuiText>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <EuiButton
                          color={"accent"}
                          onClick={() => {
                            queryClient.removeQueries(["subscription"]);
                            dispatch(clearSubscribeForm());
                            navigate(`${ROUTES.SERVER}/${server.alias}`);
                          }}
                        >
                          Link Now
                        </EuiButton>
                      </EuiFlexItem>
                    </>
                  ) : (
                    <>
                      <EuiFlexItem>
                        <EuiText>You do not have any subscriptions.</EuiText>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <EuiButton
                          color={"accent"}
                          onClick={() => {
                            navigate(
                              `${ROUTES.STORE}/${server.alias}/collections`,
                              {
                                replace: true,
                              }
                            );
                          }}
                        >
                          View Store
                        </EuiButton>
                      </EuiFlexItem>
                    </>
                  )
                ) : (
                  <EuiFlexGroup justifyContent={"spaceAround"}>
                    <EuiFlexItem>
                      {server.siteType === "woo" ? (
                        <WooSubscriptionDetailsTable
                          server={server}
                          subscriptions={subscriptions.data}
                        />
                      ) : (
                        <ArSubscriptionDetailsTable
                          server={server}
                          subscriptions={subscriptions.data}
                        />
                      )}
                    </EuiFlexItem>
                  </EuiFlexGroup>
                )}
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </ArPanel>
      </EuiFlexItem>
    );
  }
};
