import React, { FC, ReactNode } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiOverlayMask } from "@elastic/eui";
import { css } from "@emotion/react";

import { LoadingIndicator } from "./LoadingIndicator";

interface LoadingOverlayProps {
  message?: ReactNode;
}
export const LoadingOverlay: FC<LoadingOverlayProps> = ({ message }) => {
  return (
    <>
      <EuiOverlayMask>
        <EuiFlexGroup direction={"column"} gutterSize={"xl"}>
          <EuiFlexItem>
            <LoadingIndicator />
          </EuiFlexItem>
          <EuiFlexItem
            css={css`
              align-items: center;
            `}
          >
            {message || null}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiOverlayMask>
    </>
  );
};
