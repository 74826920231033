import { FormikProps, withFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useImmer } from "use-immer";
import { v4 } from "uuid";
import * as Yup from "yup";

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiTitle,
} from "@elastic/eui";
import { css } from "@emotion/react";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";

import { AppSettingsState } from "../../../@types/appSettings";
import { DiscordGuildRole } from "../../../@types/discord";
import { ArProductLinkPayload, ArProductState } from "../../../@types/product";
import { ServerState } from "../../../@types/server";
import { useDiscordApi } from "../../../features/api/discord-server-api";
import { initialArProductRoleLinks } from "../../../features/api/initials";
import {
  productKeys,
  useArchiveProduct,
  useUpdateProduct,
} from "../../../features/api/products";
import {
  useProductServerRoleLinks,
  useUpdateArProductRoleLinks,
} from "../../../features/api/server-roles";
import { getModal, setModal } from "../../../features/redux/appState";

import { ProductStep } from "./ProductStep";
import { RolesStep } from "./RolesStep";
import { FormValues, OuterProps } from "./types";

interface ArProductModal {
  server: ServerState;
}

const getModalTitle = (step: number, isEdit?: boolean) => {
  switch (step) {
    case 0:
      return `${isEdit ? "Edit" : "Create"} Product`;
    case 1:
      return (
        <>
          <EuiTitle>
            <h2>{isEdit ? "Edit" : "Choose"} Roles</h2>
          </EuiTitle>
          <EuiTitle size={"xxxs"}>
            <h2>Link one or more Discord roles to this product</h2>
          </EuiTitle>
        </>
      );
    default:
      return "Unknown Step";
  }
};

const BaseForm = (props: OuterProps & FormikProps<FormValues>) => {
  const dialog = useSelector(
    getModal("editProduct")
  ) as AppSettingsState["modals"]["editProduct"];

  const archiveProduct = useArchiveProduct(props.server.id);

  const [step, setStep] = useImmer<number>(0);
  const isProductMutating = useIsMutating(["mutateProduct"]);
  const isProductServerRolesMutating = useIsMutating(["mutatingArProductLink"]);

  const {
    server,
    handleSubmit,
    dispatch,
    arProductLinkPayload,
    setArProductLinkPayload,
  } = props;

  const arProductLinks = useProductServerRoleLinks(server.id, {
    refetchOnWindowFocus: false,
  });

  const guildRoles = useDiscordApi<DiscordGuildRole[]>(
    server.id,
    "guilds",
    "roles"
  );

  useEffect(() => {
    if (arProductLinks.isSuccess && props.values.productId) {
      setArProductLinkPayload((draft) => {
        draft.productId = props.values.productId;
        draft.links = arProductLinks.data
          .filter(
            (link) =>
              link.product.productId === props.values.productId &&
              link.serverRole !== undefined
          )
          .map((link) => link.serverRole!.discordRoleId);
        return draft;
      });
    }
  }, [arProductLinks.isSuccess, arProductLinks.data]);

  const handleClose = () => {
    dispatch(setModal({ modal: "editProduct", open: false, props: undefined }));
  };

  function renderStepContent(step: number) {
    switch (step) {
      case 0:
        return <ProductStep {...props} handleClose={handleClose} />;
      case 1:
        if (guildRoles.isSuccess && arProductLinks.isSuccess) {
          return (
            <RolesStep
              {...props}
              roleLinkPayload={arProductLinkPayload}
              setRoleLinkPayload={setArProductLinkPayload}
              handleClose={handleClose}
              guildRoles={guildRoles.data}
            />
          );
        }
        return null;

      default:
        return <div>Not Found</div>;
    }
  }

  const getSecondaryButton = (step: number, isEdit?: boolean) => {
    switch (step) {
      case 0:
        return (
          <EuiButtonEmpty
            color={"ghost"}
            disabled={
              Boolean(isProductMutating) ||
              Boolean(isProductServerRolesMutating)
            }
            onClick={handleClose}
          >
            Cancel
          </EuiButtonEmpty>
        );
      case 1:
        return (
          <EuiButtonEmpty
            color={"ghost"}
            disabled={
              Boolean(isProductMutating) ||
              Boolean(isProductServerRolesMutating)
            }
            onClick={() => {
              setStep((draft) => {
                draft--;
                return draft;
              });
            }}
          >
            Back
          </EuiButtonEmpty>
        );
      default:
        return <div>Not Found</div>;
    }
  };

  const getMainButton = (step: number, isEdit?: boolean) => {
    switch (step) {
      case 0:
        return (
          <EuiButton
            color={"accent"}
            disabled={
              !props.values.name ||
              !props.values.price ||
              !props.values.subscriptionInterval ||
              Boolean(isProductMutating) ||
              Boolean(isProductServerRolesMutating)
            }
            onClick={() => {
              setStep((draft) => {
                draft++;
                return draft;
              });
            }}
          >
            Set Roles
          </EuiButton>
        );
      case 1:
        return (
          <EuiButton
            color={"accent"}
            disabled={arProductLinkPayload.links.length === 0}
            isLoading={
              Boolean(isProductMutating) ||
              Boolean(isProductServerRolesMutating)
            }
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </EuiButton>
        );
      default:
        return "Unknown Step";
    }
  };

  if (dialog.isOpen) {
    return (
      <EuiModal
        onClose={handleClose}
        css={css`
          min-width: 750px;
          min-height: 75vh;
        `}
      >
        <EuiModalHeader
          css={css`
            justify-content: center;
            width: 100%;
          `}
        >
          <EuiModalHeaderTitle
            css={css`
              width: 100%;
            `}
          >
            {getModalTitle(step, dialog.props?.isEdit)}
          </EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiFlexGroup
            justifyContent={"center"}
            direction={"column"}
            css={css`
              max-width: 600px;
              margin-left: auto;
              margin-right: auto;
              margin-top: 10px;
            `}
          >
            <EuiFlexItem>
              <form onSubmit={handleSubmit} id={`edit-product-form`}>
                <EuiForm>{renderStepContent(step)}</EuiForm>
              </form>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiFlexGroup justifyContent={"spaceBetween"}>
            <EuiFlexItem grow={false}>
              {dialog.props?.isEdit ? (
                <EuiButtonEmpty
                  color={"danger"}
                  disabled={
                    Boolean(isProductMutating) ||
                    Boolean(isProductServerRolesMutating)
                  }
                  onClick={() => {
                    archiveProduct.mutate(
                      {
                        serverId: server.id,
                        item: props.initialValues,
                      },
                      {
                        onSettled: (data) => {
                          handleClose();
                        },
                      }
                    );
                  }}
                >
                  Archive
                </EuiButtonEmpty>
              ) : null}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup>
                <EuiFlexItem>
                  {getSecondaryButton(step, dialog.props?.isEdit)}
                </EuiFlexItem>
                <EuiFlexItem>
                  {getMainButton(step, dialog.props?.isEdit)}
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  return null;
};

const id = v4();

const FormikForm = withFormik<OuterProps, FormValues>({
  mapPropsToValues: (props) => ({
    id: props.initialValues.id || id,
    productId: props.initialValues.productId || "",
    serverId: props.initialValues.serverId || props.server.id,
    name: props.initialValues.name || "",
    price: props.initialValues.price || 0,
    imageUrl: props.initialValues.imageUrl || "",
    compareAtPrice: props.initialValues.compareAtPrice || 0,
    subscriptionInterval: props.initialValues.subscriptionInterval || 0,
    subscriptionIntervalUnit:
      props.initialValues.subscriptionIntervalUnit || "month",
    description: props.initialValues.description || "",
    freeTrial: props.initialValues.freeTrial || undefined,
  }),

  enableReinitialize: true,
  validationSchema: Yup.object().shape({
    name: Yup.string().required("Name is required"),
    price: Yup.string().required("Price is required"),
    subscriptionInterval: Yup.number()
      .required("Subscription Interval is required")
      .integer("Subscription Interval must be an Integer"),
    subscriptionIntervalUnit: Yup.string().required(
      "Subscription Timeline is required"
    ),
    freeTrial: Yup.number().integer("Free Trial (Days) must be an Integer"),
  }),

  handleSubmit(
    {
      id,
      productId,
      name,
      price,
      compareAtPrice,
      subscriptionInterval,
      subscriptionIntervalUnit,
      description,
      imageUrl,
      freeTrial,
    }: FormValues,
    { props, setSubmitting, setErrors }
  ) {
    setSubmitting(true);
    const updatedProduct: ArProductState = {
      id,
      serverId: props.server.id,
      name,
      price,
      compareAtPrice,
      subscriptionInterval,
      subscriptionIntervalUnit,
      description,
      productId,
      imageUrl,
      freeTrial,
    };
    props.updateProduct.mutate(
      { serverId: props.server.id, item: updatedProduct },
      {
        onSettled: (data: ArProductState | undefined) => {
          if (data) {
            props.updateArProductRoleLinks.mutate(
              {
                serverId: props.server.id,
                productId: data.productId,
                links: props.arProductLinkPayload.links,
              },
              {
                onSettled: () => {
                  setSubmitting(false);
                  if (!productId) {
                    void props.queryClient.invalidateQueries(
                      productKeys.all(props.server.id, false)
                    );
                  }

                  props.dispatch(
                    setModal({ modal: "editProduct", open: false })
                  );
                },
              }
            );
          } else {
            setSubmitting(false);
            props.dispatch(setModal({ modal: "editProduct", open: false }));
          }
        },
      }
    );
  },
})(BaseForm);

export const ArEditProductModal: React.FC<ArProductModal> = ({ server }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const modal = useSelector(
    getModal("editProduct")
  ) as AppSettingsState["modals"]["editProduct"];

  const updateProduct = useUpdateProduct(server.id);
  const updateArProductRoleLinks = useUpdateArProductRoleLinks(server.id);

  const [arProductLinkPayload, setArProductLinkPayload] =
    useImmer<ArProductLinkPayload>(initialArProductRoleLinks(server.id));

  if (modal.props) {
    return (
      <FormikForm
        queryClient={queryClient}
        server={server}
        initialValues={modal.props}
        updateProduct={updateProduct}
        updateArProductRoleLinks={updateArProductRoleLinks}
        dispatch={dispatch}
        arProductLinkPayload={arProductLinkPayload}
        setArProductLinkPayload={setArProductLinkPayload}
      />
    );
  }
  return null;
};
