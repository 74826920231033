import {
  QueryObserverOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";

import { DiscordApiDomain, DiscordApiSubDomain } from "../../@types/discord";
import { AddRemoveRolesRequest } from "../../@types/serverRole";
import { addToast } from "../../components/toast";

import { api } from "./api";

const discordApiKeys = {
  base: [{ scope: "discordApi" }] as const,
  api: (
    serverId: string,
    domain: DiscordApiDomain,
    subDomain: DiscordApiSubDomain
  ) => [{ ...discordApiKeys.base[0], serverId, domain, subDomain }],
};

export const fetchDiscordApi = async (
  serverId: string,
  domain: DiscordApiDomain,
  subDomain: DiscordApiSubDomain
) => {
  const resp = await api.get<any>(`/discord-api`, {
    params: { serverId, domain, subDomain },
  });
  return resp.data;
};

export const useDiscordApi = <Response>(
  serverId: string | undefined,
  domain: DiscordApiDomain,
  subDomain: DiscordApiSubDomain,
  options?: QueryObserverOptions<Response, Error>
) =>
  useQuery<Response, Error>(
    discordApiKeys.api(serverId!, domain, subDomain),
    async () => fetchDiscordApi(serverId!, domain, subDomain),
    {
      enabled: Boolean(serverId),
      ...options,
    }
  );

export const bulkModifyRoles = async (data: AddRemoveRolesRequest[]) => {
  const resp = await api.post("/discord-api/bulkModifyRoles", data);
  return resp.data;
};

export const useBulkModifyRoles = () => {
  return useMutation<any, Error, any>(
    async (data: AddRemoveRolesRequest[]) => bulkModifyRoles(data),
    {
      onError: (error) => {
        addToast({
          id: "bulk-modify-role-failure",
          color: "danger",
          title: error.message,
        });
      },
    }
  );
};
