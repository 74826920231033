import React from "react";
import { useNavigate } from "react-router-dom";

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiTitle,
} from "@elastic/eui";
import { css } from "@emotion/react";

import { ArPage } from "../../arComponents/ArPage";
import { ArPanel } from "../../arComponents/ArPanel";
import { ROUTES } from "../../components/app/app";
import { useLogin } from "../../features/api/auth";
import { AutoroleFull } from "../../images/autorole-full";
import logo from "../../images/autorole-full.svg";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LoginBaseRouteProps {
  message?: string;
}

export const NotFound: React.FC<LoginBaseRouteProps> = ({ message }) => {
  const navigate = useNavigate();
  return (
    <ArPage
      isLoading={false}
      bodyCss={css`
        height: 100%;
      `}
      body={
        <EuiFlexGroup
          gutterSize={"l"}
          justifyContent={"spaceAround"}
          alignItems={"center"}
          direction={"column"}
          responsive={false}
          css={css`
            height: 100%;
          `}
        >
          <EuiFlexItem
            grow={false}
            css={css`
              @media only screen and (max-width: 480px) {
                width: 80%;
              }
            `}
          >
            <ArPanel paddingSize={"xl"} className={"ar-login-panel ar-panel"}>
              <EuiFlexGroup
                direction={"column"}
                justifyContent={"spaceAround"}
                alignItems={"center"}
                css={css`
                  height: 100%;
                `}
              >
                <EuiFlexItem
                  css={css`
                    justify-content: center;
                    text-align: center;
                  `}
                >
                  <EuiTitle>
                    <h2>Page Not Found</h2>
                  </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton onClick={() => navigate(ROUTES.INDEX)}>
                    Home Page
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </ArPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      }
      restrictWidth={true}
    />
  );
};
