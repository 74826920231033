import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useImmer } from "use-immer";

import { LoadingOverlay } from "../../components/loading/LoadingOverlay";
import { SomethingWentWrong } from "../../components/loading/SomethingWentWrong";
import { useUser } from "../../features/api/auth";
import { useUpdateDiscordAccountServerLink } from "../../features/api/servers";
import { useBillingPortal } from "../../features/api/stripe";

export const BillingPortalRedirect: React.FC = () => {
  const [errors, setErrors] = useImmer<number>(0);
  const { serverId, subscriptionId } = useParams<{
    serverId: string;
    subscriptionId: string;
  }>();

  const user = useUser();
  const billingPortal = useBillingPortal();
  const discordAccountLink = useUpdateDiscordAccountServerLink();

  useEffect(() => {
    if (user.isSuccess && errors <= 6) {
      discordAccountLink.mutate(
        { serverId },
        {
          onSuccess: (data) => {
            billingPortal.mutate({
              serverId: serverId,
              subscriptionId,
              discordAccountId: data.discordAccountId,
            });
          },
        }
      );
    }
  }, []);

  if (errors > 6) {
    return <LoadingOverlay message={<SomethingWentWrong />} />;
  }

  return <LoadingOverlay />;
};
