import React, { ReactNode, useMemo } from "react";
import { useImmer } from "use-immer";

import {
  EuiButtonIcon,
  EuiCodeBlock,
  EuiFlexGroup,
  EuiFlexItem,
  EuiInMemoryTable,
  EuiLoadingContent,
  RIGHT_ALIGNMENT,
} from "@elastic/eui";
import {
  EuiTableFieldDataColumnType,
  EuiTableSortingType,
} from "@elastic/eui/src/components/basic_table/table_types";

import { ServerState } from "../../@types/server";
import { WooWebhook } from "../../@types/woo";
import { useEnabledWebhooksForAdmins } from "../../features/api/admin";

interface EnabledWebhooksTableProps {
  server: ServerState;
}

export const EnabledWebhooksTable: React.FC<EnabledWebhooksTableProps> = ({
  server,
}) => {
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useImmer<{
    [id: string]: ReactNode;
  }>({});

  const webhooksForAdmins = useEnabledWebhooksForAdmins(server.id);

  const getRowProps = (item: WooWebhook) => {
    return {
      onClick: () => {
        toggleDetails(item);
      },
    };
  };

  const sort: EuiTableSortingType<any> = {
    enableAllColumns: true,
    allowNeutralSort: true,
    sort: {
      field: "updatedAt",
      direction: "desc",
    },
  };

  const toggleDetails = (item: WooWebhook) => {
    setItemIdToExpandedRowMap((draft) => {
      if (draft[item.id]) {
        delete draft[item.id];
      } else {
        draft[item.id] = (
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiCodeBlock language="json" overflowHeight={300} isCopyable>
                {JSON.stringify(item, null, 4)}
              </EuiCodeBlock>
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      }
      return draft;
    });
  };

  const columns = useMemo<EuiTableFieldDataColumnType<WooWebhook>[]>(() => {
    if (webhooksForAdmins.isSuccess) {
      return [
        {
          field: "name",
          name: "Name",
        },
        {
          field: "topic",
          name: "Topic",
        },
        {
          field: "deliveryUrl",
          name: "Delivery Url",
        },
        {
          name: "",
          field: "",
          align: RIGHT_ALIGNMENT,
          width: "40px",
          isExpander: true,
          render: (item: any) => (
            <EuiButtonIcon
              onClick={() => toggleDetails(item)}
              aria-label={
                itemIdToExpandedRowMap && [item.id] ? "Collapse" : "Expand"
              }
              iconType={
                itemIdToExpandedRowMap[item.id] ? "arrowUp" : "arrowDown"
              }
            />
          ),
        },
      ];
    }
    return [];
  }, [webhooksForAdmins.isSuccess]);

  if (webhooksForAdmins.data) {
    return (
      <EuiInMemoryTable
        items={webhooksForAdmins.data}
        columns={columns}
        itemId="id"
        itemIdToExpandedRowMap={itemIdToExpandedRowMap}
        isExpandable={true}
        tableLayout={"auto"}
        rowProps={getRowProps}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sorting={sort}
        hasActions={false}
        pagination={true}
      />
    );
  }
  return <EuiLoadingContent lines={5} />;
};
