export const AUTOROLE_APP_VERSION = "1.0.0";

export const ACTIVE = ["active"];
export const INACTIVE = [
  "disabled",
  "on-hold",
  "cancelled",
  "cancelled-subscription",
  "expired",
  "inactive",
  "trash",
  "pending-cancellation",
  "pending-cancel",
];

export const DISCORD = "https://discord.gg/VZpcsyfYk3";

export const statusPrettier = (str: string, separator = "-") => {
  const arr = str.split(separator);

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  return arr.join(" ");
};
